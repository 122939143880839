<template>
	<div class="content profile-content">
		<ProfileNavbarTeacher :title="$t('sidebar.notifications')"></ProfileNavbarTeacher>
		<router-link
			to="/notification-teacher"
			type="button"
			class="btn btn-light btn-back mb-3"
		>
			<img width="6" class="mr-3" src="/images/vector-back-grey.png" />
			{{ $t('school.back-btn') }}
		</router-link>
		<div class="col-12">
			<div class="d-flex flex-wrap justify-content-between align-items-center mb-5">
				<h1 class="notification-title">
					<img src="/images/message-check.svg" alt="" />
					{{ data.subject[$i18n.locale] }}
				</h1>
				<p class="notification-date">
					{{ data.date }}
				</p>
			</div>
			<div class="col-12 notification-card">
				<img
					src="/images/1000-BALA-logo.png"
					alt=""
					class="notification-logo"
				/>
				<h2 class="notification-fullname">
					{{ data.fullname }}
				</h2>
				<div class="notification-message" v-html="data.message[$i18n.locale]"></div>
				<div class="notification-callcenter" style="margin-top: 1rem;">
<!--						{{ text.detail_callcenter }}-->
          {{$t('call_centre')}}
					<div class="d-flex align-items-center my-3">
						<a href="tel:87273100258">8 727 310 02 58</a>
            <span>{{ $t('profile.notifications.detail-free-call') }}</span>
<!--						<span>{{ text.detail_free_call }}</span>-->
					</div>
				</div>
        <div class="notification-download-wrapper">
          <a
              target="_blank"
              class="notification-download"
              :href="API_ROOT+'/storage/'+ data.filePath"
          >
            {{$t('downloadLetter')}}
          </a>
        </div>
				<p class="notification-copyright">
					© 2020 {{ $t('footer.elbasy-academy') }}. {{ $t('footer.copyright') }}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
	import ProfileNavbarTeacher from '@/components/ProfileNavbarTeacher'

	export default {
		name: 'Settings',
		components: {
      ProfileNavbarTeacher,
		},
		data() {
			return {
        API_ROOT: window.API_ROOT,
				data: "",
				text: {
					detail_math: "Математическая грамотность:",
					detail_native_lang: "Казахский или русский язык:",
					detail_english: "Английский язык:",
					detail_natural_science: "Естествознание:",
					detail_total: "Набранное количество баллов:",
					detail_callcenter: "Если у Вас возникли вопросы, обращайтесь в кол-центр по номеру:",
					detail_free_call: "(звонок бесплатный)"
				},
				loader: true
			}
		},
    filters :{
      round:function (value){
        return Math.round((value)*100)/100
      }
    },
		methods: {

		},
    mounted() {
      if (localStorage.getItem('notification_teacher_data_result')) {
        this.data = JSON.parse(localStorage.getItem('notification_teacher_data_result'))
        console.log('this.data', this.data)
      }
    },
  }
</script>

<style lang="scss" scoped>
.notification {
  &-title {
    font-size: 24px;
    color: #05458c;
    font-weight: bold;

    img {
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-right: 10px;
      object-fit: contain;
    }
  }

  &-download-wrapper {
    justify-content: center;
    display: flex;
  }

  &-download {
    min-width: 200px;
    margin: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #2A8BF2;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 48px !important;
    text-decoration: none;
    color: white !important;
    border-radius: 10px;
    min-height: 48px;
    display: block;
    max-width: max-content;
  }

  &-date {
    font-size: 18px;
    color: #05458c;
  }

  &-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dfe0eb;
    padding: 33px 26px;
  }

  &-logo {
    display: block;
    width: 147px;
    margin: 0 auto;
  }

  &-fullname {
    font-size: 30px;
    color: #05458C;
    text-align: center;
    margin: 32px 0;
    font-weight: bold;
  }

  &-results {
    color: #18A0FB;

    &__side {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__overall {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  &-callcenter {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding: 11px;
      border-radius: 10px;
      border: 1px solid #DFE0EB;
      margin-right: 10px;

      &::before {
        content: '';
        display: block;
        float: left;
        width: 22px;
        height: 31px;
        margin-right: 10px;
        background-image: url('/images/phone.svg');
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }
    }

    span {
      color: #05458C;
      opacity: 0.3;
    }
  }

  &-copyright {
    text-align: center;
    font-size: 14px;
    color: #05458C;
    opacity: 0.3;
  }
}

@media screen and (max-width: 700px) {
  .notification {
    &-title {
      font-size: 16px;

      img {
        display: none;
      }
    }

    &-date {
      font-size: 12px;
      margin-left: auto;
    }

    &-card {
      padding: 33px 13px;
    }

    &-results {
      &__side {
      display: none;
      }
    }

    &-message {
      margin: 15px 0;
    }

    &-callcenter {
      a {
        white-space: nowrap;
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}
</style>
