<template>
  <div class="view-home">
    <div class="main-title-mobile">
      <h1>{{ $t('main-page.title') }}</h1>
    </div>
    <div class="main-page-bg">
      <div class="main-bg-overlay"></div>
      <div class="container">
        <div class="col-lg-7 col-md-12">
          <div class="welcome-text">
            <h1 class="main-title">{{ $t('main-page.title') }}</h1>
            <p class="main-description pr-5">{{ $t('main-page.description') }}</p>
            <div style="display: none" class="check-school-button">
              <router-link to="/check-school" href="check-school.html" type="button"
                           class="btn btn-warning btn-rounded btn-check-school">{{
                  $t('main-page.check-school-button')
                }}
              </router-link>
              <!--            <a href="https://elbasyacademy.kz/one-news/91"  target="_blank" class="btn btn-primary btn-rounded btn-check-school btn-result">{{$t('first-step-result')}}</a>-->
            </div>
            <div class="sponsors clearfix">
              <p>{{ $t('main-page.sponsors') }}</p>
              <a href="https://fnn.kz" target="_blank"><i
                  v-bind:class="{'sponsor-fond': this.$i18n.locale === 'ru', 'sponsor-fond-kz': this.$i18n.locale === 'kz'}"></i></a>
              <a href="https://elbasyacademy.kz" target="_blank"><i class="sponsor-elumiti"></i></a>
              <a href="#"><i class="sponsor-ministry"></i></a>
            </div>
          </div>
        </div>
        <!--			<div class="col-6 py-5">-->
        <!--        <img src="/images/main-header.jpg">-->
        <!--				<img src="/images/logo-big.svg" width="530">-->
        <!--			</div>-->
      </div>
    </div>
    <div class="reviews pt-5">
      <div class="container">
        <div class="text-center pb-4">
          <h3>{{ $t('main-page.members-reviews') }}</h3>
        </div>
        <div class="users-comment">

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane" :class="{'show active': tab == 'comment1'}" id="comment1" role="tabpanel"
                 aria-labelledby="comment1-tab">
              <div class="comment">{{ $t('main-page.feedback-1') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment2'}" id="comment2" role="tabpanel"
                 aria-labelledby="comment2-tab">
              <div class="comment">{{ $t('main-page.feedback-2') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment3'}" id="comment3" role="tabpanel"
                 aria-labelledby="comment3-tab">
              <div class="comment">{{ $t('main-page.feedback-3') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment4'}" id="comment4" role="tabpanel"
                 aria-labelledby="comment4-tab">
              <div class="comment">{{ $t('main-page.feedback-4') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment5'}" id="comment5" role="tabpanel"
                 aria-labelledby="comment5-tab">
              <div class="comment">{{ $t('main-page.feedback-5') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment6'}" id="comment6" role="tabpanel"
                 aria-labelledby="comment6-tab">
              <div class="comment">{{ $t('main-page.feedback-6') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment7'}" id="comment7" role="tabpanel"
                 aria-labelledby="comment7-tab">
              <div class="comment">{{ $t('main-page.feedback-7') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment8'}" id="comment8" role="tabpanel"
                 aria-labelledby="comment8-tab">
              <div class="comment">{{ $t('main-page.feedback-8') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment9'}" id="comment9" role="tabpanel"
                 aria-labelledby="comment9-tab">
              <div class="comment">{{ $t('main-page.feedback-9') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment10'}" id="comment10" role="tabpanel"
                 aria-labelledby="comment10-tab">
              <div class="comment">{{ $t('main-page.feedback-10') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment11'}" id="comment11" role="tabpanel"
                 aria-labelledby="comment11-tab">
              <div class="comment">{{ $t('main-page.feedback-11') }}</div>
            </div>
            <div class="tab-pane" :class="{'show active': tab == 'comment12'}" id="comment12" role="tabpanel"
                 aria-labelledby="comment12-tab">
              <div class="comment">{{ $t('main-page.feedback-12') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment13'}" id="comment13"
                 role="tabpanel" aria-labelledby="comment13-tab">
              <div class="comment">{{ $t('main-page.feedback-13') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment14'}" id="comment14"
                 role="tabpanel" aria-labelledby="comment14-tab">
              <div class="comment">{{ $t('main-page.feedback-14') }}</div>
            </div>

            <div v-if="$i18n.locale=='ru'" class="tab-pane" :class="{'show active': tab == 'comment15'}" id="comment15"
                 role="tabpanel" aria-labelledby="comment15-tab">
              <div class="comment">{{ $t('main-page.feedback-15') }}</div>
            </div>
            <div v-if="$i18n.locale=='ru'" class="tab-pane" :class="{'show active': tab == 'comment16'}" id="comment16"
                 role="tabpanel" aria-labelledby="comment16-tab">
              <div class="comment">{{ $t('main-page.feedback-16') }}</div>
            </div>
            <div v-if="$i18n.locale=='ru'" class="tab-pane" :class="{'show active': tab == 'comment17'}" id="comment17"
                 role="tabpanel" aria-labelledby="comment17-tab">
              <div class="comment">{{ $t('main-page.feedback-17') }}</div>
            </div>
            <div v-if="$i18n.locale=='ru'" class="tab-pane" :class="{'show active': tab == 'comment18'}" id="comment18"
                 role="tabpanel" aria-labelledby="comment18-tab">
              <div class="comment">{{ $t('main-page.feedback-18') }}</div>
            </div>
            <div v-if="$i18n.locale=='ru'" class="tab-pane" :class="{'show active': tab == 'comment19'}" id="comment19"
                 role="tabpanel" aria-labelledby="comment19-tab">
              <div class="comment">{{ $t('main-page.feedback-19') }}</div>
            </div>
            <div v-if="$i18n.locale=='ru'" class="tab-pane" :class="{'show active': tab == 'comment20'}" id="comment20"
                 role="tabpanel" aria-labelledby="comment20-tab">
              <div class="comment">{{ $t('main-page.feedback-20') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment21'}" id="comment21"
                 role="tabpanel" aria-labelledby="comment21-tab">
              <div class="comment">{{ $t('main-page.feedback-21') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment22'}" id="comment22"
                 role="tabpanel" aria-labelledby="comment22-tab">
              <div class="comment">{{ $t('main-page.feedback-22') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment23'}" id="comment23"
                 role="tabpanel" aria-labelledby="comment23-tab">
              <div class="comment">{{ $t('main-page.feedback-23') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment24'}" id="comment24"
                 role="tabpanel" aria-labelledby="comment24-tab">
              <div class="comment">{{ $t('main-page.feedback-24') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment25'}" id="comment25"
                 role="tabpanel" aria-labelledby="comment25-tab">
              <div class="comment">{{ $t('main-page.feedback-25') }}</div>
            </div>
            <div v-if="$i18n.locale=='kz'" class="tab-pane" :class="{'show active': tab == 'comment26'}" id="comment26"
                 role="tabpanel" aria-labelledby="comment26-tab">
              <div class="comment">{{ $t('main-page.feedback-26') }}</div>
            </div>
          </div>
          <ul class="nav users-wrap" id="myTab" role="tablist">

            <!-- <button class="slide-pre" @click="slidePre">
                <img width="27" height="47" src="images/Vector-prev.png">
            </button> -->

            <carousel
                @pageChange="pageChange"
                :paginationclick="paginationClick"
                :pagination-enabled="true"
                :navigation-enabled="true"
                :center-mode="true"
                :scroll-per-page="true"
                :perPageCustom="[[320, 1], [480, 1], [768, 1], [991, 4]]"
                class="w-100"
                ref="slider">

              <!-- slideritem wrapped package with the components you need -->
              <slide @slideclick="tab = 'comment1'">
                <li :class="{'active': tab == 'comment1'}">
                  <a :class="{'active': tab == 'comment1'}" class="cursor-pointer" id="comment1-tab" data-toggle="tab"
                     role="tab" aria-controls="comment1" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-1') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment2'">
                <li :class="{'active': tab == 'comment2'}">
                  <a :class="{'active': tab == 'comment2'}" class="cursor-pointer" id="comment2-tab" data-toggle="tab"
                     role="tab" aria-controls="comment2" aria-selected="false">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-2') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment3'">
                <li :class="{'active': tab == 'comment3'}">
                  <a :class="{'active': tab == 'comment3'}" class="cursor-pointer" id="comment3-tab" data-toggle="tab"
                     role="tab" aria-controls="comment3" aria-selected="false">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-3') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment4'">
                <li :class="{'active': tab == 'comment4'}">
                  <a :class="{'active': tab == 'comment4'}" class="cursor-pointer" id="comment4-tab" data-toggle="tab"
                     role="tab" aria-controls="comment4" aria-selected="false">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-4') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment5'">
                <li :class="{'active': tab == 'comment5'}">
                  <a :class="{'active': tab == 'comment5'}" class="cursor-pointer" id="comment5-tab" data-toggle="tab"
                     role="tab" aria-controls="comment5" aria-selected="false">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-5') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment6'">
                <li :class="{'active': tab == 'comment6'}">
                  <a :class="{'active': tab == 'comment6'}" class="cursor-pointer" id="comment6-tab" data-toggle="tab"
                     role="tab" aria-controls="comment6" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-6.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-6') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment7'">
                <li :class="{'active': tab == 'comment7'}">
                  <a :class="{'active': tab == 'comment7'}" class="cursor-pointer" id="comment7-tab" data-toggle="tab"
                     role="tab" aria-controls="comment7" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-7') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment8'">
                <li :class="{'active': tab == 'comment8'}">
                  <a :class="{'active': tab == 'comment8'}" class="cursor-pointer" id="comment8-tab" data-toggle="tab"
                     role="tab" aria-controls="comment8" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-7.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-8') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment9'">
                <li :class="{'active': tab == 'comment9'}">
                  <a :class="{'active': tab == 'comment9'}" class="cursor-pointer" id="comment9-tab" data-toggle="tab"
                     role="tab" aria-controls="comment9" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-9') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment10'">
                <li :class="{'active': tab == 'comment10'}">
                  <a :class="{'active': tab == 'comment10'}" class="cursor-pointer" id="comment10-tab" data-toggle="tab"
                     role="tab" aria-controls="comment10" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-8.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-10') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment11'">
                <li :class="{'active': tab == 'comment11'}">
                  <a :class="{'active': tab == 'comment11'}" class="cursor-pointer" id="comment11-tab" data-toggle="tab"
                     role="tab" aria-controls="comment11" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img v-if="$i18n.locale=='ru'" width="56" src="images/review-11.png">
                      <img v-else width="56" src="images/review-11-kz.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-11') }}</span>
                        <span v-if="$i18n.locale=='ru'">{{ $t('main-page.parent') }}</span>
                        <span v-else>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide @slideclick="tab = 'comment12'">
                <li :class="{'active': tab == 'comment12'}">
                  <a :class="{'active': tab == 'comment12'}" class="cursor-pointer" id="comment12-tab" data-toggle="tab"
                     role="tab" aria-controls="comment12" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img v-if="$i18n.locale=='ru'" width="56" src="images/review-12.png">
                      <img v-else width="56" src="images/review-12 kz.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-12') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment13'">
                <li :class="{'active': tab == 'comment13'}">
                  <a :class="{'active': tab == 'comment13'}" class="cursor-pointer" id="comment13-tab" data-toggle="tab"
                     role="tab" aria-controls="comment13" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-13.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-13') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment14'">
                <li :class="{'active': tab == 'comment14'}">
                  <a :class="{'active': tab == 'comment14'}" class="cursor-pointer" id="comment14-tab" data-toggle="tab"
                     role="tab" aria-controls="comment14" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-14.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-14') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='ru'" @slideclick="tab = 'comment15'">
                <li :class="{'active': tab == 'comment15'}">
                  <a :class="{'active': tab == 'comment15'}" class="cursor-pointer" id="comment15-tab" data-toggle="tab"
                     role="tab" aria-controls="comment15" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-15.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-15') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='ru'" @slideclick="tab = 'comment16'">
                <li :class="{'active': tab == 'comment16'}">
                  <a :class="{'active': tab == 'comment16'}" class="cursor-pointer" id="comment16-tab" data-toggle="tab"
                     role="tab" aria-controls="comment16" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-16') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='ru'" @slideclick="tab = 'comment17'">
                <li :class="{'active': tab == 'comment17'}">
                  <a :class="{'active': tab == 'comment17'}" class="cursor-pointer" id="comment17-tab" data-toggle="tab"
                     role="tab" aria-controls="comment17" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-17') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='ru'" @slideclick="tab = 'comment18'">
                <li :class="{'active': tab == 'comment18'}">
                  <a :class="{'active': tab == 'comment18'}" class="cursor-pointer" id="comment18-tab" data-toggle="tab"
                     role="tab" aria-controls="comment18" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-18.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-18') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='ru'" @slideclick="tab = 'comment19'">
                <li :class="{'active': tab == 'comment19'}">
                  <a :class="{'active': tab == 'comment19'}" class="cursor-pointer" id="comment19-tab" data-toggle="tab"
                     role="tab" aria-controls="comment19" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-19') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='ru'" @slideclick="tab = 'comment20'">
                <li :class="{'active': tab == 'comment20'}">
                  <a :class="{'active': tab == 'comment20'}" class="cursor-pointer" id="comment20-tab" data-toggle="tab"
                     role="tab" aria-controls="comment20" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-20.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-20') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>

              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment21'">
                <li :class="{'active': tab == 'comment21'}">
                  <a :class="{'active': tab == 'comment21'}" class="cursor-pointer" id="comment21-tab" data-toggle="tab"
                     role="tab" aria-controls="comment21" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-21') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>

              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment22'">
                <li :class="{'active': tab == 'comment22'}">
                  <a :class="{'active': tab == 'comment22'}" class="cursor-pointer" id="comment22-tab" data-toggle="tab"
                     role="tab" aria-controls="comment22" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-22.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-22') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment23'">
                <li :class="{'active': tab == 'comment23'}">
                  <a :class="{'active': tab == 'comment23'}" class="cursor-pointer" id="comment23-tab" data-toggle="tab"
                     role="tab" aria-controls="comment23" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-23.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-23') }}</span>
                        <span>{{ $t('main-page.parent') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment24'">
                <li :class="{'active': tab == 'comment24'}">
                  <a :class="{'active': tab == 'comment24'}" class="cursor-pointer" id="comment24-tab" data-toggle="tab"
                     role="tab" aria-controls="comment24" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-24.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-24') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment25'">
                <li :class="{'active': tab == 'comment25'}">
                  <a :class="{'active': tab == 'comment25'}" class="cursor-pointer" id="comment25-tab" data-toggle="tab"
                     role="tab" aria-controls="comment25" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="images/review-25.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-25') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>
              <slide v-if="$i18n.locale=='kz'" @slideclick="tab = 'comment26'">
                <li :class="{'active': tab == 'comment26'}">
                  <a :class="{'active': tab == 'comment26'}" class="cursor-pointer" id="comment26-tab" data-toggle="tab"
                     role="tab" aria-controls="comment26" aria-selected="true">
                    <div class="carousel-item-wrap">
                      <img width="56" src="/images/Intersect.png">
                      <div>
                        <span class="user-name">{{ $t('main-page.parent-name-26') }}</span>
                        <span>{{ $t('main-page.student') }}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </slide>

              <!-- Customizable loading -->
              <div slot="loading">loading...</div>
            </carousel>

            <!-- <button class="slide-next" @click="slideNext">
                <img width="27" height="47" src="images/Vector-next.png">
            </button> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="modules py-5">
      <div class="container">
        <div class="row">
          <div class="text-center w-100 pb-4">
            <h3>{{ $t('main-page.elumiti-projects-title') }}</h3>
          </div>
          <ul class="clearfix">
            <li class="talent_module clear-both">
              <a href="https://ro.elumiti.kz/" target="_blank">
                <i></i>
                <span>{{ $t("main-page-projects.talent") }}</span>
                <button></button>
              </a>
            </li>
            <li class="qabilet_module clear-both">
              <a href="https://qabilet.elbasyacademy.kz/" target="_blank">
                <i></i>
                <span>{{ $t("main-page-projects.qabilet") }}</span>
                <button></button>
              </a>
            </li>
            <li class="birge_oqy_module clear-both">
              <a href="https://birgeoqy.kz/" target="_blank">
                <i></i>
                <span>{{ $t("main-page-projects.birgeoqy") }}</span>
                <button></button>
              </a>
            </li>
            <li class="smartustaz_module clear-both">
              <a href="https://smartustaz.kz/" target="_blank">
                <i></i>
                <span>{{ $t("main-page-projects.smartustaz") }}</span>
                <button></button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Carousel, Slide} from 'vue-carousel';
// import VueCarousel from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },
  methods: {
    pageChange(number) {
      if (window.innerWidth < 992) {
        this.tab = 'comment' + (number + 1);
      } else {
        this.tab = 'comment' + (number + 3);
      }
    },
    paginationClick() {

    },
    slidePre() {
      // console.log(this.$refs.slider);
      this.$refs.slider.$emit('slidePre');
      // this.$emit('slidePre');
    },
    slideNext() {
      this.$refs.slider.$emit('slideNext');
      // this.$emit('slideNext');
    }

  },
  mounted() {
    if (window.innerWidth < 992) {
      this.tab = 'comment1';
    }
  },
  data() {
    return {
      tab: 'comment3'
    }
  }
}
</script>
<style>
.btn-result {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  max-width: 252.7px !important;
  margin-top: 1rem !important;
  display: block !important;
}

@media (max-width: 991px) {
  .btn-result {
    margin: auto;
  }
}

.slide-pre,
.slide-next {
  position: absolute;
  top: calc(50% - 25px);
  border: none;
  background-color: transparent;
}

.slide-pre:active,
.slide-pre:hover,
.slide-next:active,
.slide-next:hover {
  outline: none;
  cursor: pointer;
}

.slide-next {
  right: -50px;
}

.slide-pre {
  left: -50px;
}
</style>
