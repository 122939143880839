<template>
  <div class="view-home for-members-bg">
    <div class="container">
      <div class="row about-page pb-5">
        <div class="col-md-12">
          <div class="breadcrumb px-0">
            <router-link to="/">{{ $t('breadcrumb.main-page') }}</router-link>
            <router-link to="/for-members" class="active">{{ $t('breadcrumb.for-members') }}</router-link>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h1 class="title about-title mb-3">{{ $t('for-members.title') }}</h1>
              <!--            <h2>{{ $t('for-members.task-examples') }}</h2>-->
            </div>
            <div class="col-lg-10 col-md-12 mx-auto">
              <div class="about-content-position">
                <!--              <div class="step-card step-1">-->
                <!--                <h3>{{ $t('for-members.stage') }}</h3>-->
                <!--                <p>{{ $t('for-members.first-stage') }}</p>-->
                <!--              </div>-->
                <div class="about-content-background">
                  <div class="about-content-image item-first" style="background-image: url('images/0x0.jfif')"
                       alt=""></div>
                  <div class="about-content-image item-second"
                       style="background-image: url('images/photo_2022-11-25_15-01-36.jpg')" alt=""></div>
                  <div class="about-content-image item-third" style="background-image: url('images/1D8A1614.JPG')"
                       alt=""></div>

                </div>
                <div class="for_members_new_block" style="z-index: 2;">
                  <div class="for_members_title">
                    {{ $t('for-members.review-author-6') }}
                  </div>
                  <div class="for_members_text" v-html="$t('for-members.review-6')">
                  </div>
<!--                  <el-button type="success" onclick="document.location='https://ro.elumiti.kz/'">Пробный тест</el-button>-->
                  <el-button type="success" onclick="window.open('https://ro.elumiti.kz/','_blank');">{{ $t('practice_test') }}</el-button>
                </div>
              </div>
              <!--              <div class="examples clearfix">-->
              <!--                <div class="for_members_examples_title">{{ $t('for-members.task-examples') }}</div>-->
              <!--&lt;!&ndash;                <div class="for_members_examples_5">&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div class="for_members_example_fifth_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                      5 {{ $t('for-members.class') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_subject">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.type-example-5') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_text">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.example') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <a :href="class5Math" download="" class="for_members_example_download">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.download-btn') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </a>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div class="for_members_example_fifth_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                      5 {{ $t('for-members.class') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_subject">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.type-example-4') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_text">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.example') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <a :href="class5RussianLanguage" download="" class="for_members_example_download">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.download-btn') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </a>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div class="for_members_example_fifth_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                      5 {{ $t('for-members.class') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_subject">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.type-example-3') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_text">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.example') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <a :href="class5KazakhLanguage" download="" class="for_members_example_download">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.download-btn') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </a>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div class="for_members_example_fifth_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                      5 {{ $t('for-members.class') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_subject">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.type-example-6') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_text">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.example') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <a :href="class5EnglishLanguage" download="" class="for_members_example_download">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.download-btn') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </a>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                  <div class="for_members_example_fifth_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_grade">&ndash;&gt;-->
              <!--&lt;!&ndash;                      5 {{ $t('for-members.class') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_subject">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.type-example-7') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <div class="for_members_example_text">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.example') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <a :href="class5NaturalScience" download="" class="for_members_example_download">&ndash;&gt;-->
              <!--&lt;!&ndash;                      {{ $t('for-members.download-btn') }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </a>&ndash;&gt;-->
              <!--&lt;!&ndash;                  </div>&ndash;&gt;-->

              <!--&lt;!&ndash;                </div>&ndash;&gt;-->
              <!--                <div class="for_members_examples_5">-->
              <!--                  <div class="for_members_example_fifth_grade six_grade">-->
              <!--                    <div class="for_members_example_grade example_six_grade">-->
              <!--                      6 {{ $t('for-members.class') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_subject">-->
              <!--                      {{ $t('for-members.type-example-5') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_text">-->
              <!--                      {{ $t('for-members.example') }}-->
              <!--                    </div>-->
              <!--                    <a :href="class6Math" download="" class="for_members_example_download">-->
              <!--                      {{ $t('for-members.download-btn') }}-->
              <!--                    </a>-->
              <!--                  </div>-->
              <!--                  <div class="for_members_example_fifth_grade six_grade">-->
              <!--                    <div class="for_members_example_grade example_six_grade">-->
              <!--                      6 {{ $t('for-members.class') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_subject">-->
              <!--                      {{ $t('for-members.type-example-4') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_text">-->
              <!--                      {{ $t('for-members.example') }}-->
              <!--                    </div>-->
              <!--                    <a :href="class5RussianLanguage" download="" class="for_members_example_download">-->
              <!--                      {{ $t('for-members.download-btn') }}-->
              <!--                    </a>-->
              <!--                  </div>-->
              <!--                  <div class="for_members_example_fifth_grade six_grade">-->
              <!--                    <div class="for_members_example_grade example_six_grade">-->
              <!--                      6 {{ $t('for-members.class') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_subject">-->
              <!--                      {{ $t('for-members.type-example-3') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_text">-->
              <!--                      {{ $t('for-members.example') }}-->
              <!--                    </div>-->
              <!--                    <a :href="class5KazakhLanguage" download="" class="for_members_example_download">-->
              <!--                      {{ $t('for-members.download-btn') }}-->
              <!--                    </a>-->
              <!--                  </div>-->
              <!--                  <div class="for_members_example_fifth_grade six_grade">-->
              <!--                    <div class="for_members_example_grade example_six_grade">-->
              <!--                      6 {{ $t('for-members.class') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_subject">-->
              <!--                      {{ $t('for-members.type-example-6') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_text">-->
              <!--                      {{ $t('for-members.example') }}-->
              <!--                    </div>-->
              <!--                    <a :href="class5EnglishLanguage" download="" class="for_members_example_download">-->
              <!--                      {{ $t('for-members.download-btn') }}-->
              <!--                    </a>-->
              <!--                  </div>-->
              <!--                  <div class="for_members_example_fifth_grade six_grade">-->
              <!--                    <div class="for_members_example_grade example_six_grade">-->
              <!--                      6 {{ $t('for-members.class') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_subject">-->
              <!--                      {{ $t('for-members.type-example-7') }}-->
              <!--                    </div>-->
              <!--                    <div class="for_members_example_text">-->
              <!--                      {{ $t('for-members.example') }}-->
              <!--                    </div>-->
              <!--                    <a :href="class5NaturalScience" download="" class="for_members_example_download">-->
              <!--                      {{ $t('for-members.download-btn') }}-->
              <!--                    </a>-->
              <!--                  </div>-->

              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
      class5LogicalThinking: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_logicheskoe_myshlenie.pdf" : "/files/download_examples/5_class/5_class_logikalyk_oilay.pdf",
      class5SpatialThinking: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_prostranstvennoe_myshlenie.pdf" : "/files/download_examples/5_class/5_class_kenistikte_oilay.pdf",
      class6LogicalThinking: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_logicheskoe_myshlenie.pdf" : "/files/download_examples/6_class/6_class_logikalyk_oilay.pdf",
      class6SpatialThinking: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_prostranstvennoe_myshlenie.pdf" : "/files/download_examples/6_class/6_class_kenistikte_oilay.pdf",
      class5Math: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_matematicheskaya_gramotnost.pdf" : "/files/download_examples/5_class/5_class_matematikalyk_sauattylyk.pdf",
      class5RussianLanguage: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_russkii_yazik.rar" : "/files/download_examples/5_class/5_class_orys_tili.rar",
      class5KazakhLanguage: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_kazakhskii_yazyk.rar" : "/files/download_examples/5_class/5_class_kazakh_tili.rar",
      class5EnglishLanguage: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_anglijskij_yazyk.rar" : "/files/download_examples/5_class/5_agylshyn_tili.rar",
      class5NaturalScience: this.$i18n.locale === 'ru' ? "/files/download_examples/5_class/5_class_estestnoznanie.pdf" : "/files/download_examples/5_class/5_class_zharatylystany.pdf",
      class6Math: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_matematicheskaya_gramotnoct.pdf" : "/files/download_examples/6_class/6_class_matematikalyk_sayattylyk.pdf",
      class6RussianLanguage: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_rysskii_yazik.rar" : "/files/download_examples/6_class/6_class_orys_tili.rar",
      class6KazakhLanguage: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_kazakhskii_yazik.rar" : "/files/download_examples/6_class/6_class_kazakh_tili.rar",
      class6EnglishLanguage: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_angliiskii_yazik.rar" : "/files/download_examples/6_class/6_class_agylshyn_tili.rar",
      class6NaturalScience: this.$i18n.locale === 'ru' ? "/files/download_examples/6_class/6_class_estestvoznanie.pdf" : "/files/download_examples/6_class/6_class_zharatylystany.pdf",
    }
  },
}
</script>
<style>
.about-content-background {
  left: -135px !important;
  top: 10px !important;
}

.item-first {
  left: -177px;
  width: 319px;
  height: 319px;
}

.item-second {
  width: 217px;
  height: 217px;
  top: 128px;
  z-index: 9;
}

.item-third {
  left: 98px;
  width: 256px;
  height: 256px;
}

.about-content-image {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1000px;
  background-position: center;
  border: 10px solid #FFFFFF;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%), 0px 4px 4px rgb(0 0 0 / 4%);
}
</style>
<style scoped>
.examples {
  margin-top: 17rem;
}
</style>
