<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-5 col-md-12">
        <div class="welcome-text">
          <h1>{{ $t('reset-password.forgot-password') }}</h1>
          <div class="description">
            <p class="pt-3">{{ $t('reset-password.password-recovery-method') }}</p>
            <div class="form-group py-4">
              <el-radio v-model="method" label="email">{{ $t('reset-password.recovery-via-email') }}</el-radio>
              <el-radio v-model="method" label="sms">{{ $t('reset-password.recovery-via-sms') }}</el-radio>
            </div>
            <div class="form-group pt-5">
              <el-button type="primary" @click="next">{{ $t('reset-password.next-btn') }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12 py-5">
        <img src="/images/signin-bg.svg" width="610" style="max-width: 100%;">
      </div>
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data(){
    return {
      method: 'email',
    }
  },
  methods: {
    next(){
      this.$router.push('/forget-password/' + this.method);
    }
  },
}
</script>
