<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-12">
        <div class="welcome-text">
          <h1>{{ $t('reset-password.title') }}</h1>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="w-100">
          <div v-if="!smsSended" class="description">
            <form-group :field="$v.phone" :label="$t('signin-form.phone-label')" name="phone" class="mt-4">
              <el-input
                  :placeholder="$t('signin-form.phone-placeholder')"
                  v-model="phone"
                  v-mask="'+7 (###) ### ##-##'"
                  @input="enforcePhoneFormat"
                  @blur="onGetIin"
                  autocomplete="off">
              </el-input>
            </form-group>
            <form-group :field="$v.form.iin" :label="$t('signin-form.member-label')" name="iin">
              <el-select v-model="form.iin" :placeholder="$t('signin-form.member-placeholder')" :loading="iinLoading">
                <el-option
                    v-for="iin in iinArray"
                    @change="$v.form.iin.$touch()"
                    :key="iin.value"
                    :label="iin.label"
                    :value="iin.value">
                </el-option>
              </el-select>
            </form-group>
            <div class="form-group pt-2">
              <el-button type="primary" @click="sendSms">{{ $t('reset-password.next-btn') }}</el-button>
            </div>
          </div>
          <div v-if="smsSended" class="description">
            <div v-if="phoneVerified" class="w-100">
              <div class="form-group my-4">
                <label>{{ $t('reset-password.password-label') }}</label>
                <el-input
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="8"
                    type="number"
                    :placeholder="$t('reset-password.password-placeholder')"
                    v-model="password"
                    @input="onPasswordInput"
                    show-password
                    autocomplete="off">
                </el-input>
              </div>
              <div class="form-group">
                <label>{{ $t('reset-password.password-confirm-label') }}</label>
                <el-input
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    maxlength="8"
                    type="number"
                    :placeholder="$t('reset-password.password-confirm-placeholder')"
                    v-model="password_confirmation"
                    @input="onPasswordConfirmInput"
                    show-password
                    autocomplete="off">
                </el-input>
              </div>
              <div class="form-group pt-2">

                <el-button type="primary" @click="resetPassword">{{ $t('reset-password.recovery-btn') }}</el-button>

              </div>
            </div>
            <div v-else class="w-100">
              <div class="form-group my-4">
                <label>{{ $t('reset-password.enter-sms-code') }}</label>
                <el-input
                    :placeholder="$t('reset-password.enter-sms-code-placeholder')"
                    v-model="code"
                    autocomplete="off">
                </el-input>
              </div>
              <p class="sms-note">{{$t('reset-password.sms-note')}}</p>
              <div class="form-group pt-2">
                <el-button type="primary" @click="verification">{{ $t('reset-password.next-btn') }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <img v-if="!phoneVerified" src="/images/signin-bg.svg" width="610" class="float-right" style="max-width: 100%;">
        <div v-else class="py-5">
          <transition name="hint" appear>
            <div v-if='passwordValidation.errors.length > 0 && !submitted' class='hints'>
              <p :class="{'text-success': error.active, 'text-danger': !error.active}" v-for='error in passwordValidation.errors'><span></span>{{error.message}}</p>
            </div>
          </transition>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import LoginForm from '@/components/LoginForm'
import {mapActions} from "vuex";
import {minLength, required} from "vuelidate/lib/validators";
export default {
  name: 'Login',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    LoginForm
  },
  data(){
    return {
      code: '',
      token: '',
      email: '',
      smsSended: false,
      phoneVerified: false,
      iinLoading: false,
      password: '',
      password_confirmation: '',
      iinArray: [],
      phone: '',
      passwordValid: false,
      submitted:false,
      rules: [
        { active: false, message: this.$t('signin-form.password-must2')+'. '+this.$t('signin-form.password-must3'), regex:/.{8,}/ },

        // { active: false, message:"Допустимые символы: ! . - _ ?",  regex:/[!.\-_?]/ },
      ],
      form: {
        phone: '',
        iin: ''
      }
    }
  },
  methods: {
    addOptionsToSelect() {
      this.iinArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.iin;
        this.iinArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    onGetIin() {
      this.iinLoading = true;
      if (!this.$v.form.phone.$invalid){
        if (this.form.phone != '') {
          this.getIin({
            phone: this.form.phone
          }).then((res) => {
            this.iinLoading = false;
            this.addOptionsToSelect()
          }).catch((e) => {
            this.iinLoading = false;
            console.log(e);
          })
        }
      }
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.form.phone = x[0];
    },
    sendSms(){
      this.$v.form.iin.$touch()
      if (this.$v.form.iin.$invalid) return
      this.$http.get(window.API_ROOT + '/api/password/reset/sms?iin=' + this.form.iin)
        .then((res) => {
          if (res.body.status == 'success') {
            Vue.toastr({
              message: 'Success',
              description: this.$t('reset-password.sms-sended'),
              type: 'success'
            });
            this.token = res.body.token;
            this.email = res.body.email;
            this.smsSended = true;
          } else {
            Vue.toastr({
              message: this.$t('error'),
              description: res.body.status,
              type: 'error'
            });
          }
        })
        .catch((e) => {
          console.log(e);
        })
    },
    verification(){
      this.$http.post(window.API_ROOT + '/api/password/reset/check-sms', {
        code: this.code,
        token: this.token
      })
      .then((res) => {
        if (res.body.status == 'success') {
          Vue.toastr({
            message: 'Success',
            description: this.$t('reset-password.phone-verified'),
            type: 'success'
          });
          this.phoneVerified = true;
        } else {
          Vue.toastr({
            message: this.$t('error'),
            description: this.$t('reset-password.verification-error'),
            type: 'error'
          });
        }
      })
      .catch((e) => {

        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.verification-error'),
          type: 'error'
        });
        console.log(e);
      })
    },
    resetPassword(){
      if (!this.passwordValid) return;
      this.$http.post(window.API_ROOT + '/api/password/update/sms', {
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then((res) => {
          Vue.toastr({
            message: res.body.status,
            description: res.body.message,
            type: res.body.status
          })
          if (res.body.status == 'success') {
            this.$router.push({path: '/signin'})
          }
        })
        .catch((e) => {
          Vue.toastr({
            message: this.$t('error'),
            description: e.body.message,
            type: 'error'
          })
        })
    },
    onPasswordInput(e){
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    onPasswordConfirmInput(e){
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.password_confirmation = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    ...mapActions({
      'getIin': 'getIin',
    })
  },
  computed: {
    notSamePasswords () {
      if (this.passwordsFilled) {
        return (this.password !== this.password_confirmation)
      } else {
        return false
      }
    },
    passwordsFilled () {
      return (this.password !== '' && this.password_confirmation !== '')
    },
    passwordValidation () {
      let errors = []
      for (let condition of this.rules) {
        condition.active = condition.regex.test(this.password);
        // if (!condition.regex.test(this.password)) {
        errors.push({
          message: condition.message,
          active: condition.active
        });
        // }
      }
      let error = false;
      Object.entries(errors).forEach(entry => {
        const [key, value] = entry;
        if (value.active == false){
          error = true;
        }
      });
      this.passwordValid = !error;
      return { valid:!error, errors }
    }
  },
  validations: {
    phone: {
      required: false,
      minLength: minLength(18)
    },
    form: {
      iin: {
        required,
      },
      phone: {
        minLength: minLength(11)
      },
    }
  }
}
</script>
<style>
.text-danger span{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('/images/close.svg');
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  background-color: #dc3545;
  float: left;
  border-radius: 24px;
  margin-right: 10px;
}
.text-success span{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("/images/ok.svg");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  background-color: #018837;
  float: left;
  border-radius: 24px;
  margin-right: 10px;
}
.el-alert .el-alert__title{
  text-transform: capitalize;
}
.sms-note {
  color: #cdcdcd;
}
</style>