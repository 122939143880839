<template>
<div class="view-home">
	<div class="container">
		<div class="special-schools-page row mt-5">
      <div class="col-12">
        <div class="welcome-text">
          <h1 class="title">{{ $t('special-schools.title')}}</h1>
        </div>
        <div class="spec-schools-form">
          <div class="col-12 mt-5 w-100 top-of-table">
            <form action="" class="py-3">
              <div class="form-group mb-0">
                <div class="form-row">
                  <div class="col-8">
                    <input type="text" class="form-control search-input" :placeholder="$t('for-winners.search-results')" @input="handleSearch">
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-secondary btn-filter ml-2" type="button"
                            @click="toggleFilterForm">{{ $t('for-winners.filters') }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div class="col-md-6 col-12 border-bottom order-lg-1 order-3" v-if="meta && meta.total > 10">
              <ul class="pagination py-3">
                <li v-for="(link, index) in meta.links" :key="link.label" style="cursor: pointer">
                  <a @click.prevent="links.prev ? goToPage(meta.current_page - 1) : null" v-if="index === 0">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.240234 5.00879C0.240234 5.21875 0.323242 5.39941 0.484375 5.56055L4.23437 9.21777C4.36133 9.34473 4.51758 9.41309 4.70312 9.41309C5.08398 9.41309 5.38672 9.11035 5.38672 8.73438C5.38672 8.54883 5.31348 8.37305 5.17676 8.23633L1.85645 5.01367L5.17676 1.78613C5.30859 1.64941 5.38672 1.47852 5.38672 1.28809C5.38672 0.912109 5.08398 0.609375 4.70312 0.609375C4.51758 0.609375 4.36133 0.677734 4.22949 0.804688L0.484375 4.46191C0.318359 4.63281 0.240234 4.80371 0.240234 5.00879Z"
                          fill="#69788C"/>
                    </svg>
                  </a>
                  <a @click.prevent="links.next ? goToPage(meta.current_page + 1) : null" v-else-if="index === meta.links.length - 1">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M5.38672 5.01367C5.38672 4.80371 5.30371 4.62305 5.14258 4.46191L1.39258 0.804688C1.26562 0.677734 1.10937 0.609375 0.923828 0.609375C0.542969 0.609375 0.240234 0.912109 0.240234 1.28809C0.240234 1.47363 0.313477 1.64941 0.450195 1.78613L3.77051 5.00879L0.450195 8.23633C0.318359 8.37305 0.240234 8.54395 0.240234 8.73438C0.240234 9.11035 0.542969 9.41309 0.923828 9.41309C1.10937 9.41309 1.26562 9.34473 1.39746 9.21777L5.14258 5.56055C5.30859 5.38965 5.38672 5.21875 5.38672 5.01367Z"
                          fill="#69788C"/>
                    </svg>
                  </a>
                  <a @click.prevent="goToPage(link.label)" :class="{active: link.active}" v-else>
                    {{link.label}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="px-0 col-12 mx-auto card mb-5">
            <div class="col-12 order-lg-1 order-2">
              <div class="table-response">
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>{{ $t('special-schools.school-name') }}</th>
                      <th>{{ $t('special-schools.address')}}</th>
                      <th>{{ $t('special-schools.contacts') }}</th>
                      <th> {{ $t('special-schools.website') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(school, index) in spec_schools" :key="index">
                      <td>{{school.name}}</td>
                      <td>{{school.address}}</td>
                      <td>{{school.contacts}}</td>
                      <td>{{school.site}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 form-filter" :class="{'show': filterBlock}">
              <div class="filters row pt-4">
                <div class="col-lg-4 col-md-12">
                  <label for="">{{ $t('references.region-label') }}</label>
                  <select id="regions" class="form-control" @input="setRegion">
                    <option value="">{{ $t('for-winners.filter-region') }}</option>
                    <option v-for="(region, index) in regions" :key="index" :value="region.id">{{region.name}}</option>
                  </select>
                </div>
                <div class="col-lg-4 col-md-12">
                  <label for="">{{ $t('references.language-label') }}</label>
                  <select class="form-control" id="language" @input="setLang">
                    <option value="">{{ $t('for-winners.filter-language') }}</option>
                    <option value="ru">{{ $t('testing.russian-lang') }}</option>
                    <option value="kz">{{ $t('testing.kazakh-lang') }}</option>
                  </select>
                  <p class="danger-error">{{this.errorLang}}</p>
                </div>
                <div class="col-lg-4 col-md-12">
                  <label for="">{{ $t('references.class-label') }}</label>
                  <select class="form-control" id="classes" @input="setClass">
                    <option value="">{{ $t('for-winners.filter-class') }}</option>
                    <option value="1">5</option>
                    <option value="2">6</option>
                    <option value="3">7</option>
                  </select>
                  <p class="danger-error">{{this.errorClass}}</p>
                </div>
                <div class="col-12 text-right mt-3">
                  <div class="btn btn-filter-modal" @click="setFilter">{{ $t('references.apply-btn') }}</div>
                  <div class="btn btn-filter-modal" @click="resetFilter">{{ $t('references.reset-btn') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
		</div>
	</div>
</div>
</template>
<script>
export default {
  name: 'SpecialSchools',
	data(){
	  return {
	    filterBlock: false,
      spec_schools: [],
      filters: {},
      regions: [],
      errorLang: '',
      errorClass: '',
      meta: {},
      links: {},
      pages: 0,
      search: null,
      debounceTimeout: null,
    }
  },
  validations: {
  },
  methods: {
    async getSpecSchools() {
      try {
        const res = await this.$http.get(`${API_ROOT}/api/schools-for-admission`)
        this.updateList(res)
      }
      catch (e) {
        console.log(e)
      }
    },
    updateList(response){
      if(response.body) {
        this.spec_schools = response.body.data
        this.links = response.body.links
        this.meta = response.body.meta
      }else{
        this.spec_schools = []
        this.links = []
        this.meta = []
      }
    },
    async handleSearch(event) {
      this.search = null
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(async () => {
        this.search = event.target.value
        const response = await this.$http.get(`${API_ROOT}/api/schools-for-admission?lang=${this.$i18n.locale}${this.filters.language ? `instruct_lang=${this.filters.language}` : ''}${this.filters.class ? `&class=${this.filters.class}` : ''}${this.filters.region ? `&region=${this.filters.region}` : ''}${this.search ? `&search=${this.search}` : ''}`)
        this.updateList(response)
      }, 600)
    },
    toggleFilterForm() {
      this.filterBlock = !this.filterBlock
    },
    setRegion() {
      var e = document.getElementById('regions');
      this.filters.region = e.value;
    },
    setLang() {
      var e = document.getElementById('language');
      this.filters.language = e.value;
    },
    setClass() {
      var e = document.getElementById('classes');
      this.filters.class = e.value;
    },
    async setFilter(){
      const res = await this.$http.get(`${API_ROOT}/api/schools-for-admission?lang=${this.$i18n.locale}${this.filters.language ? `instruct_lang=${this.filters.language}` : ''}${this.filters.class ? `&class=${this.filters.class}` : ''}${this.filters.region ? `&region=${this.filters.region}` : ''}`)
      this.updateList(res)
      this.filterBlock = false
    },
    async getRegions(){
      const response = await this.$http.get(`${API_ROOT}/api/references/regions/`)
      this.regions = response.body.data;
    },
    async goToPage(page){
      const response = await this.$http.get(`${API_ROOT}/api/schools-for-admission?lang=${this.$i18n.locale}${this.filters.language ? `instruct_lang=${this.filters.language}` : ''}${this.filters.class ? `&class=${this.filters.class}` : ''}${this.filters.region ? `&region=${this.filters.region}` : ''}${page && `&page=${page}`}`)
      this.updateList(response)
    },
    resetFilter(){
      document.getElementById("regions").selectedIndex = 0;
      document.getElementById("language").selectedIndex = 0;
      document.getElementById("classes").selectedIndex = 0;
      this.getSpecSchools();
    }
  },
  mounted() {
    this.getSpecSchools();
    this.getRegions();
  }
}
</script>
<style>
.special-schools-page .title {
  color: #05458C;
  padding-left: 60px;
}
.special-schools-page .card {
  border-top: 1px solid #dee2e6;
}
.spec-schools-form {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(4, 38, 82, 0.08);
  border-radius: 8px;
}
.table th {
  border-top: none;
}
.top-of-table {
  display: flex;
  margin: 3rem 0 0;
  width: 100%;
  justify-content: space-between;
}
.form-filter {
    border: 1px solid #E0E0E0;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 20px;
    position: absolute;
    top: 0px;
    left: -10px;
    right: -10px;
    visibility: hidden;
    transition: all 0.3s linear;
    opacity: 0;
    transform: translateY(-20px);
  }

  .form-filter .danger-error {
    color: red;
  }

  .form-filter.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .btn-filter-modal {
    padding: 14px 36px;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    font-weight: 500;
    margin-right: 15px;
    transition: all 0.4s linear;
  }

  .btn-filter-modal:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 30);
  }

  @media (max-width: 1000px) {
    .table-response {
      overflow-x: auto;
    }
    .special-schools-page .title {
      padding: 0;
    }
    .top-of-table {
      flex-flow: column;
    }
  }
</style>
