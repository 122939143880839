<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-4 col-md-12">
        <div class="welcome-text">
          <h1>{{ $t('reset-password.title') }}</h1>
        </div>
        <div class="w-100">
          <div v-if="EmailSended" class="description">
            <h1 class="email-sended">{{ $t('reset-password.email-sended') }}</h1>
          </div>
          <div v-else class="description">
            <form-group :field="$v.email" :label="$t('reset-password.email-label')" name="email" class="mt-4" :class="{'text-danger': !$v.email.email || $v.email.$dirty&&!$v.email.required}">
              <el-input
                  id="email"
                  :placeholder="$t('reset-password.email-placeholder')"
                  v-model="email"
                  @change="$v.email.$touch()"
                  @blur="onGetIin"
                  clearable>
              </el-input>
              <p v-if="!$v.email.email" class="help-block text-danger">{{ $t('form-group.email-required') }}</p>
<!--              <p v-if="$v.email.$dirty&&!$v.email.required" class="help-block text-danger">{{ $t('form-group.required') }}</p>-->
            </form-group>

<!--            <form-group :field="$v.iin" :label="$t('signin-form.member-label')" name="iin">-->
<!--              <el-select v-model="iin" :placeholder="$t('signin-form.member-placeholder')" :loading="iinLoading">-->
<!--                <el-option-->
<!--                    v-for="iin in iinArray"-->
<!--                    @change="$v.iin.$touch()"-->
<!--                    :key="iin.value"-->
<!--                    :label="iin.label"-->
<!--                    :value="iin.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </form-group>-->

            <div class="form-group">
              <el-button type="primary" @click="sendConfirmation">{{ $t('reset-password.next-btn') }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 py-5 text-right">
        <img src="/images/signin-bg.svg" width="610" style="max-width: 100%;">
      </div>
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
import {mapActions} from "vuex";
import {minLength, minValue, required, email} from "vuelidate/lib/validators";
export default {
  name: 'Login',
  components: {
    LoginForm
  },
  methods: {
    sendConfirmation(){
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$http.get(window.API_ROOT + `/api/password/reset/email?email=${this.email}&is_teacher=1`)
        .then((res) => {
          this.EmailSended = true;
          console.log(res);
        }).catch((e) => {
          console.log(e);
        })
    },
    addOptionsToSelect() {
      this.iinArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.iin;
        this.iinArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    onGetIin() {
      this.iinLoading = true;
      if (!this.$v.email.$invalid){
        if (this.email != '') {
          this.getIinByEmail({
            email: this.email
          }).then((res) => {
            this.iinLoading = false;
            this.addOptionsToSelect()
          }).catch((e) => {
            this.iinLoading = false;
            console.log(e);
          })
        }
      }
    },
    ...mapActions({
      'getIinByEmail': 'getIinByEmail',
    })
  },
  data(){
    return {
      email: '',
      iin: '',
      iinArray: [],
      iinLoading: false,
      EmailSended: false
    }
  },
  validations: {
    email: { email, required },
    // iin: { required }
  }
}
</script>

<style>

.email-sended {
  font-size: 18px;
}

</style>
