import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import Sidebar from '@/components/Sidebar'
import SidebarTeacher from "@/components/SidebarTeacher";

export default [
    {
        path: '/',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Index')
        }
    },
    {
        path: '/signin',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signin')
        }
    },
    {
        path: '/signin-teacher',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/SigninTeacher')
        }
    },
    {
        path: '/signup-by-iin',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-by-IIn')
        }
    },
    {
        path: '/signup-by-iin/phone-confirm',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-by-IIn-phone-confirm')
        }
    },
    {
        path: '/signup-by-iin/additional-info',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-by-IIn-additional-info')
        }
    },
    {
        path: '/signup-by-iin/third-step',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-by-IIn-3Step')
        }
    },
    {
        path: '/signup-teacher/first-step',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-Teacher-1Step-New')
        }
    },
    {
        path: '/signup-teacher/teacher-email-confirm',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-Teacher-2Step-New')
        }
    },
    {
        path: '/signup-teacher/third-step',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Auth/Signup/Signup-Teacher-3Step-New')
        }
    },



    {
        path: '/forget-password',
        components: {header: Navbar, footer: Footer, default: () => import('@/views/Auth/ForgetPassword/Index')}
    },
    {
        path: '/forget-password/sms',
        components: {header: Navbar, footer: Footer, default: () => import('@/views/Auth/ForgetPassword/Sms')}
    },
    {
        path: '/forget-password/email',
        components: {header: Navbar, footer: Footer, default: () => import('@/views/Auth/ForgetPassword/Email')}
    },
    {
        path: '/reset-password',
        components: {header: Navbar, footer: Footer, default: () => import('@/views/Auth/ResetPassword')}
    },
    {
        path: '/forget-password-teacher/email',
        components: {header: Navbar, footer: Footer, default: () => import('@/views/Auth/ForgetPassword/EmailTeacher')}
    },
    {
        path: '/mail-confirmed',
        components: {default: () => import('@/views/Auth/MailConfirmed')}
    },
    // {
    //   path: '/signup',
    //   components: {
    //     header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Index')
    //   }
    // },
    // {
    //   path: '/signup/password',
    //   components: {
    //     header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Password')
    //   }
    // },
    // {
    //   path: '/signup/photo',
    //   components: {
    //     header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Photo')
    //   }
    // },
    // {
    //   path: '/signup/education',
    //   components: {
    //     header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Education')
    //   }
    // },
    // {
    //   path: '/signup/parents',
    //   components: {
    //     header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Parents')
    //   }
    // },
    // {
    //   path: '/signup/finish',
    //   components: {
    //     header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Finish')
    //   }
    // },
    {
        path: '/check-school',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/CheckSchool')
        }
    },
    {
        path: '/school/:id',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/School')
        }
    },
    {
        path: '/about',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/About')
        }
    },
    {
        path: '/for-members',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/ForMembers')
        }
    },
    {
        path: '/for-teachers',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/ForTeachers')
        }
    },
    {
        path: '/instructions',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/Instructions')
        }
    },
    {
        path: '/for-winners',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/ForWinners')
        }
    },
    {
        path: '/special-schools',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/SpecialSchools')
        }
    },
    {
        path: '/faq',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/Faq')
        }
    },
    {
        path: '/profile',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Index')
        }
    },
    {
        path: '/teacher-profile',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/Index')
        }
    },
    {
        path: '/teacher-experience',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/Experience')
        }
    },
    {
        path: '/teacher-olympiad-winners',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/TeacherOlympiadWinners')
        }
    },
    {
        path: '/teacher-settings',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/TeacherSettings')
        }
    },
    {
        path: '/notification-teacher',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/NotificationTeacher')
        }
    },
    {
        path: '/notification-teacher-letter',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/NotificationResultTeacher')
        }
    },
    {
        path: '/testing',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Testing')
        }
    },
    {
        path: '/results',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Results')
        }
    },
    {
        path: '/my_teachers',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/MyTeachers')
        }
    },
    {
        path: '/notification',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Notification')
        }
    },
    {
        path: '/notification-test-result/:id',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/notificationTestResult')
        }
    },
    {
        path: '/notification-result/:id',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/NotificationResult')
        }
    },
    {
        path: '/notification-result-second/:id',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/NotificationResult-Second')
        }
    },
    {
        path: '/notification-result-rejected',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/NotificationResult-Third')
        }
    },
    {
        path: '/settings',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Settings')
        }
    },
    {
        path: '/cabinet',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Cabinet')
        }
    },
    {
        path: '/teacher-cabinet',
        components: {
            sidebar: SidebarTeacher, default: () => import('@/views/TeacherProfile/TeacherCabinet')
        }
    },
    {
        path: '/schools-list',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/SchoolsList')
        }
    },
    {
        path: '/moderation-policy',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/ModerationPolicyNew')
        }
    },
    {
        path: '/terms-of-use',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/TermsOfUse')
        }
    },
    {
        path: '/privacy-policy',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/PrivacyPolicy.vue')
        }
    },
    {
        path: '*',
        components: {
            header: Navbar,
            footer: Footer,
            default: Empty
        }

    }
]


