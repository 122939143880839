<template>
  <div class="content profile-content">
    <ProfileNavbarTeacher :title="$t('profile.title')"></ProfileNavbarTeacher>
    <div class="clearfix">
      <div>
        <div class="col-md-12 filter">
          <!-- <div class="row"> -->
          <!-- <div class="col-md-8"> -->
          <div id="tabsMenu">
            <ul class="nav nav-tabs d0flex flex-row">
              <li class="nav-item">
                <a
                    class="nav-link notifications-title text-center"
                    @click.prevent="setActive('new')"
                    :class="{ active: isActive('new') }"
                    href="#new"
                >{{
                    $t('profile.notifications.new-notifications-tab-title')
                  }}
                  ({{ newNotifications.length }})</a
                >
              </li>
              <li class="nav-item">
                <a
                    class="nav-link notifications-title text-center"
                    @click.prevent="setActive('viewed')"
                    :class="{ active: isActive('viewed') }"
                    href="#viewed"
                >{{
                    $t('profile.notifications.viewed-notification-tab-title')
                  }}
                  ({{ viewedNotifications.length }})</a
                >
              </li>
            </ul>
          </div>
          <!-- </div> -->
          <div>
            <button
                class="btn btn-outline-secondary btn-filter ml-3 filter-title"
                type="button"
                @click="toggleFilterForm"
            >
              {{ $t('profile.notifications.filter-title') }}
            </button>
            <div class="form-filter" :class="{ show: isFilterFormVisible }">
              <div class="">
                <div class="form-check">
                  <label class="form-check-label" for="all">
                    {{ $t('profile.notifications.filter-all-title') }}
                    <input
                        v-model="filter"
                        class="form-check-input"
                        type="radio"
                        name="filter"
                        id="all"
                        value="0"
                        checked
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="firstPart">
                    {{ $t('profile.notifications.filter-first-test-title') }}
                    <input
                        v-model="filter"
                        class="form-check-input"
                        type="radio"
                        name="filter"
                        id="firstPart"
                        value="2"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="secondPart">
                    {{ $t('profile.notifications.filter-second-test-title') }}
                    <input
                        v-model="filter"
                        class="form-check-input"
                        type="radio"
                        name="filter"
                        id="secondPart"
                        value="3"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="startSchool">
                    {{ $t('profile.notifications.filter-entrance-title') }}
                    <input
                        v-model="filter"
                        class="form-check-input"
                        type="radio"
                        name="filter"
                        id="startSchool"
                        value="4"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="filter-btns mt-4">
                <button
                    class="btn btn-filter-modal"
                    @click="getFilterNotifications()"
                >
                  {{ $t('profile.notifications.filter-btn-title') }}
                </button>
                <button class="btn btn-filter-modal ml-2" @click="clearFilter">
                  {{ $t('profile.notifications.filter-btn-clear-title') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content" id="myTabContent">
        <div
            class="tab-pane fade"
            :class="{ 'active show': isActive('new') }"
            id="new"
        >
          <div class="notification-block">
            <h3 class="new-notification-title">
              {{ $t('profile.notifications.new-notification-title') }}
            </h3>
            <div class="col-md-12 mt-5">
              <div v-if="newNotifications.length < 1">
                {{ $t('profile.notifications.notifications-not-found') }}
              </div>
              <div
                  class="row notification mt-1"
                  v-for="(notification, index) in newNotifications"
                  :key="index"
                  @click="goToNotification(notification.type,notification.id, notification.data)"
                  :class="notification.type=== `App\\Notifications\\TeacherThankYouLetterFileNotification` ? 'clickable' : null"
              >
                <div class="col-md-3 justify-content-center notification-title">
                  {{ notification.data.subject[$i18n.locale] }}
                </div>
                <div
                    class="col-md-7 justify-content-center notification-description"
                    v-html="notification.data.message[$i18n.locale]"
                >
                </div>
                <div class="col-md-2 justify-content-center notification-data">
                  {{ notification.date }}
                  <span class="notification-data-img">
										<img src="/images/profile/notification-image.svg" alt=""/>
									</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="tab-pane fade"
            :class="{ 'active show': isActive('viewed') }"
            id="viewed"
        >
          <div class="notification-block">
            <h3 class="new-notification-title">
              {{ $t('profile.notifications.viewed-notifications-title') }}
            </h3>
            <div class="col-md-12 mt-5">
              <div v-if="viewedNotifications.length < 1">
                {{ $t('profile.notifications.notifications-not-found') }}
              </div>
              <div
                  class="row notification mt-1"
                  v-for="(notification, index) in viewedNotifications"
                  :key="index"
                  :class="notification.type === 2 || notification.type === 3  || notification.type===6? 'clickable' : null"
                  @click="goToNotification(notification.type, notification.id, notification.data)"
              >
                <div class="col-md-3 justify-content-center notification-title">
                  {{ notification.data.subject }}
                </div>
                <div
                    class="col-md-7 justify-content-center notification-description"
                    v-html="notification.data.message"
                >
                </div>
                <div class="col-md-2 justify-content-center notification-data">
                  {{ notification.date }}
                  <!--20.01.2021 9:30-->
                  <span class="notification-data-img">
										<img src="/images/profile/notification-image.svg" alt=""/>
									</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import Vue from 'vue'
import ProfileNavbarTeacher from "@/components/ProfileNavbarTeacher";

export default {
  name: 'Notification',
  components: {
    ProfileNavbarTeacher
  },
  data() {
    return {
      API_ROOT: window.API_ROOT,
      filter: 0,
      newNotifications: [],
      viewedNotifications: [],
      activeItem: 'new',
      isFilterFormVisible: false
    }
  },

  created() {
    this.$http
        .get(window.API_ROOT + '/api/teacher/notifications')
        .then(res => {
          this.newNotifications = res.body.new
          this.viewedNotifications = res.body.readed
          // this.newNotifications = res.data.data.new
          // this.viewedNotifications = res.data.data.readed
        })
        .catch(e => {
          // Vue.toastr({
          //   message: 'Ошибка',
          //   description: 'Ошибка',
          //   type: 'error'
          // })
          console.log(e)
        })
  },

  methods: {
    readNotification(id) {
      this.$http
          .get(window.API_ROOT + `/api/teacher/notifications/${id}/read`)
          .then(res => {
            console.log(res)
          })
          .catch(e => {
            // Vue.toastr({
            //   message: 'Ошибка',
            //   description: 'Ошибка',
            //   type: 'error'
            // })
          })
    },
    toggleFilterForm() {
      this.isFilterFormVisible = !this.isFilterFormVisible
    },
    clearFilter() {
      this.$http
          .get(window.API_ROOT + '/api/teacher/notifications')
          .then(res => {
            this.newNotifications = res.body.new
            this.viewedNotifications = res.body.readed
            // this.newNotifications = res.data.data.new
            // this.viewedNotifications = res.data.data.readed
          })
          .catch(e => {
            // Vue.toastr({
            //   message: 'Ошибка',
            //   description: 'Ошибка',
            //   type: 'error'
            // })
          })
      this.isFilterFormVisible = false
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
    onChange(filter) {
      this.filter = filter.target.value
    },
    getFilterNotifications() {
      this.$http
          .get(
              `${window.API_ROOT}/api/teacher/notifications`, {
                params: {
                  filter: this.filter === 0 ? '' : this.filter
                }
              }
          )
          .then(res => {
            this.newNotifications = res.body.new
            this.viewedNotifications = res.body.readed
            // this.newNotifications = res.data.data.new
            // this.viewedNotifications = res.data.data.readed
          })
          .catch(e => {
            // Vue.toastr({
            //   message: 'Ошибка',
            //   description: 'Ошибка',
            //   type: 'error'
            // })
          })
      this.isFilterFormVisible = false
    },
    goToNotification(type, id, data) {
      localStorage.setItem('notification_teacher_data_result', JSON.stringify(data))
      if (type === 1 || type === 4 || type === null) return

      if (type === `App\\Notifications\\TeacherThankYouLetterFileNotification`) {
        this.readNotification(id)
        this.$router.push(`/notification-teacher-letter`)
      }

    }
  },
  mounted() {
    // this.$http
    //     .post(window.API_ROOT + '/api/user/notifications/update')
    //     .then(res => {
    //       console.log('created')
    //     })
    //     .catch(e => {
    //       Vue.toastr({
    //         message: 'Ошибка',
    //         description: 'Ошибка',
    //         type: 'error'
    //       })
    //     })
  }
}
</script>
<style scoped>
.notification-download {
  min-width: 200px;
  margin: 1rem;
  cursor: pointer;
  text-align: center;
  background-color: #2A8BF2;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 48px !important;
  text-decoration: none;
  color: white !important;
  border-radius: 10px;
  min-height: 48px;
  display: block;
  max-width: max-content;
}

.notification {
  height: 90px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.notification.clickable {
  cursor: pointer;
}

.notification-data-img {
  margin-left: 10px;
}

.notification-title {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #05458c;
}

.notification-description {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  height: 100%;
  overflow: hidden;
  color: #05458c;
}

.notification-data {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  color: #05458c;
}

.filter {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
}

.notifications-title {
  width: 218px;
}

.btn-filter {
  padding: 7px 36px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  color: black;
}

.form-filter {
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04),
  0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 20px;
  position: absolute;
  top: 50px;
  left: 20px;
  width: 414px;
  visibility: hidden;
  transition: all 0.3s linear;
  opacity: 0;
  transform: translateY(-20px);
}

.form-filter.show {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.btn-filter-modal {
  padding: 14px 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}

.form-check {
  margin-top: 15px;
  padding-left: 0;
}

.notification-block {
  margin-top: 60px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  padding: 9px 10px;
  background: #18a0fb;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 5px 5px 5px 5px;
  color: #ffffff;
}

.nav-tabs .nav-link {
  color: black;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 0px;
}

.nav-tabs {
  border-bottom: none;
}

.col-md-8 {
  max-width: 44%;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the radio button is checked, add a blue background */
.form-check-label input:checked ~ .checkmark {
  background-color: #18a0fb;
}

.form-check-label input:checked .form-check-label {
  background-color: #18a0fb;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
}

@media screen and (max-width: 700px) {
  .filter {
    padding-left: 0px;
    display: flex;
    flex-direction: column;
  }

  .filter-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .filter-btns .btn-filter-modal:first-child {
    margin-bottom: 14px;
    color: #fff;
    background-color: #18a0fb;
    padding: 12px 32px;
  }

  .filter-btns .btn-filter-modal:nth-child(2) {
    padding: 12px 36px;
  }

  .col-md-8 {
    max-width: 100%;
  }

  .btn-filter {
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .form-filter {
    left: 15px;
    top: 60px;
    width: 325px;
  }

  .btn-filter-modal {
    padding: 12px 10px;
  }

  .notification-title {
    font-size: 22px;
    order: 1;
    text-align: center;
  }

  .notification-data-img {
    position: absolute;
    top: -60px;
  }

  .notification-data-img img {
    width: 334px;
    height: 50px;
    object-fit: contain;
  }

  .notification-data {
    order: 2;
    margin-top: 60px;
  }

  .notification {
    margin-top: 20px !important;
    height: 241px;
  }

  .notification-description {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    height: 38px;
    order: 3;
  }

  .new-notification-title {
    display: flex;
    text-align: center;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
}

@media screen and (max-width: 490px) {
  .notifications-title,
  .filter-title {
    font-size: 11px;
  }

  .notifications-title {
    width: auto;
  }

  #tabsMenu ul li {
    width: 50%;
  }
}

@media screen and (max-width: 320px) {
  .form-filter {
    left: -20px;
    top: 60px;
    width: 320px;
  }

  .notification-data-img img {
    width: 320px;
  }
}
</style>
