import * as API from '@/api'
import {SendEmail} from "@/api";

const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL'

const getters = {}

const mutations = {
  [SEND_EMAIL_SUCCESS] (state, data) {
  },
  [SEND_EMAIL_FAIL] (state) {
  },
}

const actions = {
  sendEmail({commit}, data) {
    return API.SendEmail.save(data)
      .then((res) => {
        // commit(AUTH_CHECK_IIN_SUCCESS, res.body)
      })
      .catch((res) => {
        // commit(AUTH_CHECK_IIN_FAIL)
        return Promise.reject(res)
      })
  },
}

export default {
  getters,
  mutations,
  actions
}