<template>
    <div class="view-home instructions-bg">
        <div class="container">
            <div class="row about-page pb-5">
                <div class="col-md-12">
                    <div class="breadcrumb px-0">
                        <router-link to="/">{{ $t('breadcrumb.main-page') }}</router-link>
                        <router-link to="/instructions" class="active">{{ $t('breadcrumb.instructions') }}</router-link>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ins-wrapper">
                            <div class="ins-tabs">
                                <div class="ins-tabs_item" :class="tab === 0 ? 'active' : ''" @click="setTab(0)">{{$t('instructions-2.tab-0-step')}}</div>
                                <div class="ins-tabs_item" :class="tab === 1 ? 'active' : ''" @click="setTab(1)">{{$t('instructions-2.tab-1-step')}}</div>
                                <div class="ins-tabs_item" :class="tab === 2 ? 'active' : ''" @click="setTab(2)">{{$t('instructions-2.tab-2-step')}}</div>
                                <div class="ins-tabs_item" :class="tab === 3 ? 'active' : ''" @click="setTab(3)">{{$t('instructions-2.tab-3-step')}}</div>
                            </div>
                            <!-- 0 -->
                            <div class="ins-content" v-if="tab === 0">
                                <h2 class="title center">{{$t('instructions-0.title')}}</h2>
                                <p class="undertitle">{{$t('instructions-0.undertitle')}}</p>
                                <p class="attention" v-if="$i18n.locale === 'ru'">
                                    <span class="blue">{{$t('instructions-0.attention')}}</span>
                                    <span>{{$t('instructions-0.attention-text')}}</span>
                                </p>
                                <p class="attention" v-if="$i18n.locale === 'kz'">
                                    <span>{{$t('instructions-0.attention-text')}}</span>
                                    <span class="blue">{{$t('instructions-0.attention')}}</span>
                                </p>
                                <p class="attention">{{$t('instructions-0.one')}}</p>
                                <p class="attention">{{$t('instructions-0.two')}}</p>
                                <p class="attention">{{$t('instructions-0.three')}}</p>
                            </div>
                            <!-- 1 -->
                            <div class="ins-content" v-if="tab === 1">
                                <h2 class="title center">{{$t('instructions-1.title')}}</h2>
                                <p class="attention blue">{{$t('instructions-1.subtitle1')}}</p>
                                <p class="attention">{{$t('instructions-1.one-one')}}</p>
                                <p class="attention">{{$t('instructions-1.one-two')}}</p>
                                <p class="attention blue">{{$t('instructions-1.subtitle2')}}</p>
                                <p class="attention">{{$t('instructions-1.two-one')}}</p>
                                <p class="attention">{{$t('instructions-1.two-two')}}</p>
                            </div>
                            <!-- 2 -->
                            <div class="ins-content" v-if="tab === 2">
                                <h2 class="title">{{$t('instructions-2.title')}}</h2>
                                <p class="undertitle">{{$t('instructions-2.undertitle')}}</p>
                                <p class="consist blue">{{$t('instructions-2.consist')}}</p>
                                <div class="test-types">
                                    <div class="type_section">
                                        <img src="/images/logic-logo.svg" alt="">
                                        <p class="type">{{$t('instructions-2.logic')}}</p>
                                        <p>{{$t('instructions-2.logic-q')}}</p>
                                    </div>
                                    <div class="type_section">
                                        <img src="/images/space-logo.svg" alt="">
                                        <p class="type">{{$t('instructions-2.space')}}</p>
                                        <p>{{$t('instructions-2.space-q')}}</p>
                                    </div>
                                </div>
                                <div class="common-section">
                                    <p class="common blue">{{$t('instructions-2.common')}}</p>
                                    <div>
                                        <img src="/images/questions-block.svg" alt="">
                                        <p>40 {{$t('instructions-2.common-q')}}</p>
                                    </div>
                                    <div>
                                        <img src="/images/time-block.svg" alt="">
                                        <p>90 минут</p>
                                    </div>
                                </div>
                                <p class="description">
                                    <span class="blue">{{$t('instructions-2.logic-section')}}</span>
                                    <span>{{$t('instructions-2.logic-desc')}}</span>
                                </p>
                                <div class="examples">
                                    <h2 class="title">{{$t('instructions-2.examples')}}</h2>
                                    <p class="task">{{$t('instructions-2.1-task')}}</p>
                                    <p class="answers" v-html="$t('instructions-2.1-answers')"></p>
                                    <p class="right-answer">{{$t('instructions-2.right-answer')}} A</p>
                                    <p class="task">{{$t('instructions-2.2-task')}}</p>
                                    <img src="/images/task1.svg" alt="">
                                    <p class="right-answer">{{$t('instructions-2.right-answer')}} D</p>
                                </div>
                                <p class="description">
                                    <span class="blue">{{$t('instructions-2.space-section')}}</span>
                                    <span>{{$t('instructions-2.space-desc')}}</span>
                                </p>
                                <div class="examples">
                                    <h2 class="title">{{$t('instructions-2.examples')}}</h2>
                                    <p class="task" v-html="$t('instructions-2.3-task')"></p>
                                    <img src="/images/task2.svg" alt="">
                                    <p class="right-answer">{{$t('instructions-2.right-answer')}} C</p>
                                    <p class="task">{{$t('instructions-2.4-task')}}</p>
                                    <img src="/images/task3-1.svg" alt="">
                                    <p class="task" v-html="$t('instructions-2.4-task-q')"></p>
                                    <img src="/images/task3-2.svg" alt="">
                                    <p class="right-answer">{{$t('instructions-2.right-answer')}} A</p>
                                    <p class="task">{{$t('instructions-2.5-task')}}</p>
                                    <img src="/images/task4.svg" alt="">
                                    <p class="right-answer">{{$t('instructions-2.right-answer')}} C</p>
                                </div>
                                <p class="goodluck blue">{{$t('instructions-2.goodluck')}}</p>
                            </div>
                            <!-- 3 -->
                            <div class="ins-content" v-if="tab === 3">
                                <h2 class="title">{{$t('instructions-3.title')}}</h2>
                                <p class="undertitle">{{$t('instructions-3.undertitle')}}</p>
                                <p class="consist blue">{{$t('instructions-3.consist')}}</p>
                                <div class="test-types">
                                    <div class="type_section">
                                        <img src="/images/math-logo.svg" alt="">
                                        <p class="type">{{$t('instructions-3.math')}}</p>
                                        <p>{{$t('instructions-3.math-q')}}</p>
                                    </div>
                                    <div class="type_section">
                                        <img src="/images/lang-logo.svg" alt="">
                                        <p class="type">{{$t('instructions-3.mother-lang')}}</p>
                                        <p>{{$t('instructions-3.mother-lang-q')}}</p>
                                    </div>
                                    <div class="type_section">
                                        <img src="/images/english-logo.svg" alt="">
                                        <p class="type">{{$t('instructions-3.english')}}</p>
                                        <p>{{$t('instructions-3.english-q')}}</p>
                                    </div>
                                    <div class="type_section">
                                        <img src="/images/science-logo.svg" alt="">
                                        <p class="type">{{$t('instructions-3.science')}}</p>
                                        <p>{{$t('instructions-3.science-q')}}</p>
                                    </div>
                                </div>
                                <div class="common-section">
                                    <p class="common blue">{{$t('instructions-3.common')}}</p>
                                    <div>
                                        <img src="/images/questions-block.svg" alt="">
                                        <p>100 {{$t('instructions-3.common-q')}}</p>
                                    </div>
                                    <div>
                                        <img src="/images/time-block.svg" alt="">
                                        <p>150 минут</p>
                                    </div>
                                </div>
                                <p class="description">
                                    <span class="blue">{{$t('instructions-3.math-section')}}</span>
                                    <span>{{$t('instructions-3.math-desc')}}</span>
                                </p>
                                <div class="examples">
                                    <h2 class="title">{{$t('instructions-3.examples')}}</h2>
                                    <p class="task">{{$t('instructions-3.1-task')}}</p>
                                    <img src="/images/2task1.svg" alt="">
                                    <p class="task">{{$t('instructions-3.1-task-q')}}</p>
                                    <p class="answers" v-html="$t('instructions-3.1-answers')"></p>
                                    <p class="right-answer">{{$t('instructions-3.right-answer')}} C</p>
                                </div>
                                <p class="description">
                                    <span class="blue">{{$t('instructions-3.lang-section')}}</span>
                                    <span>{{$t('instructions-3.lang-desc')}}</span>
                                </p>
                                <div class="examples">
                                    <h2 class="title">{{$t('instructions-3.examples')}}</h2>
                                    <p class="test-type" v-html="$t('instructions-3.listening')"></p>
                                    <p class="task" v-html="$t('instructions-3.2-task')"></p>
                                    <img src="/images/2task2.svg" alt="">
                                    <p class="task" v-html="$t('instructions-3.2-task-q')"></p>
                                    <p class="answers" v-html="$t('instructions-3.2-answers')"></p>
                                    <p class="test-type" v-html="$t('instructions-3.reading')"></p>
                                    <p class="test-type center" v-html="$t('instructions-3.reading-title')"></p>
                                    <p class="task longtext">{{$t('instructions-3.3-task')}}</p>
                                    <p class="task" v-html="$t('instructions-3.3-task-q')"></p>
                                    <p class="answers" v-html="$t('instructions-3.3-answers')"></p>
                                    <p class="right-answer">{{$t('instructions-3.right-answer')}} C</p>
                                    <p class="test-type" v-html="$t('instructions-3.vocabulary')"></p>
                                    <p class="task longtext" v-html="$t('instructions-3.4-task')"></p>
                                    <p class="test-type" v-html="$t('instructions-3.4-task-q')"></p>
                                    <p class="answers" v-html="$t('instructions-3.4-answers')"></p>
                                    <p class="right-answer">{{$t('instructions-3.right-answer')}} B</p>
                                </div>
                                <p class="description">
                                    <span class="blue">{{$t('instructions-3.science-section')}}</span>
                                    <span>{{$t('instructions-3.science-desc')}}</span>
                                </p>
                                <div class="examples">
                                    <h2 class="title">{{$t('instructions-3.examples')}}</h2>
                                    <p class="task">{{$t('instructions-3.5-task')}}</p>
                                    <img src="/images/2task3.svg" alt="">
                                    <p class="task" v-html="$t('instructions-3.5-task-q')"></p>
                                    <p class="answers" v-html="$t('instructions-3.5-answers')"></p>
                                    <p class="right-answer">{{$t('instructions-3.right-answer')}} A</p>
                                </div>
                                <p class="goodluck blue">{{$t('instructions-3.goodluck')}}</p>
                            </div>
                            <!-- MOBILE VIEW -->
                            <div class="ins-tabs_mobile">
                                <h2 class="mobile_title">{{$t('breadcrumb.instructions')}}</h2>
                                <div class="ins-tabs_mobile_content">
                                    <!-- 1 -->
                                    <div class="ins-tabs_mobile_content_section">
                                        <div class="tab_mobile" @click="setMTab(1)">
                                            <img src="/images/arrow-right.svg" class="arrow" :class="{animation: mtab === 1}" alt="">
                                            <p class="tab_name">{{$t('instructions-2.tab-0-step')}}</p>
                                        </div>
                                        <div class="ins-content_mobile" v-if="mtab === 1">
                                            <h2 class="title center">{{$t('instructions-0.title')}}</h2>
                                            <p class="undertitle">{{$t('instructions-0.undertitle')}}</p>
                                            <p class="attention" v-if="$i18n.locale === 'ru'">
                                                <span class="blue">{{$t('instructions-0.attention')}}</span>
                                                <span>{{$t('instructions-0.attention-text')}}</span>
                                            </p>
                                            <p class="attention" v-if="$i18n.locale === 'kz'">
                                                <span>{{$t('instructions-0.attention-text')}}</span>
                                                <span class="blue">{{$t('instructions-0.attention')}}</span>
                                            </p>
                                            <p class="attention">{{$t('instructions-0.one')}}</p>
                                            <p class="attention">{{$t('instructions-0.two')}}</p>
                                            <p class="attention">{{$t('instructions-0.three')}}</p>
                                        </div>
                                    </div>
                                    <!-- 2 -->
                                    <div class="ins-tabs_mobile_content_section">
                                        <div class="tab_mobile" @click="setMTab(2)">
                                            <img src="/images/arrow-right.svg" class="arrow" :class="{animation: mtab === 2}" alt="">
                                            <p class="tab_name">{{$t('instructions-2.tab-1-step')}}</p>
                                        </div>
                                        <div class="ins-content_mobile" v-if="mtab === 2">
                                            <h2 class="title center">{{$t('instructions-1.title')}}</h2>
                                            <p class="attention blue">{{$t('instructions-1.subtitle1')}}</p>
                                            <p class="attention">{{$t('instructions-1.one-one')}}</p>
                                            <p class="attention">{{$t('instructions-1.one-two')}}</p>
                                            <p class="attention blue">{{$t('instructions-1.subtitle2')}}</p>
                                            <p class="attention">{{$t('instructions-1.two-one')}}</p>
                                            <p class="attention">{{$t('instructions-1.two-two')}}</p>
                                        </div>
                                    </div>
                                    <!-- 3 -->
                                    <div class="ins-tabs_mobile_content_section">
                                        <div class="tab_mobile" @click="setMTab(3)">
                                            <img src="/images/arrow-right.svg" class="arrow" :class="{animation: mtab === 3}" alt="">
                                            <p class="tab_name">{{$t('instructions-2.tab-2-step')}}</p>
                                        </div>
                                        <div class="ins-content_mobile" v-if="mtab === 3">
                                            <h2 class="title">{{$t('instructions-2.title')}}</h2>
                                            <p class="undertitle">{{$t('instructions-2.undertitle')}}</p>
                                            <p class="consist blue">{{$t('instructions-2.consist')}}</p>
                                            <div class="test-types">
                                                <div class="type_section">
                                                    <img src="/images/logic-logo.svg" alt="">
                                                    <p class="type">{{$t('instructions-2.logic')}}</p>
                                                    <p>{{$t('instructions-2.logic-q')}}</p>
                                                </div>
                                                <div class="type_section">
                                                    <img src="/images/space-logo.svg" alt="">
                                                    <p class="type">{{$t('instructions-2.space')}}</p>
                                                    <p>{{$t('instructions-2.space-q')}}</p>
                                                </div>
                                            </div>
                                            <div class="common-section">
                                                <p class="common blue">{{$t('instructions-2.common')}}</p>
                                                <div>
                                                    <img src="/images/questions-block.svg" alt="">
                                                    <p>40 {{$t('instructions-2.common-q')}}</p>
                                                </div>
                                                <div>
                                                    <img src="/images/time-block.svg" alt="">
                                                    <p>60 минут</p>
                                                </div>
                                            </div>
                                            <p class="description">
                                                <span class="blue">{{$t('instructions-2.logic-section')}}</span>
                                                <span>{{$t('instructions-2.logic-desc')}}</span>
                                            </p>
                                            <div class="examples">
                                                <h2 class="title">{{$t('instructions-2.examples')}}</h2>
                                                <p class="task">{{$t('instructions-2.1-task')}}</p>
                                                <p class="answers" v-html="$t('instructions-2.1-answers')"></p>
                                                <p class="right-answer">{{$t('instructions-2.right-answer')}} A</p>
                                                <p class="task">{{$t('instructions-2.2-task')}}</p>
                                                <img src="/images/task1.svg" alt="">
                                                <p class="right-answer">{{$t('instructions-2.right-answer')}} D</p>
                                            </div>
                                            <p class="description">
                                                <span class="blue">{{$t('instructions-2.space-section')}}</span>
                                                <span>{{$t('instructions-2.space-desc')}}</span>
                                            </p>
                                            <div class="examples">
                                                <h2 class="title">{{$t('instructions-2.examples')}}</h2>
                                                <p class="task" v-html="$t('instructions-2.3-task')"></p>
                                                <img src="/images/task2.svg" alt="">
                                                <p class="right-answer">{{$t('instructions-2.right-answer')}} C</p>
                                                <p class="task">{{$t('instructions-2.4-task')}}</p>
                                                <img src="/images/task3-1.svg" alt="">
                                                <p class="task" v-html="$t('instructions-2.4-task-q')"></p>
                                                <img src="/images/task3-2.svg" alt="">
                                                <p class="right-answer">{{$t('instructions-2.right-answer')}} A</p>
                                                <p class="task">{{$t('instructions-2.5-task')}}</p>
                                                <img src="/images/task4.svg" alt="">
                                                <p class="right-answer">{{$t('instructions-2.right-answer')}} C</p>
                                            </div>
                                            <p class="goodluck blue">{{$t('instructions-2.goodluck')}}</p>
                                        </div>
                                    </div>
                                    <!-- 4 -->
                                    <div class="ins-tabs_mobile_content_section">
                                        <div class="tab_mobile" @click="setMTab(4)">
                                            <img src="/images/arrow-right.svg" class="arrow" :class="{animation: mtab === 4}" alt="">
                                            <p class="tab_name">{{$t('instructions-2.tab-3-step')}}</p>
                                        </div>
                                        <div class="ins-content_mobile" v-if="mtab === 4">
                                            <h2 class="title">{{$t('instructions-3.title')}}</h2>
                                            <p class="undertitle">{{$t('instructions-3.undertitle')}}</p>
                                            <p class="consist blue">{{$t('instructions-3.consist')}}</p>
                                            <div class="test-types">
                                                <div class="type_section">
                                                    <img src="/images/math-logo.svg" alt="">
                                                    <p class="type">{{$t('instructions-3.math')}}</p>
                                                    <p>{{$t('instructions-3.math-q')}}</p>
                                                </div>
                                                <div class="type_section">
                                                    <img src="/images/lang-logo.svg" alt="">
                                                    <p class="type">{{$t('instructions-3.mother-lang')}}</p>
                                                    <p>{{$t('instructions-3.mother-lang-q')}}</p>
                                                </div>
                                                <div class="type_section">
                                                    <img src="/images/english-logo.svg" alt="">
                                                    <p class="type">{{$t('instructions-3.english')}}</p>
                                                    <p>{{$t('instructions-3.english-q')}}</p>
                                                </div>
                                                <div class="type_section">
                                                    <img src="/images/science-logo.svg" alt="">
                                                    <p class="type">{{$t('instructions-3.science')}}</p>
                                                    <p>{{$t('instructions-3.science-q')}}</p>
                                                </div>
                                            </div>
                                            <div class="common-section">
                                                <p class="common blue">{{$t('instructions-3.common')}}</p>
                                                <div>
                                                    <img src="/images/questions-block.svg" alt="">
                                                    <p>100 {{$t('instructions-3.common-q')}}</p>
                                                </div>
                                                <div>
                                                    <img src="/images/time-block.svg" alt="">
                                                    <p>60 минут</p>
                                                </div>
                                            </div>
                                            <p class="description">
                                                <span class="blue">{{$t('instructions-3.math-section')}}</span>
                                                <span>{{$t('instructions-3.math-desc')}}</span>
                                            </p>
                                            <div class="examples">
                                                <h2 class="title">{{$t('instructions-3.examples')}}</h2>
                                                <p class="task">{{$t('instructions-3.1-task')}}</p>
                                                <img src="/images/2task1.svg" alt="">
                                                <p class="task">{{$t('instructions-3.1-task-q')}}</p>
                                                <p class="answers" v-html="$t('instructions-3.1-answers')"></p>
                                                <p class="right-answer">{{$t('instructions-3.right-answer')}} C</p>
                                            </div>
                                            <p class="description">
                                                <span class="blue">{{$t('instructions-3.lang-section')}}</span>
                                                <span>{{$t('instructions-3.lang-desc')}}</span>
                                            </p>
                                            <div class="examples">
                                                <h2 class="title">{{$t('instructions-3.examples')}}</h2>
                                                <p class="test-type" v-html="$t('instructions-3.listening')"></p>
                                                <p class="task" v-html="$t('instructions-3.2-task')"></p>
                                                <img src="/images/2task2.svg" alt="">
                                                <p class="task" v-html="$t('instructions-3.2-task-q')"></p>
                                                <p class="answers" v-html="$t('instructions-3.2-answers')"></p>
                                                <p class="test-type" v-html="$t('instructions-3.reading')"></p>
                                                <p class="test-type center" v-html="$t('instructions-3.reading-title')"></p>
                                                <p class="task longtext">{{$t('instructions-3.3-task')}}</p>
                                                <p class="task" v-html="$t('instructions-3.3-task-q')"></p>
                                                <p class="answers" v-html="$t('instructions-3.3-answers')"></p>
                                                <p class="right-answer">{{$t('instructions-3.right-answer')}} C</p>
                                                <p class="test-type" v-html="$t('instructions-3.vocabulary')"></p>
                                                <p class="task longtext" v-html="$t('instructions-3.4-task')"></p>
                                                <p class="test-type" v-html="$t('instructions-3.4-task-q')"></p>
                                                <p class="answers" v-html="$t('instructions-3.4-answers')"></p>
                                                <p class="right-answer">{{$t('instructions-3.right-answer')}} B</p>
                                            </div>
                                            <p class="description">
                                                <span class="blue">{{$t('instructions-3.science-section')}}</span>
                                                <span>{{$t('instructions-3.science-desc')}}</span>
                                            </p>
                                            <div class="examples">
                                                <h2 class="title">{{$t('instructions-3.examples')}}</h2>
                                                <p class="task">{{$t('instructions-3.5-task')}}</p>
                                                <img src="/images/2task3.svg" alt="">
                                                <p class="task" v-html="$t('instructions-3.5-task-q')"></p>
                                                <p class="answers" v-html="$t('instructions-3.5-answers')"></p>
                                                <p class="right-answer">{{$t('instructions-3.right-answer')}} A</p>
                                            </div>
                                            <p class="goodluck blue">{{$t('instructions-3.goodluck')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-up" v-if="mtab !== 0" @click="scroll">
                                <img src="/images/arrow-up.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default ({
    data(){
        return {
            tab: 0,
            mtab: 0,
        }
    },
    methods: {
        setTab(value) {
            this.tab = value
        },
        setMTab(value) {
            this.mtab = value === this.mtab ? 0 : value
        },
        scroll() {
            scrollTo(0, 0)
        }
    }
})
</script>
<style>
html {
    scroll-behavior: smooth;
}
.ins-wrapper {
    display: flex;
    position: relative;
    font-size: 18px;
    margin-bottom: 320px;
}
.blue {
    color: #2F80ED;
    font-weight: 600;
}
.ins-tabs {
    display: flex;
    flex-flow: column;
    font-size: 18px;
    margin-right: 60px;
    min-width: 245px;
}
.ins-tabs_item {
    padding: 10px 0;
    cursor: pointer;
    margin-bottom: 30px;
}
.ins-tabs_item.active {
    border-bottom: 3px solid #2B6ADB;
}
.ins-content {
    font-family: "Roboto";
    line-height: 21px;
}
.ins-content .title {
    color: #05458C;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 35px;
}
.ins-content .title.center {
    text-align: center;
}
.ins-content .undertitle {
    margin-bottom: 20px;
}
.ins-content .test-types {
    display: flex;
    flex-wrap: wrap;
    padding: 0 75px;
    justify-content: space-between;
    margin: 32px 0 0;
}
.ins-content .test-types .type_section {
    padding: 32px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    width: 48%;
    margin-bottom: 32px;
}
.ins-content .test-types .type_section .type {
    color: #05458C;
    margin: 25px 0;
    font-weight: 600;
}
.ins-content .common-section {
    display: flex;
    align-items: center;
}
.ins-content .common-section .common {
    margin: 0;
    max-width: 240px;
}
.ins-content .common-section div {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 0 25px;
}
.ins-content .common-section div p {
    margin: 0 0 0 15px;
}
.ins-content .description {
    margin: 60px 0 50px;
}
.ins-content .attention {
    margin: 34px 0;
}
.ins-content .examples {
    display: flex;
    flex-flow: column;
    align-items: center;
}
.ins-content .examples p {
    width: 100%;
}
.ins-content .examples .title {
    text-align: center;
}
.ins-content .examples .test-type {
    margin-bottom: 30px;
}
.ins-content .examples .test-type.center {
    text-align: center;
}
.ins-content .examples .longtext {
    margin-bottom: 34px;
}
.ins-content .examples .answers, .ins-content .examples img {
    margin: 34px 0;
}
.ins-content .examples .right-answer {
    font-style: italic;
    margin-bottom: 48px;
}
.ins-content .goodluck {
    font-size: 25px;
    text-align: center;
    margin-top: 12px;
}
.ins-tabs_mobile {
    display: none;
}
.button-up {
    display: none;
}
@media (max-width: 916px) {
    .ins-wrapper {
        flex-flow: column;
        margin-bottom: 80px;
    }
    .ins-tabs, .ins-content {
        display: none;
    }
    .ins-tabs_mobile {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .mobile_title {
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        color: #1A5EBF;
        text-align: center;
        margin-bottom: 34px;
    }
    .ins-tabs_mobile_content_section {
        padding: 25px 23px;
        background: #FFFFFF;
        box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
        border-radius: 10px;
        margin-bottom: 5px;
    }
    .ins-tabs_mobile_content_section .tab_mobile {
        display: flex;
        align-items: center;
    }
    .ins-tabs_mobile_content_section .tab_name {
        color: #05458C;
        margin: 0 0 0 16px;
    }
    .arrow {
        transition: 0.30s;
        -webkit-transition: 0.30s;
        -moz-transition: 0.30s;
        -ms-transition: 0.30s;
        -o-transition: 0.30s;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    .animation {
        transition: 0.30s;
        -webkit-transition: 0.30s;
        -moz-transition: 0.30s;
        -ms-transition: 0.30s;
        -o-transition: 0.30s;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    @-webkit-keyframes slide-down {
        0% { opacity: 0; -webkit-transform: translateY(-10%); }
        100% { opacity: 1; -webkit-transform: translateY(0); }
    }
    @-moz-keyframes slide-down {
        0% { opacity: 0; -moz-transform: translateY(-10%); }
        100% { opacity: 1; -moz-transform: translateY(0); }
    }
.ins-content_mobile {
    font-family: "Roboto";
    line-height: 21px;
    font-size: 16px;
    -webkit-animation: slide-down .3s ease-out;
    -moz-animation: slide-down .3s ease-out;
}
.ins-content_mobile .title {
    color: #05458C;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 35px;
    display: none;
}
.ins-content_mobile .title.center {
    text-align: center;
}
.ins-content_mobile .undertitle {
    margin: 25px 20px;
}
.ins-content_mobile .test-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 32px 0 0;
}
.ins-content_mobile .test-types .type_section {
    padding: 32px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    width: 100%;
    margin-bottom: 32px;
}
.ins-content_mobile .test-types .type_section .type {
    color: #05458C;
    margin: 25px 0;
    font-weight: 600;
}
.ins-content_mobile .common-section {
    display: flex;
    align-items: center;
    flex-flow: column;
}
.ins-content_mobile .common-section .common {
    margin: 0;
    max-width: 240px;
}
.ins-content_mobile .common-section div {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 12px 0;
    width: 60%;
}
.ins-content_mobile .common-section div p {
    margin: 0 0 0 15px;
}
.ins-content_mobile .description {
    margin: 60px 0 50px;
}
.ins-content_mobile .attention {
    margin: 25px 0;
}
.ins-content_mobile .examples {
    display: flex;
    flex-flow: column;
    align-items: center;
}
.ins-content_mobile .examples p {
    width: 100%;
}
.ins-content_mobile .examples .title {
    text-align: center;
}
.ins-content_mobile .examples .test-type {
    margin-bottom: 30px;
}
.ins-content_mobile .examples .test-type.center {
    text-align: center;
}
.ins-content_mobile .examples .longtext {
    margin-bottom: 34px;
}
.ins-content_mobile .examples .answers, .ins-content_mobile .examples img {
    margin: 34px 0;
}
.ins-content_mobile .examples img {
    max-width: 100%;
}
.ins-content_mobile .examples .right-answer {
    font-style: italic;
    margin-bottom: 48px;
}
.ins-content_mobile .goodluck {
    font-size: 25px;
    text-align: center;
    margin-top: 12px;
}
.button-up {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
}
}
</style>
