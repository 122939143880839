<template>
  <footer class="footer pb-0">
    <div class="container mx-auto text-white pb-4">
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <h5>{{ $t('footer.title') }}</h5>
          <ul class="footer_menu">
            <li>
                <router-link tag="a" to="/terms-of-use">{{ $t('footer.terms-of-use') }}</router-link>
            </li>
            <li>
              <router-link tag="a" to="/moderation-policy">{{ $t('footer.policy-of-moderation') }}</router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">{{ $t('footer.privacy-policy') }}</router-link>
            </li>
<!--            <li>-->
<!--              <a target="_blank" v-if="$i18n.locale=='ru'" href="files/documents/Соглашение_о_Конфеденциальности_RU_1000.pdf" tag="a" to="/moderation-policy">{{ $t('footer.policy-of-moderation') }}</a>-->
<!--              <a target="_blank" v-if="$i18n.locale=='kz'" href="files/documents/KZ_Соглашение_о_Конфеденциальности_1000.pdf" tag="a" to="/moderation-policy">{{ $t('footer.policy-of-moderation') }}</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a target="_blank" v-if="$i18n.locale=='ru'" href="files/documents/Политика_Конфеденциальности_RU_1000.pdf" tag="a" to="/moderation-policy"> {{ $t('footer.privacy-policy') }}</a>-->
<!--              <a target="_blank" v-if="$i18n.locale=='kz'" href="files/documents/KZ_Политика_Конфеденциальности_1000.pdf" tag="a" to="/moderation-policy"> {{ $t('footer.privacy-policy') }}</a>-->
<!--            </li>-->
          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <h5>{{ $t('footer.projects-title') }}</h5>
          <ul class="footer_menu">
            <li><a href="https://ro.elumiti.kz/" target="_blank">{{ $t('footer.project-talent') }}</a></li>
            <li><a href="https://qabilet.elbasyacademy.kz/" target="_blank">{{ $t('footer.project-qabilet') }}</a></li>
            <li><a href="https://birgeoqy.kz/" target="_blank">{{ $t('footer.project-birge-oqy') }}</a></li>
            <li><a href="https://smartustaz.kz/" target="_blank">{{ $t('footer.project-smartustaz') }}</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 social">
          <h5>{{ $t('footer.we-are-in-social-networks') }}</h5>
          <ul class="social_icons">
            <li class="social_in"><a href="https://www.instagram.com/el_umiti.kz/" target="_blank">Instagram</a></li>
            <li class="social_fb"><a href="https://www.facebook.com/elumiti" target="_blank">Facebook</a></li>
            <li class="social_yt"><a href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA" target="_blank">Youtube</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-12 our-app">
          <h5>{{ $t('footer.install-app') }}</h5>
          <div class="mobile-apps-wrap">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <p class="mb-0">© {{ date }} {{ $t('footer.elbasy-academy') }}. {{ $t('footer.copyright') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>

<style>
.our-app {
  display: none;
}
</style>
