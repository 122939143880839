<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.title')"></ProfileNavbar>
    <div class="clearfix">
      <div class="row">
        <div class="col-md-12">
          <div class="profile-edit-form w-100">
            <button v-on:click="showButtons(false, false, false, true, false)" v-show="visibleBtnBack" class="back-btn">
                <img src="/images/arrow-back.png" alt="">
            </button>
            <h3 class="title text-center text-lg-left">{{ $t('profile.title') }}</h3>
            <p class="hint text-center text-lg-left">{{ $t('profile.description') }}</p>
            <form @submit.prevent="updateProfile()">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-label w-100 text-center text-lg-left">{{ $t('profile.avatar') }}</label>
                    <img v-if="avatarUrl" id="photo" :src="avatarUrl" class="avatar">
                    <el-upload
                        class="avatar-uploader"
                        :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                        :action="apiRoot + '/api/avatar/update'"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <button type="button" class="btn btn-light">
                          <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70711 6.20711C3.31658 6.59763 2.68342 6.59763 2.29289 6.20711C1.90237 5.81658 1.90237 5.18342 2.29289 4.79289L6.2929 0.79289C6.6834 0.40237 7.3166 0.40237 7.7071 0.79289L11.7071 4.79289C12.0976 5.18342 12.0976 5.81658 11.7071 6.20711C11.3166 6.59763 10.6834 6.59763 10.2929 6.20711L8 3.91421V8.5H6V3.91421L3.70711 6.20711ZM6 8.5H3C1.34315 8.5 0 9.8431 0 11.5V17.5C0 19.1569 1.34315 20.5 3 20.5H11C12.6569 20.5 14 19.1569 14 17.5V11.5C14 9.8431 12.6569 8.5 11 8.5H8V13.5C8 14.0523 7.5523 14.5 7 14.5C6.4477 14.5 6 14.0523 6 13.5V8.5Z" fill="#2A8BF2"/>
                          </svg>
                          <span>{{ $t('profile.download-photo') }}</span>
                        </button>
                    </el-upload>
                    <button type="button" @click="takePhoto" class="btn-take-photo" style="margin: 10px 0;">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 3.5L7.17 5.5H4C2.9 5.5 2 6.4 2 7.5V19.5C2 20.6 2.9 21.5 4 21.5H20C21.1 21.5 22 20.6 22 19.5V7.5C22 6.4 21.1 5.5 20 5.5H16.83L15 3.5H9ZM12 18.5C9.24 18.5 7 16.26 7 13.5C7 10.74 9.24 8.5 12 8.5C14.76 8.5 17 10.74 17 13.5C17 16.26 14.76 18.5 12 18.5Z" fill="#05458C"/>
                        <path d="M12 17.5L13.25 14.75L16 13.5L13.25 12.25L12 9.5L10.75 12.25L8 13.5L10.75 14.75L12 17.5Z" fill="#05458C"/>
                      </svg>
                      <span>{{ $t('signup.photo-take-picture-btn') }}</span>
                    </button>
                    <div class="flex-column webcam-photo-input" :class="webcamActive ? 'd-flex' : null" style="width: 300px; display: none;">
                        <el-button @click="doPhoto" icon="el-icon-camera" size="medium" type="primary" plain style="font-size: 30px;">{{ $t('signup.photo-take-picture-btn') }}</el-button>
                        <video id="video" width="300" height="300" autoplay></video>
                        <span class="webcam-photo-input__circle"></span>
                        <canvas id="cvs" style="display: none" width="300" height="300"></canvas>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 main-info" v-show="visibleMain">
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.iin-label') }}</label>
                    <el-input disabled :placeholder="$t('profile.iin-placeholder')" v-model="account.iin"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.last-name-label') }}</label>
                    <el-input disabled :placeholder="$t('profile.last-name-placeholder')" v-model="account.last_name"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.first-name-label') }}</label>
                    <el-input disabled :placeholder="$t('profile.first-name-placeholder')" v-model="account.first_name"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.patronymic-label') }}</label>
                    <el-input disabled :placeholder="$t('profile.patronymic-placeholder')" v-model="account.patronymic"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.birth-date-label') }}</label>
                    <el-date-picker
                        style="width: 100%;"
                        v-model="account.birth_date"
                        type="date"
                        placeholder="Дата"
                        format="yyyy-MM-dd"
                        default-value="2007-01-01"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.gender') }}</label>
                    <el-select
                      disabled
                      :placeholder="$t('references.gender')"
                      v-model="account.sex">
                      <el-option
                          :key="1"
                          :value="1"
                          :label="$t('references.gender-male')">
                      </el-option>
                      <el-option
                          :key="2"
                          :value="2"
                          :label="$t('references.gender-female')">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.email-label') }}</label>
                    <el-input type="email" :placeholder="$t('references.email-placeholder')" v-model="account.email"></el-input>
                  </div>
<!--                  <div class="form-group">-->
<!--                    <label class="form-label">{{ $t('references.region-label') }}</label>-->
<!--                    <el-select-->
<!--                        :placeholder="$t('references.region-placeholder')"-->
<!--                        v-model="account.region_id"-->
<!--                        :loading="regionsLoading"-->
<!--                        @change="getCities"-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in regions"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-label">{{ $t('references.district-label') }}</label>-->
<!--                    <el-select-->
<!--                        :placeholder="$t('references.district-placeholder')"-->
<!--                        v-model="account.city_id"-->
<!--                        :loading="citiesLoading"-->
<!--                        @change="getLocalities"-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in cities"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-label">{{ $t('references.locality-label') }}</label>-->
<!--                    <el-select-->
<!--                        :placeholder="$t('references.locality-placeholder')"-->
<!--                        v-model="account.locality_id"-->
<!--                        :loading="localitiesLoading"-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in localities"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </div>-->
                </div>
                <div v-show="visibleEducation" class="col-lg-4 col-md-12 education-info">
                  <h4 class="mb-3">{{ $t('profile.education') }}</h4>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.region-label') }}</label>
                    <el-input v-model="account.education.region_name" disabled :placeholder="$t('references.region-placeholder')"></el-input>
<!--                    <el-select-->
<!--                        placeholder="Выберите область"-->
<!--                        v-model="account.education.region_id"-->
<!--                        :loading="regionsLoading"-->
<!--                        disabled-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in regions"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.district-label') }}</label>
                    <el-input v-model="account.education.city_name" disabled :placeholder="$t('references.district-placeholder')"></el-input>
<!--                    <el-select-->
<!--                        placeholder="Выберите Район"-->
<!--                        v-model="account.education.city_id"-->
<!--                        :loading="citiesLoading"-->
<!--                        disabled-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in cities"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.locality-label') }}</label>
                    <el-input v-model="account.education.location_name" disabled :placeholder="$t('references.locality-placeholder')"></el-input>
<!--                    <el-select-->
<!--                        placeholder="Выберите населенный пункт"-->
<!--                        v-model="account.education.locality_id"-->
<!--                        :loading="localitiesLoading"-->
<!--                        disabled-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in localities"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
                  </div>
<!--                  <div class="form-group">-->
<!--                    <label class="form-label">{{ $t('references.school-type-label') }}</label>-->
<!--                    <el-select-->
<!--                        :placeholder="$t('references.school-type-placeholder')"-->
<!--                        v-model="account.education.school_type_id"-->
<!--                        disabled-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in schoolTypes"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </div>-->
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.school-name-label') }}</label>
                    <el-input v-model="account.school" disabled :placeholder="$t('references.school-name-placeholder')"></el-input>
<!--                    <el-select-->
<!--                        placeholder="Учебное заведение"-->
<!--                        v-model="account.education.school_id"-->
<!--                        disabled-->
<!--                        :loading="schoolsLoading"-->
<!--                        filterable>-->
<!--                      <el-option-->
<!--                          v-for="item in schools"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.class-label') }}</label>
                    <el-select disabled v-model="account.class" :placeholder="$t('references.class-placeholder')">
                      <el-option
                          v-for="item in classes"
                          :key="item.id"
                          disabled
                          :label= "item.id + $t('profile.class')"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('references.education-language-label') }}</label>
                    <el-select disabled v-model="account.education.instruction_language" :placeholder="$t('references.education-language-placeholder')">
                      <el-option
                          v-for="item in [{
                          label: $t('references.language-ru'),
                          value: 'ru'
                        }, {
                          label: $t('references.language-kz'),
                          value: 'kz'
                        }, {
                          label: $t('references.language-en'),
                          value: 'en'
                        }]"
                          :key="item.label"
                          :label="item.label"
                          disabled
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.test-result') }}</label>
                    <el-input disabled v-model="account.second_stage"></el-input>
                  </div>
                </div>
                <div v-show="visibleParent" class="col-lg-4 col-md-12 parent-info">
                  <h4 class="mb-3">{{ $t('profile.representative') }}</h4>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.representative-last-name-label') }}</label>
                    <el-input :placeholder="$t('profile.representative-last-name-placeholder')" v-model="account.parent.last_name"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.representative-first-name-label') }}</label>
                    <el-input :placeholder="$t('profile.representative-first-name-placeholder')" v-model="account.parent.first_name"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.representative-patronymic-label') }}</label>
                    <el-input :placeholder="$t('profile.representative-patronymic-placeholder')" v-model="account.parent.patronymic"></el-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ $t('profile.representative-tel-label') }}</label>
                    <el-input
                        @input="enforcePhoneFormat"
                        :placeholder="$t('profile.representative-tel-placeholder')"
                        v-model="phone"></el-input>
                  </div>
<!--                  <div class="form-group">-->
<!--                    <label class="form-label">{{ $t('profile.representative-additional-tel-label') }}</label>-->
<!--                    <el-input-->
<!--                        @input="enforceAdditionalPhoneFormat"-->
<!--                        :placeholder="$t('profile.representative-additional-tel-placeholder')"-->
<!--                        v-model="additionalPhone"></el-input>-->
<!--                  </div>-->
<!--                  <div class="form-group">-->
<!--                    <label class="form-label">{{ $t('profile.representative-email-label') }}</label>-->
<!--                    <el-input -->
<!--                        :placeholder="$t('profile.representative-email-placeholder')" -->
<!--                        v-model="account.parent.email"></el-input>-->
<!--                  </div>-->
                  <div class="py-3">
                    <h4 class="mb-3">{{ $t('profile.testing') }}</h4>
                    <div class="form-group">
                      <label class="form-label">{{ $t('references.test-language-label') }}</label>
                      <el-select v-model="account.education.test_language" :placeholder="$t('references.test-language-placeholder')">
                        <el-option
                            v-for="item in [{
                              label: $t('references.language-ru'),
                              value: 'ru'
                            }, {
                              label: $t('references.language-kz'),
                              value: 'kz'
                            }]"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
<!--                    <div class="form-group">-->
<!--                      <el-radio :label="1" v-model="account.internet_connection">{{ $t('profile.online') }}</el-radio>-->
<!--                      <el-radio :label="0" v-model="account.internet_connection">{{ $t('profile.special-center') }}</el-radio>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
              <div class="row my-4 ">
                <button type="submit" class="btn btn-primary mx-auto col-lg-2 col-10">{{ $t('profile.save-btn') }}</button>
                <!--s-->
              </div>
            </form>
                <div v-show="visibleBtns" class="more-info-btns p-0">
                    <a v-on:click="showInputs(true, false, false, false, true)" class="btn more-info-btn mt-5" href="#" role="button" >{{ $t('profile.main') }}</a>
                    <a v-on:click="showInputs(false, true, false, false, true)" class="btn more-info-btn mt-5" href="#" role="button">{{ $t('profile.education') }}</a>
                    <a v-on:click="showInputs(false, false, true, false, true)" class="btn more-info-btn mt-5" href="#" role="button">{{ $t('profile.representative') }}</a>
                </div>
          </div>
        </div>



<!--        <div class="col-md-4">-->
<!--          <div class="row pr-3">-->
<!--            <div class="password-edit-form">-->
<!--              <h3 class="title">Настройки пароля</h3>-->
<!--              <p class="hint">Если вы желаете изменить пароль - заполните форму ниже</p>-->
<!--              <form action="#" method="POST">-->
<!--                <div class="form-group">-->
<!--                  <label class="form-label">Новый пароль</label>-->
<!--                  <el-input placeholder="Введите пароль" v-model="password"></el-input>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <label class="form-label">Повторите пароль</label>-->
<!--                  <el-input placeholder="Повторите пароль" v-model="passwordConfirm"></el-input>-->
<!--                </div>-->
<!--                <div class="row my-4">-->
<!--                  <el-button type="primary" class="mx-auto">Сохранить</el-button>-->
<!--                </div>-->
<!--              </form>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div>
          <button @click="$router.go('/profile')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-danger-img mb-3" src="/images/profile/modal-danger.svg" alt="">
          <h3>{{ errorModalTitle }}</h3>
          <span>{{ errorModalDesc }}</span>
          <div class="text-center">
            <button @click="$router.go('/profile')" class="btn btn-primary mx-2 my-2">{{ $t('signup.photo-modal-retry') }}</button>
            <button @click="$router.go('/profile')" class="btn btn-outline-primary mx-2 my-2">{{ $t('signup.photo-modal-cancel') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="announcement-modal" hide-footer hide-header no-close-on-backdrop size="lg">
        <div id="dialog">
          <div class="row announcement-modal">
            <div class="col-12">
              <img src="/images/announcement-window.svg" alt="">
              <h4>
                {{$t('profile.informed-modal.title')}}
              </h4>
              <h6>
                {{$t('profile.informed-modal.for')}}
              </h6>
              <p class="fz-18 fw-medium c-dark-blue" v-html="$t('profile.informed-modal.start')"></p>
              <div class="card-main">
                <p class="fz-18 fw-medium c-blue">{{$t('profile.informed-modal.important-info-title')}}</p>
                <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-1')" v-html="item" :key="item"></p>
              </div>
              <h5> {{$t('profile.informed-modal.technical-recomendation')}}</h5>
              <div class="card-main row">
                <div class="col-12 col-sm-4">
                  <img src="/images/informed-desktop.svg" alt="">
                </div>
                <div class="col-12 col-sm-8">
                  <!-- <p class="fz-18" >
                    Если вы проходите тестирование с <span class="c-blue">персонального компьютера, ноутбука:</span>
                  </p> -->
                  <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-2')" v-html="item" :key="item"></p>
                </div>
              </div>
              <div class="card-main row">
                <div class="col-12 col-sm-4">
                  <img src="/images/informed-mobile.svg" alt="">
                </div>
                <div class="col-12 col-sm-8">
                  <!-- <p class="fz-18">
                    Если вы проходите тестирование с <span class="c-blue">персонального компьютера, ноутбука:</span>
                  </p> -->
                  <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-3')" v-html="item" :key="item"></p>
                </div>
              </div>
              <h5>{{$t('profile.informed-modal.luck')}}</h5>
              <el-checkbox v-model="informedCheckbox">{{$t('profile.informed-modal.confirmed')}}</el-checkbox>
              <div class="col-12 mt-3">
                <el-button type="primary" :disabled="!informedCheckbox" @click="confirmInformedModal">{{$t('profile.informed-modal.next')}}</el-button>
              </div>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions} from "vuex";
import ProfileNavbar from "@/components/ProfileNavbar";
export default {
  name: 'Account',
  components: {
    ProfileNavbar,
  },
  data(){
    return {
        pickerOptions: {
            disabledDate(time) {
                let startDate = new Date();
                startDate.setMonth(11, 31);
                startDate.setFullYear( startDate.getFullYear() - 15 );

                let endDate = new Date();
                endDate.setMonth(11, 31);
                endDate.setFullYear( endDate.getFullYear() - 9 );

                return time.getTime() < startDate || time.getTime() >= endDate;
          }
      },
      loading: false,
      visibleMain: true,
      visibleEducation: true,
      visibleParent: true,
      userCanGo: false,
      acceptPhoto: false,
      takedScreenshot: false,
      takedImageSource: '',
      visibleBtns: false,
      visibleBtnBack: false,
      cameraInitialized: false,
      webcamActive: false,
      phone: '',
      additionalPhone: '',
      apiRoot: window.API_ROOT,
      avatarUrl: '',
      password: '',
      passwordConfirm: '',
      regions: [],
      cities: [],
      localities: [],
      schools: [],
      schoolTypes: [
        {
          id: 1,
          name: this.$t('signup.school-type-1'),
        },
        {
          id: 2,
          name: this.$t('signup.school-type-2'),
        },
        {
          id: 3,
          name: this.$t('signup.school-type-3'),
        },
        {
          id: 4,
          name: this.$t('signup.school-type-4'),
        }
      ],
      regionsLoading: false,
      schoolsLoading: false,
      citiesLoading: false,
      localitiesLoading: false,
      classes: [
        {
          id: 5,
        //   name: '5 класс'
        //   name: $t('references.language-ru')
        },
        {
          id: 6,
          name: '6 класс'
        }
      ],
      account: {
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        region_id: '',
        city_id: '',
        locality_id: '',
        internet_connection: '',
        parent: {
          last_name: '',
          first_name: '',
          patronymic: '',
            email: '',
        },
        education: {
          region_id: '',
          city_id: '',
          locality_id: '',
          school_id: '',
        }
      },
      informedCheckbox: false,
      errorModalTitle: '',
      errorModalDesc: ''
    }
  },
  methods: {
      // TODO доработать после того как будет АПИ
      updateProfile() {
          this.$http.post(window.API_ROOT + '/api/profile/update', {account: this.account})
              .then((res) => {
                localStorage.setItem('user', JSON.stringify(this.account));
                  Vue.toastr({
                      message: 'Успешно!',
                      description: res.data.success,
                      type: 'success'
                  });
              }).catch((e) => {
                  Vue.toastr({
                      message: 'Ошибка',
                      description: "Ошибка при обновлении пароля" ,
                      type: 'error'
                  });
              });
      },
      doPhoto() {
        const video = document.getElementById('video')
        const canvas = document.getElementById('cvs')
        const context = canvas.getContext('2d')
        const formData = new FormData()
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const src = canvas.toDataURL('image/png')
        formData.set('photo', src)
        this.avatarUrl = src;
        this.$http.post(`${window.API_ROOT}/api/avatar/update`, formData).then(res => {
          if (res.body.status === 'success') {
            if (window.stream) {
              window.stream.getTracks().forEach(function(track) {
                track.stop();
              });
            }
            this.webcamActive = false
          } else {
            this.errorModalTitle = this.$t('signup.error-modal');
            this.errorModalDesc = res.body.msg;
            this.$bvModal.show('error-modal');
            // this.$message.error(res.body.msg)
          }
        }).catch((e) => {
          console.log(e)
        })
      },
      takePhoto() {
        this.webcamActive = true
        const video = document.getElementById('video')
        const canvas = document.getElementById('cvs')
        const context = canvas.getContext('2d')
        const vendorUrl = window.URL || window.webkitURL
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: true })
          .then(function (stream) {
            video.srcObject = stream;
            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');
            video.play();
          })
          .catch(function (error) {
          });
        }
        // navigator.getMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.
        // mozGetUserMedia || navigator.msGetUserMedia;
        // navigator.getMedia({
        // video: true,
        // audio: false
        // }, function(stream) {
        //   video.srcObject = stream;
        //   video.setAttribute('autoplay', '');
        //   video.setAttribute('muted', '');
        //   video.setAttribute('playsinline', '');
        //   video.play();
        // }, function(error) {
        //   alert('Ошибка! Что-то пошло не так, попробуйте позже.');
        // });
      },
      handleAvatarSuccess(res, file) {
        if(res.status === 'success'){
          this.userCanGo = true;
          // this.takedScreenshot = true;
          if (document.getElementById('i')){
            document.getElementById('i').classList.add('manual-upload');
          }
          // document.getElementsByClassName('webcam-photo-taked')[0].classList.remove('hidden');

          if (document.getElementById('capture-video')) {
            document.getElementById('capture-video').classList.add('hidden');
          }
          if (window.stream) {
            window.stream.getTracks().forEach(function (track) {
              track.stop();
            });
          }

          this.avatarUrl = URL.createObjectURL(file.raw);
          this.cameraInitialized = false;

          let reader = new FileReader();
          reader.readAsDataURL(file.raw);
          reader.onload = (e) => {
            sessionStorage.setItem('avatar-base64', reader.result);
            this.takedScreenshot = true;
            this.takedImageSource = reader.result;
          };
          sessionStorage.setItem('avatar', res.avatar);
        }else{
          this.$message.error(res.msg)
        }
      },

      showInputs(visibleMain, visibleEducation, visibleParent, visibleBtns, visibleBtnBack){
            this.visibleMain=visibleMain;
            this.visibleEducation=visibleEducation;
            this.visibleParent=visibleParent;
            this.visibleBtns=visibleBtns;
            this.visibleBtnBack=visibleBtnBack;
      },
      showButtons(visibleMain, visibleEducation, visibleParent, visibleBtns, visibleBtnBack) {
            this.visibleMain=visibleMain;
            this.visibleEducation=visibleEducation;
            this.visibleParent=visibleParent;
            this.visibleBtns=visibleBtns;
            this.visibleBtnBack=visibleBtnBack;
      },
    getCities(id, reset = true){
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              if (reset) {
                this.account.city_id = '';
              }
            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalities(id, reset = true){
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              if (reset) {
                this.account.locality_id = '';
              }
            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    enforcePhoneFormat() {
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.phone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");
      this.account.parent.phone = x[0];
    },
    enforceAdditionalPhoneFormat() {
      let x = this.additionalPhone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.additionalPhone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");
      this.account.parent.additional_phone = x[0];
    },
    // handleAvatarSuccess(res, file) {
    //   this.avatarUrl = URL.createObjectURL(file.raw);
    // },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!(isJPG || isPNG)) {
        this.$message.error('Avatar picture must be JPG or PNG format!');
      }
      if (!isLt4M) {
        this.$message.error('Avatar picture size can not exceed 4MB!');
      }
      return (isJPG || isPNG) && isLt4M;
    },
    logoutMethod() {
      this.logout();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      sessionStorage.setItem('avatar-url', '')
      window.location = '/';
      // this.$router.push('/');
    },
    async confirmInformedModal() {
      try {
        await this.$http.post(`${window.API_ROOT}/api/user/informed`, {
          informed: 1
        })
        this.$bvModal.hide('announcement-modal')
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        })
      }
    },
    ...mapActions({
      'logout': 'logout'
    }),
  },
  mounted() {
    if(!localStorage.getItem('user')){
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res.body.data));
            this.$router.go()
          })
    }
    let res = JSON.parse(localStorage.getItem('user'))
    sessionStorage.setItem('avatar-url', `${window.API_ROOT}/api/user/avatar?file=${res.avatar}`);
        this.avatarUrl =`${window.API_ROOT}/api/user/avatar?file=${res.avatar}`;
        this.account = res;
        if(this.account.parent==null){
          this.account.parent={
            last_name: '',
            first_name: '',
            patronymic: '',
            email: '',
          }

        }
        console.log(this.account)
        this.account.result === null && (this.account.result = 0)
        this.regionsLoading = true;
        this.$http.get(window.API_ROOT + '/api/references/regions/')
            .then((res) => {
              if (res.body.data) {
                this.regions = res.body.data;
                this.regionsLoading = false;
              }
            }).catch((e) => {
          this.regionsLoading = false;
        });

          this.getCities(this.account.region_id, false);


          this.getLocalities(this.account.city_id, false);


          this.phone = this.account.parent.phone;
          if( this.phone){
            this.enforcePhoneFormat();
          }
    this.additionalPhone = this.account.parent.additional_phone;
         if( this.additionalPhone){
           this.enforceAdditionalPhoneFormat();
         }








    if ($('body').width() <= 992) {
      this.visibleMain=false;
      this.visibleEducation=false;
      this.visibleParent=false;
      this.visibleBtns=true;
    }
    else {
      this.visibleMain=true;
      this.visibleEducation=true;
      this.visibleParent=true;
      this.visibleBtns=false;
    }
    // if (this.account.informed === 0) {
    //   this.$bvModal.show('announcement-modal')
    // }
    if($('body').width() <= 992 && this.visibleMain===true) {
      this.visibleBtns=false;
    }

    if (sessionStorage.getItem('avatar-url')){
      this.avatarUrl = sessionStorage.getItem('avatar-url');
    }

  },

}
</script>
<style lang="scss">
form .avatar-uploader .btn-wrap{
  border: 1px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
}
form .avatar-uploader .btn-light{
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 6px #ccc;
  line-height: 22px;
}
form .avatar-uploader .btn-light svg{
  float: left;
  margin-right: 8px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.profile-content .avatar {
  float: left;
  width: 178px;
  height: 178px;
  border-radius: 178px;
  display: block;
  margin-right: 64px;
  object-fit: cover;
}
.more-info-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 22px;
}
.back-btn {
    background-color: #18A0FB;
    height: 55px;
    width: 58px;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    left: 15px;
    border: none;
}
.more-info-btn {
    width: 100%;
    height: 70px;
    left: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
}
.webcam-photo-input {
  position: relative;
}
.webcam-photo-input video {
  object-fit: cover;
}
.webcam-photo-input canvas {
  object-fit: none;
}
.webcam-photo-input__circle {
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  width: 190px;
  height: 270px;
  border-radius: 50%;
  border: 7px solid #18A0FB;
}
.modal #dialog button.close {
  z-index: 2;
}
.announcement-modal {
  img {
    width: 100%;
    height: 209px;
    object-fit: contain;
    position: static!important;
    margin-bottom: 20px;
  }
  h4 {
    color: #05458C;
    font-size: 30px;
    line-height: 1.8;
    font-weight: bold;
  }
  h6 {
    color: #05458C;
    font-size: 26px;
    line-height: 1.8;
    font-weight: bold;
  }
  h5 {
    color: #05458C;
    font-size: 26px;
    line-height: 1.8;
    font-weight: bold;
  }
  span,p {
    line-height: 1.6!important;
    a {
      word-break: break-all;
    }
    &.fw-medium {
      font-weight: 500!important;
    }
    &.c-blue {
      color: #2F80ED;
    }
    &.c-dark-blue {
      color: #05458C;
    }
    &.fz-18 {
      font-size: 18px!important;
    }
    &.fz-16 {
      font-size: 16px!important;
    }
  }
  .card-main {
    box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
    border-radius: 15px;
    border: none;
    margin: 0 auto 50px;
    padding: 20px 17px;
    background-color: #FFFFFF;
  }
}
@media (max-width: 992px) {
    .profile-content .avatar {
        margin: 0 auto 20px auto;
        float: none;
    }
    .profile-content .btn-take-photo {
      width: 100%;
    }
    .profile-content .btn-take-photo span {
      margin: 0 auto;
    }
    .avatar-uploader .el-upload {
        display: flex;
        flex-direction: column;
    }
    form .avatar-uploader .btn-wrap {
        width: fit-content;
        margin: 16px auto 84px;
    }
    .announcement-modal {
      h3 {
        font-size: 25px;
      }
      h6 {
        font-size: 16px;
      }
      p,span {
        font-size: 16px!important;
      }
    }
}
</style>
