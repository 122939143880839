<template>
  <div class="content profile-content" v-if="!loading">
    <ProfileNavbar :title="$t('profile.testing-page.title')"></ProfileNavbar>
    <div class="clearfix">
      <div class="">
        <div class="col-md-12">
          <div class="testing-lang-wrapper">
            <div class="testing-lang-wrapper-inner">
              <div class="testing-lang-wrapper-text">
                {{$t('testingLang')}}
              </div>
              <el-radio-group @change="update()" v-model="account.education.test_language"
                              :placeholder="$t('references.test-language-placeholder')" size="medium">
                <el-radio-button v-for="item in [{
                              label: $t('references.language-ru'),
                              value: 'ru'
                            }, {
                              label: $t('references.language-kz'),
                              value: 'kz'
                            }]"

                                 :key="item.label"
                                 :label="item.label"
                                 :value="item.value"></el-radio-button>

              </el-radio-group>
            </div>
            <img src="/images/100bala-testing-logo.svg" alt="" height="94px" style="margin:auto 0">

          </div>
          <h5 class="testing-title">{{ $t('testing.title') }}</h5>
          <div class="schools-wrap">
            <SchoolCard
                v-for="(quiz, index) in quizzes"
                v-if="quiz.time < quiz.end"
                :key="`${quiz.title_ru}_${index}`"
                :quiz="quiz"
                :test_lang="testLang"
            />
          </div>
          <h5 class="text-primary text-center" v-if="quizzes.length === 0">
            {{ $t('testing.no-test') }}
          </h5>
          <p class="mobile-note">
            {{ $t('testing.mobile-note') }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <Loader v-else/>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import moment from 'moment';
import VueMoment from "vue-moment";
import SchoolCard from '@/components/testing/SchoolCard'
import Loader from '@/components/Loader';

export default {
  name: 'Testing',
  components: {
    ProfileNavbar,
    SchoolCard,
    Loader
  },
  computed: {},
  data() {
    return {
      account: {
        education: {
          test_language: ''
        }
      },
      monthNames: {
        ru: ["января", "февраля", "марта", "апреля", "мая", "июня",
          "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ],
        kz: ["қаңтарда", "ақпанда", "наурызда", "сәуiрде", "мамырда", "маусымда",
          "шiлдеде", "тамызда", "қыркүйекте", "қазанда", "қарашада", "желтоқсанда"
        ]
      },
      quizzes: [],
      user_id: 0,
      testLang: '',
      loading: true,
      hasAvailableQuizzes: false,
      quizTime: false
    }
  },
  methods: {
    update() {
      if (this.account.education.test_language == this.$t('references.language-ru')) {
        this.account.education.test_language = 'ru'
      } else if (this.account.education.test_language == this.$t('references.language-kz')) {
        this.account.education.test_language = 'kz'
      }
      this.$http.post(window.API_ROOT + '/api/profile/update', {account: this.account})
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(this.account));
            Vue.toastr({
              message: 'Успешно!',
              description: res.data.success,
              type: 'success'
            });
            if (this.account.education.test_language == 'ru') {
              this.account.education.test_language = this.$t('references.language-ru')
            } else if (this.account.education.test_language == 'kz') {
              this.account.education.test_language = this.$t('references.language-kz')
            }
            this.$router.go()
          }).catch((e) => {
        Vue.toastr({
          message: 'Ошибка',
          description: "Ошибка при обновлении пароля",
          type: 'error'
        });
      });
    }
  },

  mounted: function () {
    if(!localStorage.getItem('user')){
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res.body.data));
            this.$router.go()
          })
    }
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.user_id = user.id || 0;
    }
    this.$http.get(API_ROOT + '/api/olympiads').then((res) => {
      if (res.body.data) {
        this.loading = false;
        this.quizzes = res.body.data
        console.log(this.quizzes.length, res.body.data);
        for (let i = 0; i < this.quizzes.length; i++) {
          const quiz = this.quizzes[i];

          this.quizTime = quiz.time < quiz.end


          this.hasAvailableQuizzes = quiz.time < quiz.end

        }
      }
    }).catch((e) => {
      this.loading = false;
      Vue.toastr({
        message: 'Error',
        description: e.body.data,
        type: 'error'
      })
    });
    if(!localStorage.getItem('user')){
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res.body.data));
            this.$router.go()
          })
    }
    let res = JSON.parse(localStorage.getItem('user'))
    this.account=res
    this.testLang=res.education.test_language
    if (this.account.education.test_language == 'ru') {
      this.account.education.test_language = this.$t('references.language-ru')
    } else if (this.account.education.test_language == 'kz') {
      this.account.education.test_language = this.$t('references.language-kz')
    }

    if (this.$i18n.locale === 'ru') {
      import('moment/locale/ru')
      Vue.use(VueMoment, {moment});
    }
    if (this.$i18n.locale === 'kz') {
      import('moment/locale/kk')
      Vue.use(VueMoment, {moment});
    }
  },
}
</script>
<style scoped lang="scss">
.mobile-note {
  display: none;
}

@media screen and (max-width: 678px) {
  .testing-title {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 600;
  }
}

</style>
