<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.notifications')"></ProfileNavbar>
    <router-link
        to="/notification"
        type="button"
        class="btn btn-light btn-back mb-3"
    >
      <img width="6" class="mr-3" src="/images/vector-back-grey.png"/>
      {{ $t('school.back-btn') }}
    </router-link>
    <Loader v-if="loader"/>
    <div class="col-12" v-else>
      <div class="d-flex flex-wrap justify-content-between align-items-center mb-5">
        <h1 class="notification-title">
          <img src="/images/message-check.svg" alt=""/>
          {{ data.title }}
        </h1>
        <p class="notification-date">
          {{ data.date }}
        </p>
      </div>
      <div class="col-12 notification-card">
        <img
            src="/images/1000-BALA-logo.png"
            alt=""
            class="notification-logo"
        />
        <h2 class="notification-fullname">
          {{ data.fullname }}
        </h2>
        <div class="row notification-results">
          <div class="col-4 notification-results__side order-mobile">
            {{ text.detail_logic }} <span>{{data.logic }} / {{ data.logic_max }}</span>
          </div>
          <div class="col-12 col-sm-4 notification-results__overall">
            {{ $t('profile.notifications.detail-final') }}
            <span>{{ data.result }} </span>
          </div>
          <div class="col-4 notification-results__side">
            {{ text.detail_thinking }} <span>{{data.thinking }} / {{ data.thinking_max }}</span>
          </div>
        </div>
        <div class="notification-message" v-html="data.message"></div>
        <div class="notification-callcenter my-3">
          {{ text.detail_callcenter }}
          <div class="d-flex align-items-center my-3">
            <a href="tel:87273100258">8 727 310 02 58</a>
            <span>{{ $t('profile.notifications.detail-free-call') }}</span>
          </div>
        </div>
        <div class="notification-download-wrapper" v-if="data.data">
          <a v-if="data.data.certificate" target="_blank" class="notification-download"
             :href="API_ROOT+'/storage/'+data.data.certificate">{{ $t('downloadCerificate') }}</a>
          <a v-if="data.data.diploma" target="_blank" class="notification-download"
             :href="API_ROOT+'/storage/'+data.data.diploma">{{ $t('downloadDiplom') }}</a>
        </div>
        <p class="notification-copyright">
          © 2020 Корпоративный фонд «EL ÜMITI». Все права защищены
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileNavbar from '@/components/ProfileNavbar'
import Loader from '@/components/Loader'

export default {
  name: 'Settings',
  components: {
    ProfileNavbar,
    Loader
  },
  data() {
    return {
      API_ROOT: window.API_ROOT,
      data: {
        date: '',
        fullname: '',
        is_viewed: 0,
        locale: 'ru',
        logic: '',
        message: '',
        thinking: '',
        title: '',
        total: '',
        type: null
      },
      text: {
        detail_logic: "Логическое мышление:",
        detail_thinking: "Пространственное мышление:",
        detail_total: "Набранное количество баллов:",
        detail_callcenter: "Если у Вас возникли вопросы, обращайтесь в кол-центр по номеру:",
        detail_free_call: "(звонок бесплатный)"
      },
      loader: true
    }
  },
  methods: {
    async getNotificationInfo(id) {
      try {
        const res = await this.$http.get(
            `${window.API_ROOT}/api/user/notifications/${id}`
        )
        if (res.body.data) {
          this.data = {
            ...res.body.data.data

          }

          if (res.body.data.locale === 'ru') {
            this.text = {
              detail_logic: "Логическое мышление:",
              detail_thinking: "Пространственное мышление:",
              detail_total: "Набранное количество баллов:",
              detail_callcenter: "Если у Вас возникли вопросы, обращайтесь в кол-центр по номеру:",
              detail_free_call: "(звонок бесплатный)"
            }
          }
          if (res.body.data.locale === 'kz') {
            this.text = {
              detail_logic: "Логикалық ойлау:",
              detail_thinking: "Кеңістіктік ойлау:",
              detail_total: "Жинаған ұпай саны:",
              detail_callcenter: "Егер сізде сұрақтар туындаса, 8 (800) 080 48 48 нөмірі бойынша байланыс орталығына хабарласыңыз.",
              detail_free_call: "(Қоңырау шалу тегін)"
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false
      }
    }
  },
  mounted() {
    this.getNotificationInfo(this.$route.params.id)
  }
}
</script>

<style lang="scss" scoped>
.notification {
  &-title {
    font-size: 24px;
    color: #05458c;
    font-weight: bold;

    img {
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-right: 10px;
      object-fit: contain;
    }
  }

  &-download-wrapper {
    justify-content: center;
    display: flex;
  }

  &-download {
    min-width: 200px;
    margin: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #2A8BF2;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 48px !important;
    text-decoration: none;
    color: white !important;
    border-radius: 10px;
    min-height: 48px;
    display: block;
    max-width: max-content;
  }

  &-date {
    font-size: 18px;
    color: #05458c;
  }

  &-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dfe0eb;
    padding: 33px 26px;
  }

  &-logo {
    display: block;
    width: 147px;
    margin: 0 auto;
  }

  &-fullname {
    font-size: 30px;
    color: #05458C;
    text-align: center;
    margin: 32px 0;
    font-weight: bold;
  }

  &-results {
    color: #18A0FB;

    &__side {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__overall {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  &-callcenter {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding: 11px;
      border-radius: 10px;
      border: 1px solid #DFE0EB;
      margin-right: 10px;

      &::before {
        content: '';
        display: block;
        float: left;
        width: 22px;
        height: 31px;
        margin-right: 10px;
        background-image: url('/images/phone.svg');
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }
    }

    span {
      color: #05458C;
      opacity: 0.3;
    }
  }

  &-copyright {
    text-align: center;
    font-size: 14px;
    color: #05458C;
    opacity: 0.3;
  }
}

@media screen and (max-width: 700px) {
  .notification-results__overall {
    margin: 1rem 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-mobile {
    order: 1;
  }
  .notification {
    &-title {
      font-size: 16px;

      img {
        display: none;
      }
    }

    &-date {
      font-size: 12px;
      margin-left: auto;
    }

    &-card {
      padding: 33px 13px;
    }

    &-results {
      &__side {
        margin: 1rem 0;
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-message {
      margin: 15px 0;
    }

    &-callcenter {
      a {
        white-space: nowrap;
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}
</style>
