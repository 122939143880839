<template>
	<div class="content profile-content" >
		<ProfileNavbar :title="$t('sidebar.notifications')"></ProfileNavbar>
		<router-link
			to="/notification"
			type="button"
			class="btn btn-light btn-back mb-3"
		>
			<img width="6" class="mr-3" src="/images/vector-back-grey.png" />
			{{ $t('school.back-btn') }}
		</router-link>
		<Loader v-if="loader" />
		<div class="col-12" v-else>
			<div class="d-flex flex-wrap justify-content-between align-items-center mb-5">
				<h1 class="notification-title">
					<img src="/images/message-check.svg" alt="" />
					{{ data.title }}
				</h1>
				<p class="notification-date">
					{{data.date}}
				</p>
			</div>
			<div class="col-12 notification-card">
        <div class="notification_title_part">
          <div class="notification_title_part_img"></div>
          <div class="notification_text_part">
            <div class="notification_text_part_label">
              {{$t('dear_participant')}}
            </div>
            <div class="notification_text_part_text" v-if="$i18n.locale == 'ru'">
              Хотим сообщить вам, что результаты вашего участия в первом этапе Национальной Олимпиады «Мың бала» <span style="color: #2A8BF2; font-weight: 700">не были приняты в обработку</span>. При анализе ответов были выявлены нарушения академической честности и объективности по следующим показателям:
            </div>
            <div class="notification_text_part_text" v-if="$i18n.locale == 'kz'">
              Сіздің «Мың бала» Ұлттық олимпиадасының бірінші кезеңіне қатысқандағы нәтижелеріңіз <span style="color: #2A8BF2; font-weight: 700">өңдеуге қабылданбағанын</span> хабарлағымыз келеді. Сіздің жауаптарыңызды талдау кезінде келесі көрсеткіштер бойынша академиялық адалдық пен объективтіліктің бұзылуы анықталды:
            </div>
          </div>
        </div>
        <div class="notification_reasons">
          {{$t('notification_reason_one')}}
        </div>
        <div class="notification_reasons">
          {{$t('notification_reason_two')}}
        </div>
        <div class="notification_principle_justice">
          {{$t('notification_principle_of_justice')}}
        </div>
        <div class="notification_desc">
          {{$t('notification_description')}}
        </div>
        <div class="notification_desc" v-if="$i18n.locale == 'ru'">
          При необходимости детальную информацию об инциденте нарушения академической честности можете запросить через колл-центр:  <span style="color: #2A8BF2; font-weight: 700">8 727 310 02 58</span>  <span style="color: #DFE0EB;">(звонок платный)</span>
        </div>
        <div class="notification_desc" v-if="$i18n.locale == 'kz'">
          Қажет болған жағдайда академиялық адалдықты бұзу туралы толық ақпаратты колл-орталық арқылы сұрай аласыз:  <span style="color: #2A8BF2; font-weight: 700">8 727 310 02 58</span>  <span style="color: #DFE0EB;">(қоңырау шалу ақылы)</span>
        </div>
        <div class="notification_regret">
          {{$t('notification_regret')}}
        </div>
        <div class="notification_call_part">
          <div class="notification_call_part_img">

          </div>
          <div class="notification_call_part_text">
            {{$t('notification_call')}}
          </div>
        </div>
        <div class="notification_thanks">
          {{$t('notification_thanks')}}
        </div>
				<p class="notification-copyright">
					© 2020 {{$t('footer.elbasy-academy')}}. {{$t('footer.copyright')}}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
	import ProfileNavbar from '@/components/ProfileNavbar'
	import Loader from '@/components/Loader'

	export default {
		name: 'Settings',
		components: {
			ProfileNavbar,
			Loader
		},
		data() {
			return {
        API_ROOT: window.API_ROOT,
				data: {
          date: "",
          english: "",
          english_max: "",
          fullname: "",
          is_viewed: 0,
          locale: "",
          math: "",
          math_max: "",
          message: "",
          native_lang: "",
          native_lang_max: "",
          natural_science: "",
          natural_science_max: "",
          title: "",
          total: "",
          total_max: "",
          type: ''
				},
				text: {
					detail_math: "Математическая грамотность:",
					detail_native_lang: "Казахский или русский язык:",
					detail_english: "Английский язык:",
					detail_natural_science: "Естествознание:",
					detail_total: "Набранное количество баллов:",
					detail_callcenter: "Если у Вас возникли вопросы, обращайтесь в кол-центр по номеру:",
					detail_free_call: "(звонок бесплатный)"
				},
				loader: false
			}
		},
    filters :{
      round:function (value){
        return Math.round((value)*100)/100
      }
    },
		methods: {
			async getNotificationInfo(id) {
				try {
					const res = await this.$http.get(
						`${window.API_ROOT}/api/user/notifications-3/${id}`
					)
					if (res.body.data) {
						this.data = {
							...res.body.data
						}
						if (res.body.data.locale === 'ru') {
							this.text = {
								detail_math: "Математическая грамотность:",
								detail_native_lang: "Казахский или русский язык:",
								detail_english: "Английский язык:",
								detail_natural_science: "Естествознание:",
								detail_total: "Итоговый бал:",
								detail_callcenter: "Если у Вас возникли вопросы, обращайтесь в кол-центр по номеру:",
								detail_free_call: "(звонок бесплатный)"
							}
						}
						if (res.body.data.locale === 'kz') {
							this.text = {
								detail_math: "Математикалық сауаттылық:",
								detail_native_lang: "Қазақ немесе орыс тілі:",
								detail_english: "Ағылшын тілі:",
								detail_natural_science: "Жаратылыстану:",
								detail_total: "Қорытынды бал:",
								detail_callcenter: "Егер сізде сұрақтар туындаса, 8 (800) 080 48 48 нөмірі бойынша байланыс орталығына хабарласыңыз.",
								detail_free_call: "(Қоңырау шалу тегін)"
							}
						}
					}
				} catch (error) {
					console.log(error);
				} finally {
					this.loader = false
				}
			}
		},
		mounted() {
			// this.getNotificationInfo(this.$route.params.id)
		}
	}
</script>

<style lang="scss" scoped>

.notification_thanks {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #303030;
  margin-top: 30px;
}

.notification_call_part_text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #303030;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
}

.notification_call_part {
  background: #F7F8FC;
  border-radius: 10px;
  padding: 0 20px;
  display: flex;
}

.notification_call_part_img {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/notification_call_part_img.png");
}

.notification_regret {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #2A8BF2;
  margin-bottom: 30px;
}

.notification_desc {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #303030;
  margin-bottom: 30px;
}

.notification_principle_justice {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #303030;
  padding-left: 30px;
  position: relative;
  margin-bottom: 30px;
}

.notification_principle_justice::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 0;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/notification_principle_justice.svg");
}

.notification_reasons {
  background: #F7F8FC;
  border-radius: 10px;
  padding: 15px 25px;
  border-left: 5px solid #2A8BF2;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #303030;
}

.notification_text_part_text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #303030;
}

.notification_text_part_label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #303030;
  margin-bottom: 20px;
}


.notification_text_part {
  margin-left: 20px;
}

.notification_title_part {
  display: flex;
  margin-bottom: 30px;
}

.notification_title_part_img {
  width: 140px;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/notification_new_type.png");
}

.notification {
  &-title {
    font-size: 24px;
    color: #05458c;
    font-weight: bold;

    img {
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-right: 10px;
      object-fit: contain;
    }
  }

  &-download-wrapper {
    justify-content: center;
    display: flex;
  }

  &-download {
    min-width: 200px;
    margin: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #2A8BF2;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 48px !important;
    text-decoration: none;
    color: white !important;
    border-radius: 10px;
    min-height: 48px;
    display: block;
    max-width: max-content;
  }

  &-date {
    font-size: 18px;
    color: #05458c;
  }

  &-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dfe0eb;
    padding: 33px 26px;
  }

  &-logo {
    display: block;
    width: 147px;
    margin: 0 auto;
  }

  &-fullname {
    font-size: 30px;
    color: #05458C;
    text-align: center;
    margin: 32px 0;
    font-weight: bold;
  }

  &-results {
    color: #18A0FB;

    &__side {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__overall {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  &-callcenter {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding: 11px;
      border-radius: 10px;
      border: 1px solid #DFE0EB;
      margin-right: 10px;

      &::before {
        content: '';
        display: block;
        float: left;
        width: 22px;
        height: 31px;
        margin-right: 10px;
        background-image: url('/images/phone.svg');
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }
    }

    span {
      color: #05458C;
      opacity: 0.3;
    }
  }

  &-copyright {
    text-align: center;
    font-size: 14px;
    color: #05458C;
    opacity: 0.3;
  }
}

@media screen and (max-width: 1700px) {
  .notification_title_part_img {
    width: 160px;
  }
}

@media screen and (max-width: 1600px) {
  .notification_title_part_img {
    width: 170px;
  }
}

@media screen and (max-width: 1500px) {
  .notification_title_part_img {
    width: 220px;
  }
}

@media screen and (max-width: 1300px) {
  .notification_title_part_img {
    width: 260px;
  }
}


@media screen and (max-width: 1000px) {
  .notification_call_part_img {
    display: none;
  }

  .notification_title_part_img {
    display: none;
  }

  .notification_call_part_text {
    padding: 20px;
  }

}

@media screen and (max-width: 700px) {

  .notification {
    &-title {
      font-size: 16px;

      img {
        display: none;
      }
    }

    &-date {
      font-size: 12px;
      margin-left: auto;
    }

    &-card {
      padding: 33px 13px;
    }

    &-results {
      &__side {
      display: none;
      }
    }

    &-message {
      margin: 15px 0;
    }

    &-callcenter {
      a {
        white-space: nowrap;
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}
</style>
