<template>
  <div class="container">
    <div class="row mt-5 helper-flex-column-rev">
      <div class="col-lg-5 col-md-12">
        <div class="text-center welcome-text">
          <h1 v-if="!tech">{{ $t('signin-form.title')}}</h1>
          <h1 v-else>{{ $t('signin-form.tech-title') }}</h1>
        </div>
        <div class="px-4" v-if="!tech">

          <LoginFormTeacher></LoginFormTeacher>



        </div>
        <div class="px-4" v-else>
          <tech-works :min="min < 10 ? '0'+ min : min" :sec="sec < 10 ? '0'+ sec : sec"></tech-works>
        </div>
      </div>
      <div style="margin-left: auto;" class="col-lg-5 col-md-12 py-5">
        <div class="intstruction-wrapper">
          <span class="instruction-title">{{$t('signup-by-iin.signin-instruction')}}</span>
          <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция_по_авторизации.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <a v-if="$i18n.locale=='kz'" download href="/files/kz/Авторизациялану_нұсқаулығы.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <button  @click="$bvModal.show('youtube-modal-auth')" class="watch-instruction">{{ $t('signup-by-iin.see-instruction') }}</button>
        </div>
        <div class="intstruction-wrapper">
          <span class="instruction-title">{{$t('signup-by-iin.password')}}</span>
          <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция_по_восстановлению_пароля.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <a v-if="$i18n.locale=='kz'" download href="/files/kz/Құпия_сөзді_қалпына_келтіру_бойынша_нұсқаулық.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <button  @click="$bvModal.show('youtube-modal-recovery')" class="watch-instruction">{{ $t('signup-by-iin.see-instruction') }}</button>
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal-auth" hide-footer hide-header>
      <div >
        <div id="dialog">

          <button @click="$bvModal.hide('youtube-modal-auth')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe  style="width: 100%; height: 500px;" v-if="$i18n.locale=='kz'"  src="https://www.youtube.com/embed/bZB5FsrBQrI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='ru'"  src="https://www.youtube.com/embed/pUDS8_XUMmE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </b-modal>

    <b-modal id="youtube-modal-recovery" hide-footer hide-header>
      <div>
        <div id="dialog">
          <button @click="$bvModal.hide('youtube-modal-recovery')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe  style="width: 100%; height: 500px;" v-if="$i18n.locale=='ru'"  src="https://www.youtube.com/embed/WX7KQ7cksI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='kz'"  src="https://www.youtube.com/embed/RYimnQrACYM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from 'vuex'
import LoginFormTeacher from '@/components/LoginFormTeacher'
import TechWorks from '@/components/TechWorks'
export default {
  name: 'Login',
  components: {
    LoginFormTeacher,
    TechWorks
  },
  data() {
    return {
      min: 0,
      sec: 0
    }
  },
  computed: {
    ...mapGetters({
      tech: 'isTech',
      time: 'getTime'
    })
  },
  methods: {
    ...mapActions({
      'setTech': 'setTech',
      // 'getTech': 'getTech'
    }),
  },
  watch: {
    sec: {
      handler(value) {
        if(value >= 0) {
          setTimeout(() => {
            this.sec--;
          }, 1000)
        } else if(this.min || this.hour > 0) {
          this.sec = 59;
          if(this.min > 0) {
            this.min--
          }
        }
        if(this.hour === 0 && this.min === 0 && this.sec === 0) {
          setTimeout(() => {
            this.setTech(false)
          }, 1000)
        }
      },
      immediate: true,
    }
  },
  mounted() {
    // this.getTech();
    this.min = this.time
  }
}
</script>
<style lang="css" scoped>
@media screen and (max-width: 575px) {
  .helper-flex-column-rev{
    margin-top: 0!important;
    padding: 0;
    flex-direction: column-reverse;
  }
  .adaptive-wrapper{
    flex-direction: column;
  }
  .iin-input{
    max-width: unset;
  }
  .download-instruction{
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
  .watch-instruction{
    width: 100%;
    margin-left: 0;
  }
  .button-iin-signup-group{
    flex-direction: column;
  }
  .button-iin-next{
    margin-right: 0;
    display: block;
    width: 100%;
  }
  .button-iin-clear{
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }
  .iin-search-item{
    font-size: 14px;
  }
  .iin-info{
    padding: 10px;
  }
  .warning-message{
    width: 64%;
  }
}
.el-select-dropdown__empty{
  padding: 10px;
}
</style>
