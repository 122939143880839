<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.schools-list')"></ProfileNavbar>
    <b-modal id="results" size="lg" hide-footer>
      <div class="col-12" v-if="modalInfo.quiz">
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-5">
          <h1 class="notification-title">
            {{ modalInfo.quiz }}
          </h1>
          <p class="notification-date">
            {{ modalInfo.ended.split(' ')[0] }}
          </p>
        </div>
        <div class="col-12">
          <img
              src="/images/1000-BALA-logo.png"
              alt=""
              class="notification-logo"
          />
          <h2 class="notification-fullname">
            {{ user.first_name + ' ' + user.last_name }}

          </h2>
          <div class="row notification-results">
            <div class="col-4 notification-results__side order-mobile">
              {{ text.detail_logic }}
              <span>{{ modalInfo.points.logic }} / {{ modalInfo.points.logic_max }}</span>
            </div>
            <div class="col-12 col-sm-4 notification-results__overall">
              {{ $t('profile.notifications.detail-total') }}
              <span>{{ modalInfo.points.result }} </span>
            </div>
            <div class="col-4 notification-results__side">
              {{ text.detail_thinking }}
              <span>{{ modalInfo.points.thinking }} / {{ modalInfo.points.thinking_max }}</span>
            </div>
          </div>
          <p class="notification-copyright">
            © {{ (new Date()).getFullYear() }} {{ $t('footer.elbasy-academy') }}. {{ $t('footer.copyright') }}
          </p>
        </div>
      </div>
    </b-modal>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="quiz"
          :label="$t('name')"
      >
      </el-table-column>
      <el-table-column
          prop="points.result"
          :label="$t('result')">
      </el-table-column>
      <el-table-column
          prop="ended"
          :label="$t('date')">
        <template slot-scope="scope">
          {{ scope.row.ended.split(' ')[0] }}
        </template>
      </el-table-column>
      <el-table-column
          prop="ended"
          :label="$t('actions')"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="openModal(scope.row)">{{ $t('result_info') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Vue from 'vue'
import ProfileNavbar from '@/components/ProfileNavbar'
import {VueperSlides, VueperSlide} from 'vueperslides'
import VueMask from 'v-mask'
import 'vueperslides/dist/vueperslides.css'
import {required, email} from 'vuelidate/lib/validators'
import Loader from '@/components/Loader'

export default {
  name: 'Results',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      modalInfo: {},
      tableData: [],
      errorMessage: '',
      text: {
        detail_logic: this.$t('profile.notifications.detail-logic'),
        detail_thinking: this.$t('profile.notifications.detail-thinking'),
        detail_total: this.$t('profile.notifications.detail-total'),
        detail_callcenter: this.$t('call_centre'),
        detail_free_call: this.$t('call_free'),
      },
    }
  },
  methods: {
    openModal(item) {
      console.log(item)
      this.modalInfo = item
      this.$bvModal.show('results')
    }

  },
  beforeCreate() {
    Vue.use(VueMask)
  },
  mounted() {
    console.log(this.user)
    this.$http.get(window.API_ROOT + '/api/my-results')
        .then((res) => {
          this.tableData = res.body.data
        })
  }
}
</script>
<style lang="scss" scoped>
.notification {
  &-title {
    font-size: 24px;
    color: #05458c;
    font-weight: bold;

    img {
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-right: 10px;
      object-fit: contain;
    }
  }

  &-download-wrapper {
    justify-content: center;
    display: flex;
  }

  &-download {
    min-width: 200px;
    margin: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #2A8BF2;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 48px !important;
    text-decoration: none;
    color: white !important;
    border-radius: 10px;
    min-height: 48px;
    display: block;
    max-width: max-content;
  }

  &-date {
    font-size: 18px;
    color: #05458c;
  }

  &-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dfe0eb;
    padding: 33px 26px;
  }

  &-logo {
    display: block;
    width: 147px;
    margin: 0 auto;
  }

  &-fullname {
    font-size: 30px;
    color: #05458C;
    text-align: center;
    margin: 32px 0;
    font-weight: bold;
  }

  &-results {
    color: #18A0FB;

    &__side {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__overall {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  &-callcenter {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding: 11px;
      border-radius: 10px;
      border: 1px solid #DFE0EB;
      margin-right: 10px;

      &::before {
        content: '';
        display: block;
        float: left;
        width: 22px;
        height: 31px;
        margin-right: 10px;
        background-image: url('/images/phone.svg');
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }
    }

    span {
      color: #05458C;
      opacity: 0.3;
    }
  }

  &-copyright {
    text-align: center;
    font-size: 14px;
    color: #05458C;
    opacity: 0.3;
  }
}

@media screen and (max-width: 700px) {
  .notification-results__overall {
    margin: 1rem 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-mobile {
    order: 1;
  }
  .notification {
    &-title {
      font-size: 16px;

      img {
        display: none;
      }
    }

    &-date {
      font-size: 12px;
      margin-left: auto;
    }

    &-card {
      padding: 33px 13px;
    }

    &-results {
      &__side {
        margin: 1rem 0;
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-message {
      margin: 15px 0;
    }

    &-callcenter {
      a {
        white-space: nowrap;
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}
</style>

