<template>
  <div class="content profile-content">
    <ProfileNavbarTeacher :title="$t('profile.title')"></ProfileNavbarTeacher>
    <div class="clearfix">
      <div class="row">
        <div class="col-md-12">
          <div class="profile-edit-form w-100">
            <button v-on:click="showButtons(false, false, false, true, false)" v-show="visibleBtnBack" class="back-btn">
              <img src="/images/arrow-back.png" alt="">
            </button>
            <h3 class="title text-center text-lg-left">{{ $t('signup.place-of-work') }}</h3>
            <!--            <p class="hint text-center text-lg-left">{{ $t('profile.description') }}</p>-->
            <form @submit.prevent="updateProfile()">
              <div class="row">
                <div class="col-lg-4 col-md-12 education-info">
                  <form-group>
                    <el-select
                        @change="setRegion"
                        :placeholder="$t('references.region-placeholder')"
                        v-model="account.region_id"
                        filterable>
                      <el-option
                          v-for="item in regions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </form-group>

                  <form-group>
                    <el-select
                        @change="setDistrict"
                        :placeholder="$t('references.district-placeholder')"
                        v-model="account.city_id"
                        filterable>
                      <el-option
                          v-for="item in cities"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </form-group>
                  <form-group>
                    <el-select
                        @change="setLocality"
                        :placeholder="$t('references.locality-placeholder')"
                        v-model="account.locality_id"
                        filterable>
                      <el-option
                          v-for="item in localities"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </form-group>

                </div>

                <div class="col-lg-4 col-md-12 education-info">

                  <form-group>
                    <el-select
                        :placeholder="$t('references.school-placeholder')"
                        v-model="account.school_id"
                        filterable>
                      <el-option
                          v-for="item in schools"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </form-group>

                  <form-group>
                    <el-select v-model="account.subject_id" :placeholder="$t('subject_of_teaching')">
                      <el-option
                          v-for="item in subjects"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>

                  </form-group>

                  <form-group>

                    <el-select v-model="account.teaching_language_id" :placeholder="$t('education-language-label-teacher')">
                      <el-option
                          v-for="item in langs"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>

                  </form-group>

                </div>

              </div>
              <div class="row my-4 ">
                <button type="submit" class="btn btn-primary mx-auto col-lg-2 col-10">{{
                    $t('profile.save-btn')
                  }}
                </button>

              </div>
            </form>
            <div v-show="visibleBtns" class="more-info-btns p-0">
              <a v-on:click="showInputs(true, false, false, false, true)" class="btn more-info-btn mt-5" href="#"
                 role="button">{{ $t('profile.main') }}</a>
              <a v-on:click="showInputs(false, true, false, false, true)" class="btn more-info-btn mt-5" href="#"
                 role="button">{{ $t('profile.education') }}</a>
              <a v-on:click="showInputs(false, false, true, false, true)" class="btn more-info-btn mt-5" href="#"
                 role="button">{{ $t('profile.representative') }}</a>
            </div>
          </div>
        </div>


        <!--        <div class="col-md-4">-->
        <!--          <div class="row pr-3">-->
        <!--            <div class="password-edit-form">-->
        <!--              <h3 class="title">Настройки пароля</h3>-->
        <!--              <p class="hint">Если вы желаете изменить пароль - заполните форму ниже</p>-->
        <!--              <form action="#" method="POST">-->
        <!--                <div class="form-group">-->
        <!--                  <label class="form-label">Новый пароль</label>-->
        <!--                  <el-input placeholder="Введите пароль" v-model="password"></el-input>-->
        <!--                </div>-->
        <!--                <div class="form-group">-->
        <!--                  <label class="form-label">Повторите пароль</label>-->
        <!--                  <el-input placeholder="Повторите пароль" v-model="passwordConfirm"></el-input>-->
        <!--                </div>-->
        <!--                <div class="row my-4">-->
        <!--                  <el-button type="primary" class="mx-auto">Сохранить</el-button>-->
        <!--                </div>-->
        <!--              </form>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div>
          <button @click="$router.go('/profile')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-danger-img mb-3" src="/images/profile/modal-danger.svg" alt="">
          <h3>{{ errorModalTitle }}</h3>
          <span>{{ errorModalDesc }}</span>
          <div class="text-center">
            <button @click="$router.go('/profile')" class="btn btn-primary mx-2 my-2">{{
                $t('signup.photo-modal-retry')
              }}
            </button>
            <button @click="$router.go('/profile')" class="btn btn-outline-primary mx-2 my-2">
              {{ $t('signup.photo-modal-cancel') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="announcement-modal" hide-footer hide-header no-close-on-backdrop size="lg">
      <div id="dialog">
        <div class="row announcement-modal">
          <div class="col-12">
            <img src="/images/announcement-window.svg" alt="">
            <h4>
              {{ $t('profile.informed-modal.title') }}
            </h4>
            <h6>
              {{ $t('profile.informed-modal.for') }}
            </h6>
            <p class="fz-18 fw-medium c-dark-blue" v-html="$t('profile.informed-modal.start')"></p>
            <div class="card-main">
              <p class="fz-18 fw-medium c-blue">{{ $t('profile.informed-modal.important-info-title') }}</p>
              <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-1')" v-html="item"
                 :key="item"></p>
            </div>
            <h5> {{ $t('profile.informed-modal.technical-recomendation') }}</h5>
            <div class="card-main row">
              <div class="col-12 col-sm-4">
                <img src="/images/informed-desktop.svg" alt="">
              </div>
              <div class="col-12 col-sm-8">
                <!-- <p class="fz-18" >
                  Если вы проходите тестирование с <span class="c-blue">персонального компьютера, ноутбука:</span>
                </p> -->
                <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-2')" v-html="item"
                   :key="item"></p>
              </div>
            </div>
            <div class="card-main row">
              <div class="col-12 col-sm-4">
                <img src="/images/informed-mobile.svg" alt="">
              </div>
              <div class="col-12 col-sm-8">
                <!-- <p class="fz-18">
                  Если вы проходите тестирование с <span class="c-blue">персонального компьютера, ноутбука:</span>
                </p> -->
                <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-3')" v-html="item"
                   :key="item"></p>
              </div>
            </div>
            <h5>{{ $t('profile.informed-modal.luck') }}</h5>
            <el-checkbox v-model="informedCheckbox">{{ $t('profile.informed-modal.confirmed') }}</el-checkbox>
            <div class="col-12 mt-3">
              <el-button type="primary" :disabled="!informedCheckbox" @click="confirmInformedModal">
                {{ $t('profile.informed-modal.next') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions} from "vuex";
import ProfileNavbarTeacher from "@/components/ProfileNavbarTeacher";
import VueMask from 'v-mask'
import * as API from "@/api";

export default {
  name: 'Account',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    ProfileNavbarTeacher,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date();
          startDate.setMonth(11, 31);
          startDate.setFullYear(startDate.getFullYear() - 15);

          let endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setFullYear(endDate.getFullYear() - 9);

          return time.getTime() < startDate || time.getTime() >= endDate;
        }
      },
      loading: false,
      visibleMain: true,
      visibleEducation: true,
      visibleParent: true,
      userCanGo: false,
      acceptPhoto: false,
      takedScreenshot: false,
      takedImageSource: '',
      visibleBtns: false,
      visibleBtnBack: false,
      cameraInitialized: false,
      webcamActive: false,
      additionalPhone: '',
      apiRoot: window.API_ROOT,
      avatarUrl: '',
      password: '',
      passwordConfirm: '',
      regions: [],
      cities: [],
      localities: [],
      schools: [],
      subjects: [],
      langs: [],
      regionsLoading: false,
      schoolsLoading: false,
      citiesLoading: false,
      localitiesLoading: false,
      account: {
        iin: '',
        sex: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        region_name: '',
        region_id: '',
        city_name: '',
        city_id: '',
        locality_name: '',
        locality_id: '',
        birth_date: '',
        phone: '',
        school_id: '',
        teaching_language_id: '',
        subject_id: '',
      },
      informedCheckbox: false,
      errorModalTitle: '',
      errorModalDesc: ''
    }
  },
  methods: {
    // TODO доработать после того как будет АПИ

    setDistrict() {
      this.$http.get(`${API.Api}/api/references/localities/${this.account.city_id}`)
          .then(res => {
            this.disabledLocalities = false,
                this.localities = res.body.data
            this.account.locality_id = ''
          })
    },

    setRegion() {
      this.$http.get(`${API.Api}/api/references/districts/${this.account.region_id}`)
          .then(res => {
            this.cities = res.body.data
            this.account.city_id = ''
            this.account.locality_id = ''
            this.account.school_id = ''
          })
    },

    setLocality() {
      this.$http.get(`${API.Api}/api/references/schools/${this.account.locality_id}`)
          .then(res => {
            this.disabledSchools = false,
                this.schools = res.body.data
            this.account.school_id = ''

          })
    },


    updateProfile() {

      let accountData = {
        birth_date: this.account.birth_date,
        gender: this.account.sex,
        last_name: this.account.last_name,
        first_name: this.account.first_name,
        locality_id: this.account.locality_id,
        school_id: this.account.school_id,
        subject_id: this.account.subject_id,
        teaching_language_id: this.account.teaching_language_id,
        email: this.account.email,
        phone: this.account.phone
      }

      this.$http.post(window.API_ROOT + '/api/teacher/update', accountData)
          .then((res) => {
            localStorage.setItem('user', JSON.stringify(res.body));

            Vue.toastr({
              message: this.$t('pole-modal.success') + '!',
              description: res.data.success,
              type: 'success'
            });
          }).catch((e) => {
        Vue.toastr({
          message: 'Ошибка',
          description: "Ошибка при обновлении пароля",
          type: 'error'
        });
      });
    },

    showInputs(visibleMain, visibleEducation, visibleParent, visibleBtns, visibleBtnBack) {
      this.visibleMain = visibleMain;
      this.visibleEducation = visibleEducation;
      this.visibleParent = visibleParent;
      this.visibleBtns = visibleBtns;
      this.visibleBtnBack = visibleBtnBack;
    },
    showButtons(visibleMain, visibleEducation, visibleParent, visibleBtns, visibleBtnBack) {
      this.visibleMain = visibleMain;
      this.visibleEducation = visibleEducation;
      this.visibleParent = visibleParent;
      this.visibleBtns = visibleBtns;
      this.visibleBtnBack = visibleBtnBack;
    },
    getCities(id, reset = true) {
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              if (reset) {
                this.account.city_id = '';
              }
            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalities(id, reset = true) {
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              if (reset) {
                this.account.locality_id = '';
              }
            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    enforcePhoneFormat() {
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.phone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");
      this.account.parent.phone = x[0];
    },
    enforceAdditionalPhoneFormat() {
      let x = this.additionalPhone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.additionalPhone = !x[2]
          ? x[1]
          : "+" + x[1] + " (" + x[2] + ")" + (x[3] ? " " + x[3] : "") + (x[4] ? " " + x[4] : "") + (x[5] ? "-" + x[5] : "");
      this.account.parent.additional_phone = x[0];
    },
    // handleAvatarSuccess(res, file) {
    //   this.avatarUrl = URL.createObjectURL(file.raw);
    // },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!(isJPG || isPNG)) {
        this.$message.error('Avatar picture must be JPG or PNG format!');
      }
      if (!isLt4M) {
        this.$message.error('Avatar picture size can not exceed 4MB!');
      }
      return (isJPG || isPNG) && isLt4M;
    },
    logoutMethod() {
      this.logout();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      sessionStorage.setItem('avatar-url', '')
      window.location = '/';
      // this.$router.push('/');
    },
    async confirmInformedModal() {
      try {
        await this.$http.post(`${window.API_ROOT}/api/user/informed`, {
          informed: 1
        })
        this.$bvModal.hide('announcement-modal')
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        })
      }
    },
    ...mapActions({
      'logout': 'logout'
    }),
  },
  mounted() {

    this.$http.get(window.API_ROOT + '/api/references/regions/')
        .then((res) => {
          if (res.body.data) {
            this.regions = res.body.data;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    setTimeout(() => {
      if (this.account.region_id) {
        this.$http.get(`${API.Api}/api/references/districts/${this.account.region_id}`)
            .then((res) => {
              this.cities = res.body.data
            })
      }
    }, 0)

    setTimeout(() => {
      if (this.account.city_id) {
        this.$http.get(`${API.Api}/api/references/localities/${this.account.city_id}`)
            .then((res) => {
              this.localities = res.body.data
            })
      }
    }, 200)

    setTimeout(() => {
      if (this.account.locality_id) {
        this.$http.get(`${API.Api}/api/references/schools/${this.account.locality_id}`)
            .then((res) => {
              this.schools = res.body.data
            })
      }
    }, 500)


    this.$http.get(window.API_ROOT + '/api/references/subjects/')
        .then((res) => {
          if (res.body.data) {
            this.subjects = res.body.data;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + '/api/references/teaching-languages/')
        .then((res) => {
          if (res.body.data) {
            this.langs = res.body.data;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });


    if (localStorage.getItem('user')) {

      let teacher = JSON.parse(localStorage.getItem('user'))

      this.account.iin = teacher.iin
      this.account.sex = +(teacher.gender)
      this.account.first_name = teacher.first_name
      this.account.last_name = teacher.last_name
      this.account.patronymic = teacher.patronymic ?? 'Отсутствует'
      this.account.email = teacher.email
      this.account.phone = teacher.phone
      this.account.region_name = teacher.region.name
      this.account.region_id = teacher.region.id
      this.account.city_name = teacher.city.name
      this.account.city_id = teacher.city.id
      this.account.locality_name = teacher.locality.name
      this.account.locality_id = teacher.locality.id
      this.account.birth_date = teacher.birth_date
      this.account.school_id = teacher.school.id
      this.account.teaching_language_id = teacher.teaching_language.id
      this.account.subject_id = teacher.subject.id
    }


  }


  // mounted() {
  //   if (!localStorage.getItem('user')) {
  //     this.$http.get(window.API_ROOT + '/api/user/edit')
  //         .then((res) => {
  //           localStorage.setItem('user', JSON.stringify(res.body.data));
  //           this.$router.go()
  //         })
  //   }
  //   let res = JSON.parse(localStorage.getItem('user'))
  //   sessionStorage.setItem('avatar-url', `${window.API_ROOT}/api/user/avatar?file=${res.avatar}`);
  //   this.avatarUrl = `${window.API_ROOT}/api/user/avatar?file=${res.avatar}`;
  //   this.account = res;
  //   if (this.account.parent == null) {
  //     this.account.parent = {
  //       last_name: '',
  //       first_name: '',
  //       patronymic: '',
  //       email: '',
  //     }
  //
  //   }
  //   console.log(this.account)
  //   this.account.result === null && (this.account.result = 0)
  //   this.regionsLoading = true;
  //   this.$http.get(window.API_ROOT + '/api/references/regions/')
  //       .then((res) => {
  //         if (res.body.data) {
  //           this.regions = res.body.data;
  //           this.regionsLoading = false;
  //         }
  //       }).catch((e) => {
  //     this.regionsLoading = false;
  //   });
  //
  //   this.getCities(this.account.region_id, false);
  //
  //
  //   this.getLocalities(this.account.city_id, false);
  //
  //
  //   this.phone = this.account.parent.phone;
  //   if (this.phone) {
  //     this.enforcePhoneFormat();
  //   }
  //   this.additionalPhone = this.account.parent.additional_phone;
  //   if (this.additionalPhone) {
  //     this.enforceAdditionalPhoneFormat();
  //   }
  //
  //
  //   if ($('body').width() <= 992) {
  //     this.visibleMain = false;
  //     this.visibleEducation = false;
  //     this.visibleParent = false;
  //     this.visibleBtns = true;
  //   } else {
  //     this.visibleMain = true;
  //     this.visibleEducation = true;
  //     this.visibleParent = true;
  //     this.visibleBtns = false;
  //   }
  //   if (this.account.informed === 0) {
  //     this.$bvModal.show('announcement-modal')
  //   }
  //   if ($('body').width() <= 992 && this.visibleMain === true) {
  //     this.visibleBtns = false;
  //   }
  //
  //   if (sessionStorage.getItem('avatar-url')) {
  //     this.avatarUrl = sessionStorage.getItem('avatar-url');
  //   }
  //
  // },

}
</script>
<style lang="scss">
form .avatar-uploader .btn-wrap {
  border: 1px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
}

form .avatar-uploader .btn-light {
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 6px #ccc;
  line-height: 22px;
}

form .avatar-uploader .btn-light svg {
  float: left;
  margin-right: 8px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.profile-content .avatar {
  float: left;
  width: 178px;
  height: 178px;
  border-radius: 178px;
  display: block;
  margin-right: 64px;
  object-fit: cover;
}

.more-info-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px;
}

.back-btn {
  background-color: #18A0FB;
  height: 55px;
  width: 58px;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 15px;
  border: none;
}

.more-info-btn {
  width: 100%;
  height: 70px;
  left: 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webcam-photo-input {
  position: relative;
}

.webcam-photo-input video {
  object-fit: cover;
}

.webcam-photo-input canvas {
  object-fit: none;
}

.webcam-photo-input__circle {
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  width: 190px;
  height: 270px;
  border-radius: 50%;
  border: 7px solid #18A0FB;
}

.modal #dialog button.close {
  z-index: 2;
}

.announcement-modal {
  img {
    width: 100%;
    height: 209px;
    object-fit: contain;
    position: static !important;
    margin-bottom: 20px;
  }

  h4 {
    color: #05458C;
    font-size: 30px;
    line-height: 1.8;
    font-weight: bold;
  }

  h6 {
    color: #05458C;
    font-size: 26px;
    line-height: 1.8;
    font-weight: bold;
  }

  h5 {
    color: #05458C;
    font-size: 26px;
    line-height: 1.8;
    font-weight: bold;
  }

  span, p {
    line-height: 1.6 !important;

    a {
      word-break: break-all;
    }

    &.fw-medium {
      font-weight: 500 !important;
    }

    &.c-blue {
      color: #2F80ED;
    }

    &.c-dark-blue {
      color: #05458C;
    }

    &.fz-18 {
      font-size: 18px !important;
    }

    &.fz-16 {
      font-size: 16px !important;
    }
  }

  .card-main {
    box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
    border-radius: 15px;
    border: none;
    margin: 0 auto 50px;
    padding: 20px 17px;
    background-color: #FFFFFF;
  }
}

@media (max-width: 992px) {
  .profile-content .avatar {
    margin: 0 auto 20px auto;
    float: none;
  }
  .profile-content .btn-take-photo {
    width: 100%;
  }
  .profile-content .btn-take-photo span {
    margin: 0 auto;
  }
  .avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
  }
  form .avatar-uploader .btn-wrap {
    width: fit-content;
    margin: 16px auto 84px;
  }
  .announcement-modal {
    h3 {
      font-size: 25px;
    }

    h6 {
      font-size: 16px;
    }

    p, span {
      font-size: 16px !important;
    }
  }
}
</style>
