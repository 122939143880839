<template>
  <div class="container">
    <div class="mail_confirmed_label">
      {{ $t('mail-confirmed') }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'MailConfirmed',
  mounted() {
    setTimeout(() => {
      this.$router.push('/for-teachers');
    }, 2500)
  }
}
</script>
<style lang="css" scoped>

.mail_confirmed_label {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

</style>
