<template>
  <div id="app" class="app" v-if="!isLoading">
    <router-view name="sidebar"></router-view>
    <router-view name="header"></router-view>
    <transition name="router" mode="out-in">
      <router-view class="view router-view"></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
  <Loader v-else/>
</template>

<script>
import Vue from 'vue';
import Loader from './components/Loader';
import {
  mapActions,
  mapGetters
} from 'vuex'
export default {
  name: 'App',
  components: {
    Loader
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading'
    })
  },
  beforeCreate() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('locale');
    }
    Vue.http.headers.common['X-Locale'] = this.$i18n.locale;
  },
  mounted() {
    let script = document.createElement('script');
    switch (this.$i18n.locale) {
      case 'ru':
        script.src = 'https://code.jivosite.com/widget/RVLGonNe05';
        break;
      case 'kz':
        script.src = 'https://code.jivosite.com/widget/pq5dJrkw36';
        break;
    }
    script.async = true;
    document.body.appendChild(script);
  }
}
</script>

<style lang="css">
html,
body {
  height: 100%;
}
.app {
  min-height: 100%;
}
.router-view {
  transition: all .5s ease;
}
.router-enter, .router-leave {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
</style>