<template>
<div class="view-home">
	<div class="container">
		<div class="row mt-5">
      <div class="col-lg-12">
        <div class="text-center welcome-text">
          <h1>{{ $t('check-school.title')}}</h1>
          <p>{{ $t('check-school.description')}}</p>
        </div>
        <div class="px-4 col-lg-5 col-md-12 mx-auto">
            <div class="welcome-text-desc"
                v-html="$t('check-school.full-desc')">
            </div>
          <form class="signin-form form view-login-form pb-5">
            <form-group :field="$v.school.region_id" :label="$t('references.region-label')" name="region_id">
              <el-select
                  v-model="school.region_id"
                  @change="getDistricts"
                  :loading="regionsLoading"
                  :placeholder="$t('references.region-placeholder')"
                  filterable>
                <el-option
                    v-for="item in regions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </form-group>

            <form-group :field="$v.school.district_id" :label="$t('references.district-label')" name="district_id">
              <el-select
                  v-model="school.district_id"
                  @change="getLocalities"
                  :loading="districtsLoading"
                  :placeholder="$t('references.district-placeholder')"
                  filterable>
                <el-option
                    v-for="item in districts"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </form-group>

            <form-group :field="$v.school.locality_id" :label="$t('references.locality-label')" name="locality_id">
              <el-select
                  v-model="school.locality_id"
                  @change="getSchools"
                  :loading="schoolsLoading"
                  :placeholder="$t('references.locality-placeholder')"
                  filterable>
                <el-option
                    v-for="item in localities"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </form-group>
            <form-group :field="$v.school.school_id" :label="$t('references.school-label')" name="school_id">
              <el-select
                  v-model="school.school_id"
                  :no-match-text="$t('references.schools-not-found')"
                  :no-data-text="$t('references.schools-not-found')"
                  :placeholder="$t('references.school-placeholder')"
                  filterable>
                <el-option
                    v-for="item in schools"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </form-group>
            <div class="col-12 text-center">
              <el-button @click="check" type="primary">{{ $t('check-school.check-button') }}</el-button>
            </div>
          </form>
        </div>
      </div>
		</div>
	</div>
  <b-modal id="school-modal" hide-footer hide-header>
    <div id="sms-wrapper">
      <div id="dialog">
        <button @click="$bvModal.hide('school-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="modal-img" :class="{'success-img': !notFound, 'error-img': notFound}"></div>
        <h3 v-if="!notFound">{{ $t('check-school.school-found-title') }}</h3>
        <h3 v-if="notFound">{{ $t('check-school.school-not-found-title') }}</h3>
      </div>
    </div>
  </b-modal>
</div>
</template>
<script>
import {minValue, required} from "vuelidate/lib/validators";

export default {
	data(){
	  return {
	    notFound: false,
      localities: [],
      districts: [],
      regions: [],
      schools: [],
      regionsLoading: false,
      districtsLoading: false,
      localitiesLoading: false,
      schoolsLoading: false,
      school: {
        region_id: '',
        district_id: '',
        locality_id: '',
        school_id: '',
      },
    }
  },
  validations: {
    school: {
      region_id: { required },
      district_id: { required },
      locality_id: { required },
      school_id: { required },
    }
  },
  methods: {
    getDistricts(id){
      this.$v.school.region_id.$touch();
      this.districtsLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.districtsLoading = false;
            if (res.body.data) {
              this.districts = res.body.data;
              this.school.district_id = '';
            }
          }).catch((e) => {
        this.districtsLoading = false;
      })
    },
    getLocalities(id){
      this.$v.school.district_id.$touch();
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
        .then((res) => {
          this.localitiesLoading = false;
          if (res.body.data) {
            this.localities = res.body.data;
            this.school.locality_id = '';
          }
        }).catch((e) => {
          this.localitiesLoading = false;
        })
    },
    getSchools(id){
      this.$v.school.locality_id.$touch();
      this.schoolsLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
        .then((res) => {
          this.schoolsLoading = false;
          if (res.body.data) {
            if (res.body.data){
              if (res.body.data.length){
                this.notFound = false;
              } else {
                this.notFound = true;
                this.$bvModal.show('school-modal');
              }
            }
            this.schools = res.body.data;
            this.school.school_id = '';
          }
        }).catch((e) => {
          this.schoolsLoading = false;
        })
    },
    check() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.notFound = false;
      this.$bvModal.show('school-modal');
    }
  },
  mounted() {
    this.regionsLoading = true;
    this.$http.get(window.API_ROOT + '/api/references/regions')
      .then((res) => {
        if (res.body.data) {
          this.regionsLoading = false;
          this.regions = res.body.data;
        }
      }).catch((e) => {
        this.regionsLoading = false;
        console.log(e);
      })
  }
}
</script>

<style>
#sms-wrapper #dialog img{
  position: initial;
}
#sms-wrapper #dialog h3{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}
.modal-img{
  width: 96px;
  height: 96px;
  border-radius: 96px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto 20px;
}
.success-img{
  background-color: #F4FBEA;
  background-image: url("/images/profile/success.svg");
  background-size: 50px;
}
.error-img{
  background-color: #fbeae9;
  background-image: url("/images/profile/error.svg");
  background-size: 36px;
}
</style>