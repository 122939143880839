<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.schools-list')"></ProfileNavbar>
    <router-link
        to="/schools-list"
        type="button"
        class="btn btn-light btn-back mb-3"
    >
      <img width="6" class="mr-3" src="/images/vector-back-grey.png"/>
      {{ $t('school.back-btn') }}
    </router-link>
    <div class="clearfix">
      <Loader v-if="loader"/>
      <div class="row" v-else>
        <div class="col-md-12">
          <div class="school-desc w-100">
            <div class="school-desc-block">
              <div class="desc-left-part">
                <div class="school-name-block mb-4">
                  <div class="school-name pt-3 pb-3 pl-2">
                    <img
                        width="17"
                        class="mr-1"
                        src="/images/archive.png"
                        alt=""
                    />
                    <span v-html="school[`name_${locale}`]"></span>
                  </div>
                </div>
                <div class="info-block pb-4">
                  <img
                      width="14"
                      class="mr-2"
                      src="/images/director.png"
                      alt=""
                  />
                  <div class="info">
                    <div class="info-title-text mb-1">
                      {{ school.director }}
                    </div>
                    <div class="info-text">
                      {{ $t('school.contact-person-position') }}
                    </div>
                  </div>
                </div>
                <div class="info-row">
                  <div class="info-column mr-4">
                    <div
                        class="info-block pb-3"
                        v-for="place in school.free_places"
                        :key="
												place.class.toString() + place.locale.toString()
											"
                        v-show="place.class === 7"
                    >
                      <img
                          width="14"
                          class="mr-2"
                          src="/images/folder.png"
                          alt=""
                      />
                      <div class="info">
                        <div class="info-title-text mb-1">
                          {{
                            $t('school.free-places', {
                              count: place.number_of_students
                            })
                          }}
                        </div>
                        <div class="info-text">
                          {{
                            $t(`school.free-places-${place.locale}`, {
                              class: place.class
                            })
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-column">
                    <div class="info-block pb-3">
                      <img
                          width="14"
                          class="mr-2"
                          src="/images/icon-chatting.png"
                          alt=""
                      />
                      <div class="info">
                        <div class="info-title-text mb-1">
                          {{
                            school.address[`city_${locale}`] + ', ' + school.address[`address_${locale}`]
                          }}
                        </div>
                        <div class="info-text">
                          {{ $t('school.address-title') }}
                        </div>
                      </div>
                    </div>
                    <div class="info-block pb-3">
                      <img
                          width="14"
                          class="mr-2"
                          src="/images/icon-chatting.png"
                          alt=""
                      />
                      <div class="info">
                        <div class="info-title-text mb-1">
                          {{ school.phone }}
                        </div>
                        <div class="info-text">
                          {{ $t('school.phone-title') }}
                        </div>
                      </div>
                    </div>
                    <div class="info-block pb-3">
                      <img
                          width="14"
                          class="mr-2"
                          src="/images/icon-web.png"
                          alt=""
                      />
                      <div class="info">
                        <div class="info-title-text mb-1">
                          {{ school.email }}
                        </div>
                        <div class="info-text">
                          {{ $t('school.email-title') }}
                        </div>
                      </div>
                    </div>
                    <div class="info-block pb-3">
                      <img
                          width="14"
                          class="mr-2"
                          src="/images/folder.png"
                          alt=""
                      />
                      <div class="info">
                        <a class="info-title-text mb-1" :href="$t(school.site)" target="_blank" style="color:#303030">
                          {{ school.site }}
                        </a>
                        <div class="info-text">
                          {{ $t('school.website-title') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="add-info-block pb-3">
                  <div class="pr-4 add-info-text">
                    {{
                      school.internat
                          ? $t('school.internat-type-yes')
                          : $t('school.internat-type-no')
                    }}
                  </div>
                  <div class="add-info-text">
                    {{ $t('school.gender-type', {type: school[`gender_${locale}`]}) }}
                  </div>
                </div>
                <div class="btns-block">
                  <el-button
                      class="btn btn-primary"
                      @click="showApplicationForm"
                  >
                    {{ $t('school.application') }}
                  </el-button>
                  <a
                      :href="'/' + school.school_passport"
                      download
                      type="button"
                      class="btn btn-primary d-none"
                  >
                    {{ $t('school.download-passport') }}
                  </a>
                </div>
              </div>
              <div class="desc-right-part">
                <div class="school-name-mob pt-3 pb-3 pl-2">
                  <img
                      width="17"
                      class="mr-1"
                      :src="school.main_photo"
                      alt=""
                  />
                  {{ school.name }}
                </div>
                <img :src="school.main_photo" alt=""/>
              </div>
            </div>
            <div class="school-desc-title pt-4 pb-3">
              {{ $t('school.desc-title') }}
            </div>
            <p class="school-desc-text" v-html="school[`description_${locale}`]"></p>
            <div class="school-desc-title pt-3 pb-5 text-center">
              {{ $t('school.galery') }}
            </div>
            <div class="slider-block">
              <vueper-slides
                  class="no-shadow"
                  :initSlide="2"
                  :visible-slides="3"
                  :slide-ratio="1 / 3"
                  :arrows="true"
                  :arrows-outside="true"
                  :gap="3"
                  :bullets="false"
                  :infinite="true"
                  :breakpoints="{ 690: { visibleSlides: 1, slideMultiple: 1 } }"
                  :dragging-distance="70"
              >
                <template v-slot:arrow-left>
                  <img width="12" src="/images/slider-prev.svg"/>
                </template>

                <template v-slot:arrow-right>
                  <img width="12" src="/images/slider-next.svg"/>
                </template>
                <vueper-slide
                    v-for="(slide, i) in JSON.parse(school.slider)"
                    :key="i"
                    :image="`${school.url}/${slide}`"
                />
              </vueper-slides>
            </div>
            <div class="add-info-text pb-3 d-none">
              {{ $t('school.social-network') }}
            </div>
            <div class="social-network-block d-none">
              <p>
                {{ school.social_media_links }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="application-modal"
        hide-footer
        hide-header
        size="lg"
        class="p-0"
    >
      <div id="application-wrapper">
        <div id="dialog">
          <button
              @click="$bvModal.hide('application-modal')"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true"></span>
          </button>
          <div class="row">
            <div class="col-lg-6 col-md-12 main-info">
              <h4 class="mb-3 text-left">
                {{ $t('school.send-application') }}
              </h4>
              <div class="info-text text-left">
                {{ $t('school.requered-fields') }}
              </div>
              <form-group
                  :field="$v.request_data.last_name"
                  :label="
									$t('signup.last-name-label') + $t('references.required-field')
								"
                  name="last_name"
              >
                <el-input
                    :placeholder="$t('signup.last-name-placeholder')"
                    v-model="request_data.last_name"
                    :disabled="true"
                    @change="$v.request_data.last_name.$touch()"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.first_name"
                  :label="
									$t('signup.first-name-label') +
										$t('references.required-field')
								"
                  name="first_name"
              >
                <el-input
                    :placeholder="$t('signup.first-name-placeholder')"
                    v-model="request_data.first_name"
                    :disabled="true"
                    @change="$v.request_data.first_name.$touch()"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.patronymic"
                  :label="$t('signup.patronymic-label')"
                  name="patronymic"
              >
                <el-input
                    :placeholder="$t('signup.patronymic-placeholder')"
                    v-model="request_data.patronymic"
                    :disabled="true"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.birth_date"
                  :label="$t('signup.birth-date-label')"
                  name="birth_date"
              >
                <el-date-picker
                    style="width: 100%;"
                    v-model="request_data.birth_date"
                    type="date"
                    placeholder="Дата"
                    format="yyyy-MM-dd"
                    default-value="2007-01-01"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    :disabled="true"
                    @change="$v.request_data.birth_date.$touch()"
                >
                </el-date-picker>
              </form-group>
              <form-group
                  :field="$v.request_data.sex"
                  :label="$t('references.gender')"
                  name="gender"
              >
                <el-select
                    :placeholder="$t('references.gender')"
                    :disabled="true"
                    v-model="request_data.sex"
                >
                  <el-option
                      v-for="item in genders"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </form-group>
              <div class="form-group">
                <label
                    :class="{ 'text-danger': !$v.request_data.email.email }"
                    class="signin-label"
                    for="email"
                >{{ $t('references.email-label') }}</label
                >
                <el-input
                    id="email"
                    :placeholder="$t('references.email-placeholder')"
                    v-model="request_data.email"
                    @change="$v.request_data.email.$touch()"
                    :disabled="true"
                    clearable
                >
                </el-input>
                <p
                    v-if="!$v.request_data.email.email"
                    class="help-block text-danger"
                >
                  {{ $t('form-group.email-required') }}
                </p>
              </div>
              <form-group
                  :field="$v.request_data.region_id"
                  :label="
									$t('references.region-label') +
										$t('references.required-field')
								"
                  name="region_id"
              >
                <el-select
                    :placeholder="$t('references.region-placeholder')"
                    v-model="request_data.region_id"
                    :loading="regionsLoading"
                    @change="getCities"
                    filterable
                    :disabled="true"
                >
                  <el-option
                      v-for="item in regions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </form-group>
              <form-group
                  :field="$v.request_data.city_id"
                  :label="
									$t('references.district-label') +
										$t('references.required-field')
								"
                  name="city_id"
              >
                <el-select
                    :placeholder="$t('references.district-placeholder')"
                    v-model="request_data.city_id"
                    :loading="citiesLoading"
                    @change="getLocalities"
                    filterable
                    :disabled="true"
                >
                  <el-option
                      v-for="item in cities"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </form-group>
              <form-group
                  :field="$v.request_data.locality_id"
                  :label="
									$t('references.locality-label') +
										$t('references.required-field')
								"
                  name="locality_id"
              >
                <el-select
                    v-model="request_data.locality_id"
                    :placeholder="$t('references.locality-placeholder')"
                    :loading="localitiesLoading"
                    filterable
                    :disabled="true"
                >
                  <el-option
                      v-for="item in localities"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </form-group>
            </div>
            <div class="col-lg-6 col-md-12">
              <h4 class="mb-3 text-left">{{ $t('school.education') }}</h4>
              <form-group
                  :field="$v.request_data.total"
                  :label="
									$t('profile.test-result') + $t('references.required-field')
								"
                  name="total"
              >
                <el-input
                    :placeholder="$t('profile.test-result')"
                    v-model="request_data.total"
                    @change="$v.request_data.total.$touch()"
                    :disabled="true"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.class_for_admission"
                  :label="
									$t('references.class-for-submition') + $t('references.required-field')
								"
                  name="class"
              >
                <el-select
                    :placeholder="$t('references.class-for-submition')"
                    v-model="request_data.class_for_admission"
                >
                  <el-option
                      v-for="item in classes"
                      :key="item.id"
                      :label="item.name + $t('signup.class')"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </form-group>
              <form-group
                  :field="$v.request_data.instruction_language"
                  :label="
									$t('references.education-language-label') +
										$t('references.required-field')
								"
                  name="instruction_language"
              >
                <el-select
                    :placeholder="$t('references.education-language-placeholder')"
                    v-model="request_data.instruction_language"
                >
                  <el-option
                      v-for="item in languages"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </form-group>
              <h4 class="mb-3 text-left">{{ $t('school.parent') }}</h4>
              <form-group
                  :field="$v.request_data.parent_last_name"
                  :label="
									$t('signup.last-name-label') + $t('references.required-field')
								"
                  name="last_name"
              >
                <el-input
                    :placeholder="$t('signup.last-name-placeholder')"
                    v-model="request_data.parent_last_name"
                    @change="$v.request_data.parent_last_name.$touch()"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.parent_first_name"
                  :label="
									$t('signup.first-name-label') +
										$t('references.required-field')
								"
                  name="first_name"
              >
                <el-input
                    :placeholder="$t('signup.first-name-placeholder')"
                    v-model="request_data.parent_first_name"
                    @change="$v.request_data.parent_first_name.$touch()"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.parent_patronymic"
                  :label="$t('signup.patronymic-label')"
                  name="patronymic"
              >
                <el-input
                    :placeholder="$t('signup.patronymic-placeholder')"
                    v-model="request_data.parent_patronymic"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.parent_phone"
                  :label="
									$t('references.phone-label') + $t('references.required-field')
								"
                  name="phone"
              >
                <el-input
                    :placeholder="$t('references.phone-placeholder')"
                    v-model="request_data.parent_phone"
                    v-mask="'+7 (###) ### ##-##'"
                    @change="$v.request_data.parent_phone.$touch()"
                    clearable
                >
                </el-input>
              </form-group>
              <form-group
                  :field="$v.request_data.parent_additional_phone"
                  :label="$t('references.additional-phone-label')"
                  name="additional-phone"
              >
                <el-input
                    :placeholder="$t('references.additional-phone-placeholder')"
                    v-model.lazy="request_data.parent_additional_phone"
                    v-mask="'+7 (###) ### ##-##'"
                    clearable
                >
                </el-input>
              </form-group>
              <div class="form-group">
                <label class="signin-label">{{
                    $t('references.email-label')
                  }}</label>
                <el-input
                    type="email"
                    :placeholder="$t('references.email-placeholder')"
                    v-model.lazy="request_data.parent_email"
                ></el-input>
              </div>
            </div>
            <div class="form-group w-100 text-center">
              <div class="clearfix">
                <el-checkbox v-model="accept_personal">{{
                    $t('signup.finish-accept-personal')
                  }}
                </el-checkbox>
              </div>
            </div>
            <div class="w-100 text-center">
              <el-button
                  class="btn btn-primary"
                  :disabled="!accept_personal"
                  :loading="requestBtnLoading"
                  @click="sendRequestHandler"
              >{{ $t('school.send-application-btn') }}
              </el-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="no-places-modal" hide-footer hide-header class="p-0">
      <div id="application-wrapper">
        <div id="dialog">
          <button
              @click="$bvModal.hide('no-places-modal')"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true"></span>
          </button>

          <div>
            <img style="position: static" src="/images/icon-warn.svg" alt=""/>
            <div class="no-places-modal-title">
              {{ errorMessage }}
            </div>
            <div class="no-places-modal-text">

            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import ProfileNavbar from '@/components/ProfileNavbar'
import {VueperSlides, VueperSlide} from 'vueperslides'
import VueMask from 'v-mask'
import 'vueperslides/dist/vueperslides.css'
import {required, email} from 'vuelidate/lib/validators'
import Loader from '@/components/Loader'

export default {
  name: 'Settings',
  components: {
    ProfileNavbar,
    VueperSlides,
    VueperSlide,
    Loader
  },
  data() {
    return {
      schoolEnrollRequestAvailable: '',
      errorMessage: '',
      isOpen: false,
      user: {},
      classes: [
        {
          id: '7',
          name: '7'
        },
      ],
      genders: [
        {
          id: 1,
          name: 'Мужской'
        },
        {
          id: 0,
          name: 'Женский'
        }
      ],
      languages: [
        {
          id: 'ru',
          name: this.$t('references.language-ru')
        },
        {
          id: 'kz',
          name: this.$t('references.language-kz')
        }
      ],
      slides: [],
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date()
          startDate.setMonth(11, 31)
          startDate.setFullYear(startDate.getFullYear() - 15)
          let endDate = new Date()
          endDate.setMonth(11, 31)
          endDate.setFullYear(endDate.getFullYear() - 9)
          return time.getTime() < startDate || time.getTime() >= endDate
        }
      },
      iinCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      regions: [],
      cities: [],
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      request_data: {
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        locality_id: '',
        region_id: '',
        city_id: '',
        email: '',
        sex: '',
        total: '',
        class_for_admission: '',
        parent_last_name: '',
        parent_first_name: '',
        parent_patronymic: '',
        parent_phone: '',
        parent_additional_phone: '',
        parent_email: '',
        instruction_language: ''
      },
      accept_personal: false,
      school: null,
      loader: true,
      requestBtnLoading: false,
      banToRequest: false,
      noPlaces: false,
      locale: localStorage.getItem('locale')
    }
  },
  validations: {
    request_data: {
      email: {email},
      last_name: {required},
      first_name: {required},
      birth_date: {required},
      region_id: {required},
      city_id: {required},
      locality_id: {required},
      sex: {required},
      total: {required},
      class_for_admission: {required},
      parent_last_name: {required},
      parent_first_name: {required},
      parent_phone: {required},
      instruction_language: {required}
    }
  },
  methods: {
    getDataFromIin(val) {
      if (val.length > 11) {
        this.$http
            .post(window.API_ROOT + '/api/check-iin', {
              iin: val
            })
            .then(res => {
              if (res.body.status == 'error') {
                this.iinCorrect = false
                console.log(res)
                this.errorMessage = res.body.error[0]
                this.$bvModal.show('error-modal')
              }
              if (res.body.status == 'success') {
                this.iinCorrect = true
              }
            })
            .catch(e => {
              Vue.toastr({
                message: this.$t('error'),
                description: e.message,
                type: 'error'
              })
            })
      }
    },
    async getSchoolInfo() {
      try {
        const school_id = this.$route.params.id
        const {id} = this.user
        const res = await this.$http.get(`${API_ROOT}/api/school-info/${school_id}/${id}`)
        this.school = res.body.data
        let freePlacesCount = 0
        const dateNow = Date.now()
        this.banToRequest = this.school.is_is_submitted


        this.school.free_places.map(item => {
          freePlacesCount = freePlacesCount + item.number_of_students
        })
        if (freePlacesCount >= 1) {
          this.noPlaces = false
        } else {
          this.noPlaces = true
        }
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: `${error}`,
          type: 'error'
        })
        this.$router.push('/schools-list')
      } finally {
        this.loader = false
      }
    },
    getRegions() {
      this.regionsLoading = true
      this.$http
          .get(window.API_ROOT + '/api/references/regions')
          .then(res => {
            if (res.body.data) {
              this.regionsLoading = false
              this.regions = res.body.data
            }
          })
          .catch(e => {
            this.regionsLoading = false
          })
    },
    getCities(id) {
      this.$v.request_data.region_id.$touch()
      this.citiesLoading = true
      this.$http
          .get(window.API_ROOT + '/api/references/districts/' + id)
          .then(res => {
            this.citiesLoading = false
            if (res.body.data) {
              this.cities = res.body.data
            }
          })
          .catch(e => {
            this.citiesLoading = false
          })
    },
    getLocalities(id) {
      this.$v.request_data.city_id.$touch()
      this.localitiesLoading = true
      this.$http
          .get(window.API_ROOT + '/api/references/localities/' + id)
          .then(res => {
            this.localitiesLoading = false
            if (res.body.data) {
              this.localities = res.body.data
            }
          })
          .catch(e => {
            this.localitiesLoading = false
          })
    },
    async sendRequestHandler() {
      this.$v.request_data.$touch()
      if (this.$v.request_data.$invalid) return
      try {
        this.requestBtnLoading = true
        const prepared_data = {
          ...this.request_data,
          user_id: this.user.id,
          school_id: +this.$route.params.id
        }
        const res = await this.$http.post(
            API_ROOT + '/api/user/submit-request',
            prepared_data
        )
        this.$bvModal.hide('application-modal')
        this.$router.go()

      } catch (error) {
        this.errorMessage = error.body.msg
        this.$bvModal.show('no-places-modal')
      } finally {
        this.requestBtnLoading = false
      }
    },
    async getUser() {
      if (!localStorage.getItem('user')) return
      const user = JSON.parse(localStorage.getItem('user'))
      this.user = user
      const {
        first_name,
        last_name,
        patronymic,
        region_id,
        city_id,
        locality_id,
        email,
        class: classAdmission,
        birth_date,
        sex,
        instruction_language,
        parent,
        second_stage
      } = user
      await this.getRegions()
      await this.getCities(region_id)
      await this.getLocalities(city_id)
      this.request_data = {
        ...this.request_data,
        first_name,
        last_name,
        patronymic,
        region_id,
        city_id,
        locality_id,
        email,
        birth_date,
        sex,
        total: second_stage,
        parent_first_name: parent.first_name,
        parent_last_name: parent.last_name,
        parent_patronymic: parent.patronymic,
        parent_phone: parent.phone,
        parent_email: parent.email,
        parent_additional_phone: parent.additional_phone
      }
    },
    showApplicationForm() {
      if (!this.schoolEnrollRequestAvailable) {
        return Vue.toastr({
          message: this.$t('error'),
          description: this.$t('school.wrong-day'),
          type: 'error'
        })
      }

      if (this.banToRequest || this.noPlaces) {
        return Vue.toastr({
          message: this.$t('error'),
          description: this.$t('school.no-places'),
          type: 'error'
        })
      }

      this.$bvModal.show('application-modal')

    },
  },
  beforeCreate() {
    Vue.use(VueMask)
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          const user = res.body.data;
          this.schoolEnrollRequestAvailable = user.school_enroll_request_available
          localStorage.setItem('user', JSON.stringify(user));
          if (user.second_stage_type !== 'ss_winner' || !user.school_enroll_available) {
            this.$router.push('/cabinet')
          }
        })
    this.getUser()
    this.getSchoolInfo()
  }
}
</script>

<style>
.form-group {
  text-align: left;
}

.signin-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.slider-block {
  padding: 40px 0 20px;
  width: 730px;
  margin: 0 auto;
}

.vueperslides__track-inner {
  align-items: center;
}

.vueperslides__parallax-wrapper {
  height: 320px !important;
}

.vueperslide {
  height: 200px !important;
  background-size: 134px 134px;
  background-repeat: no-repeat;
  background-size: cover;
}

.vueperslide--active {
  height: 320px !important;
}

.vueperslides__arrow {
  color: #303030;
  background-color: #ececec;
  padding: 11px 4px;
  border-radius: 2px;
}

.vueperslides__arrow > img {
  height: 12px;
}

.content .school-desc {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 40px;
  font-family: 'Roboto', sans-serif;
  color: #303030;
}

.btn-back {
  font-size: 13px;
  background-color: #e0e0e07a;
  padding: 8px 18px;
  color: #8b8b8b;
}

.school-name-block {
  border-bottom: 1px solid #e0e0e0;
  width: 470px;
}

.school-name,
.school-name-mob {
  color: #18a0fb;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #18a0fb;
  width: fit-content;
}

.info-block {
  display: flex;
  align-items: center;
}

.info-block img {
  width: 14px;
  height: 14px;
}

.info-title-text {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.info-text {
  color: #747474;
  font-size: 10px;
  line-height: 5px;
}

.add-info-text {
  font-size: 18px;
  font-weight: 500;
}

.desc-left-part {
  width: calc(100% - 360px);
}

.desc-right-part {
  width: 50%;
  text-align: center;
}

.desc-right-part img {
  width: auto;
  max-width: 360px;
  height: 360px;
  object-fit: cover;
}

.school-desc-title {
  font-size: 30px;
  line-height: 35px;
}

.school-desc-text {
  font-size: 18px;
  line-height: 21px;
}

.school-desc-block {
  display: flex;
  flex-direction: row;
}

.school-name-mob {
  display: none;
  margin: 0 auto;
  margin-bottom: 30px;
}

.info-row {
  display: flex;
  flex-direction: row;
}

.btns-block,
.add-info-block {
  display: flex;
  flex-direction: row;
}

.btns-block .btn:first-child {
  margin-right: 32px;
}

.social-network-block {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  width: fit-content;
}

.social-network-block p {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
}

.modal #dialog span {
  white-space: break-spaces;
}

#application-wrapper button.close {
  right: -1rem !important;
  left: auto !important;
}

#withdraw-wrapper button.close {
  right: 1rem !important;
  left: auto !important;
}

#withdraw-modal .modal-dialog {
  top: 25%;
}

.no-places-dialog {
  display: inline-block;
  position: relative;
  width: 100%;
}

.modal #no-places-dialog button.close {
  border-radius: 44px;
  width: 44px;
  height: 44px;
  background-color: #b5b5b5;
  background-image: url(/img/close.be409dec.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  opacity: 1;
  transition: all 0.2s ease;
}

.modal #no-places-dialog span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.modal #no-places-dialog span {
  white-space: break-spaces;
}

.modal #no-places-dialog div {
  position: relative;
  z-index: 1;
}

.modal #no-places-dialog button {
  padding: 10px 20px;
}

.no-places-modal-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  padding-bottom: 8px;
  padding-top: 20px;
}

.no-places-modal-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 24px;
}

@media screen and (max-width: 1200px) {
  .school-desc-block {
    flex-direction: column-reverse;
    align-items: center;
  }

  .school-name-block {
    display: none;
  }

  .school-name-mob {
    display: block;
  }

  .desc-right-part {
    width: 100%;
    margin-bottom: 30px;
  }

  .desc-right-part > img {
    display: none;
  }

  .desc-left-part {
    width: 100%;
  }

  @media screen and (max-width: 1200px) and (min-width: 690px) {
    .slider-block {
      width: 530px;
    }

    .vueperslide {
      background-size: 90px 90px;
    }

    .vueperslide--active {
      background-size: contain;
    }
  }
  @media screen and (max-width: 992px) {
    .el-checkbox {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }
  @media screen and (max-width: 690px) {
    .slider-block {
      width: 100%;
      padding: 0 50px;
    }
  }
  @media screen and (max-width: 550px) {
    .desc-right-part img {
      width: auto;
      max-width: 250px;
      height: auto;
    }
  }
  @media screen and (max-width: 500px) {
    .content .school-desc {
      padding: 20px;
    }

    .info-row {
      flex-direction: column;
    }

    .add-info-block {
      flex-direction: column;
      padding: 30px 0;
    }

    .add-info-block .add-info-text:first-child {
      padding-bottom: 20px;
    }

    .btns-block .btn:first-child {
      margin-right: 16px;
    }
  }
}
</style>
