<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('additional-infoSignup-by-iin.teachers') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 3</span><span class="total">{{
                $t('signup.out-of')
              }} 4</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">4</span><span class="total">{{ $t('signup.out-of') }} 3{{
                $t('signup.ending')
              }}</span>
            </div>
            <form action="#" class="signin-form row">
              <div class="col-md-12">
                <div class="row">

                  <div class="col-lg-4 col-md-12">

                    <form-group
                        :label="  $t('your_math_teacher') ">

                      <el-select v-model="teachersId.maths_id" :placeholder="$t('your_math_teacher')">
                        <el-option
                            v-for="item in teachers_maths"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>
                    <form-group
                        :label="  $t('your_English_teacher') ">

                      <el-select v-model="teachersId.english_lang_id" :placeholder="$t('your_English_teacher')">
                        <el-option
                            v-for="item in teachers_en"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>


                  </div>
                  <div class="col-lg-4 col-md-12">
                    <form-group
                        :label="  $t('your_science_teacher') ">

                      <el-select v-model="teachersId.natural_science_id" :placeholder="$t('your_science_teacher')">
                        <el-option
                            v-for="item in teachers_nature"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>
                    <form-group
                        :label="  $t('your_Kazakh_teacher') ">

                      <el-select v-model="teachersId.kazakh_lang_id" :placeholder="$t('your_Kazakh_teacher')">
                        <el-option
                            v-for="item in teachers_kk"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>

                  </div>
                  <div class="col-lg-4 col-md-12">
                    <form-group
                        :label="  $t('your_Russian_teacher') ">

                      <el-select v-model="teachersId.russian_lang_id" :placeholder="$t('your_Russian_teacher')">
                        <el-option
                            v-for="item in teachers_ru"
                            :key="item.id"
                            :label="item.last_name + ' ' + item.first_name"
                            :value="item.id">
                        </el-option>
                      </el-select>

                    </form-group>
                  </div>
                  <div style="margin-left: auto;margin-right: 15px" class="form-group text-center pt-3 btn-controls">
                    <router-link type="primary" style="margin-left: 0; margin-bottom: 1rem" class="btn-next"
                                 to="/signup-by-iin/phone-confirm" tag="el-button">{{ $t('school.back-btn') }}
                    </router-link>
                    <el-button style="color: white; background-color: #409EFF" type="button" class="btn-next"
                               @click="setTeacherId">{{ $t('signup.next-btn') }}
                    </el-button>
                  </div>
                </div>
              </div>

            </form>
<!--            <button @click.prevent="setTeacherId">888</button>-->
          </div>
        </div>
      </div>
    </div>
    <b-modal id="finish-modal" hide-footer hide-header>
      <div id="finish-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('finish-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <svg width="257" height="220" viewBox="0 0 257 220" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M229.734 162.75H214.766C214.067 162.75 213.5 163.317 213.5 164.016L213.5 178.984C213.5 179.683 214.067 180.25 214.766 180.25H229.734C230.433 180.25 231 179.683 231 178.984V164.016C231 163.317 230.433 162.75 229.734 162.75Z"
                fill="#597EF7"/>
            <path
                d="M55.6719 30.8125H28.8282C28.1292 30.8125 27.5625 31.3791 27.5625 32.0781L27.5625 58.9219C27.5625 59.6209 28.1291 60.1875 28.8281 60.1875H55.6719C56.3709 60.1875 56.9375 59.6209 56.9375 58.9219V32.0781C56.9375 31.3791 56.3709 30.8125 55.6719 30.8125Z"
                fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M119.907 179.384H145.381V159.369H119.907V179.384Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M160.815 186.239H105.32C103.058 186.239 101.226 184.406 101.226 182.145C101.226 179.884 103.058 178.051 105.32 178.051H160.815C163.077 178.051 164.909 179.884 164.909 182.145C164.909 184.406 163.077 186.239 160.815 186.239Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M218.23 160.669H45.8393C41.6073 160.669 38.1768 157.239 38.1768 153.007V47.9389C38.1768 43.7069 41.6073 40.2764 45.8393 40.2764H218.23C222.462 40.2764 225.892 43.7069 225.892 47.9389V153.007C225.892 157.239 222.462 160.669 218.23 160.669Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M185.707 70.3541H79.8643V63.0547C79.8643 61.4713 81.1532 60.1875 82.743 60.1875H182.828C184.418 60.1875 185.707 61.4713 185.707 63.0547V70.3541Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M87.2862 64.961C87.2862 65.9548 86.4823 66.7604 85.4906 66.7604C84.4991 66.7604 83.6953 65.9548 83.6953 64.961C83.6953 63.9673 84.4991 63.1616 85.4906 63.1616C86.4823 63.1616 87.2862 63.9673 87.2862 64.961Z"
                  fill="#FF7946"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M93.9892 64.961C93.9892 65.9548 93.1854 66.7604 92.1938 66.7604C91.2022 66.7604 90.3984 65.9548 90.3984 64.961C90.3984 63.9673 91.2022 63.1616 92.1938 63.1616C93.1854 63.1616 93.9892 63.9673 93.9892 64.961Z"
                  fill="#FAAD14"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M100.693 64.961C100.693 65.9548 99.8895 66.7604 98.8979 66.7604C97.9063 66.7604 97.1025 65.9548 97.1025 64.961C97.1025 63.9673 97.9063 63.1616 98.8979 63.1616C99.8895 63.1616 100.693 63.9673 100.693 64.961Z"
                  fill="#7CB305"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M82.9637 140.72C81.1933 140.72 79.7578 139.438 79.7578 137.857V69.6802H185.606V137.857C185.606 139.438 184.17 140.72 182.4 140.72H82.9637Z"
                  fill="#E4EBF7"/>
            <path
                d="M177.335 74.9082H87.6886C86.9831 74.9082 86.4111 75.4802 86.4111 76.1857V133.514C86.4111 134.22 86.9831 134.792 87.6886 134.792H177.335C178.041 134.792 178.613 134.22 178.613 133.514V76.1857C178.613 75.4802 178.041 74.9082 177.335 74.9082Z"
                fill="white"/>
            <path
                d="M130.612 83.4629H97.3431C96.0307 83.4629 94.9668 84.5268 94.9668 85.8392C94.9668 87.1516 96.0307 88.2156 97.3431 88.2156H130.612C131.924 88.2156 132.988 87.1516 132.988 85.8392C132.988 84.5268 131.924 83.4629 130.612 83.4629Z"
                fill="#FF7946"/>
            <path
                d="M159.128 111.979H97.3431C96.0307 111.979 94.9668 113.043 94.9668 114.355C94.9668 115.668 96.0307 116.732 97.3431 116.732H159.128C160.44 116.732 161.504 115.668 161.504 114.355C161.504 113.043 160.44 111.979 159.128 111.979Z"
                fill="#ADC6FF"/>
            <path
                d="M149.622 121.484H97.3431C96.0307 121.484 94.9668 122.548 94.9668 123.861C94.9668 125.173 96.0307 126.237 97.3431 126.237H149.622C150.935 126.237 151.999 125.173 151.999 123.861C151.999 122.548 150.935 121.484 149.622 121.484Z"
                fill="#7CB305"/>
            <path
                d="M140.117 102.474H97.3431C96.0307 102.474 94.9668 103.538 94.9668 104.85C94.9668 106.162 96.0307 107.226 97.3431 107.226H140.117C141.43 107.226 142.493 106.162 142.493 104.85C142.493 103.538 141.43 102.474 140.117 102.474Z"
                fill="#FAAD14"/>
            <path
                d="M168.633 92.9683H97.3431C96.0307 92.9683 94.9668 94.0322 94.9668 95.3446C94.9668 96.657 96.0307 97.7209 97.3431 97.7209H168.633C169.945 97.7209 171.009 96.657 171.009 95.3446C171.009 94.0322 169.945 92.9683 168.633 92.9683Z"
                fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M43.9781 195.051H43.0893C41.8674 195.051 40.8672 194.054 40.8672 192.834V175.099C40.8672 173.879 41.8674 172.882 43.0893 172.882H43.9781C45.2 172.882 46.2002 173.879 46.2002 175.099V192.834C46.2002 194.054 45.2 195.051 43.9781 195.051Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M70.3858 195.714C70.3858 197.183 69.1921 198.374 67.7193 198.374H19.7251C18.2523 198.374 17.0586 197.183 17.0586 195.714C17.0586 194.245 18.2523 193.053 19.7251 193.053H67.7193C69.1921 193.053 70.3858 194.245 70.3858 195.714Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9519 200.208C35.2187 200.208 34.6191 199.609 34.6191 198.878V192.549C34.6191 191.818 35.2187 191.22 35.9519 191.22H51.1159C51.849 191.22 52.4491 191.818 52.4491 192.549V198.878C52.4491 199.609 51.849 200.208 51.1159 200.208H35.9519Z"
                  fill="#ADC6FF"/>
            <path d="M36.834 192.53V198.897" stroke="#597EF7" stroke-width="6" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M50.0127 192.53V198.897" stroke="#597EF7" stroke-width="6" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7227 183.15H68.7213V124.878H18.7227V183.15Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4981 183.653C17.2465 183.653 16.2227 182.631 16.2227 181.383V167.964C16.2227 166.716 17.2465 165.694 18.4981 165.694H68.9462C70.1973 165.694 71.2217 166.716 71.2217 167.964V181.383C71.2217 182.631 70.1973 183.653 68.9462 183.653H18.4981Z"
                  fill="#ADC6FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4981 141.727C17.2465 141.727 16.2227 140.705 16.2227 139.456V126.038C16.2227 124.79 17.2465 123.768 18.4981 123.768H68.9462C70.1973 123.768 71.2217 124.79 71.2217 126.038V139.456C71.2217 140.705 70.1973 141.727 68.9462 141.727H18.4981Z"
                  fill="#ADC6FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.5977 134.449C21.6555 134.449 20.8916 133.687 20.8916 132.747C20.8916 131.807 21.6555 131.044 22.5977 131.044C23.5404 131.044 24.3043 131.807 24.3043 132.747C24.3043 133.687 23.5404 134.449 22.5977 134.449Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M26.1504 132.747C26.1504 131.807 26.9143 131.044 27.857 131.044C28.7992 131.044 29.5636 131.807 29.5636 132.747C29.5636 133.687 28.7992 134.449 27.857 134.449C26.9143 134.449 26.1504 133.687 26.1504 132.747Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.1168 134.449C32.174 134.449 31.4102 133.687 31.4102 132.747C31.4102 131.807 32.174 131.044 33.1168 131.044C34.059 131.044 34.8229 131.807 34.8229 132.747C34.8229 133.687 34.059 134.449 33.1168 134.449Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M51.2275 134.449C50.9675 134.449 50.7568 134.239 50.7568 133.98V131.514C50.7568 131.255 50.9675 131.044 51.2275 131.044H67.3503C67.6103 131.044 67.8209 131.255 67.8209 131.514V133.98C67.8209 134.239 67.6103 134.449 67.3503 134.449H51.2275Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4981 162.69C17.2465 162.69 16.2227 161.668 16.2227 160.419V147.001C16.2227 145.753 17.2465 144.73 18.4981 144.73H68.9462C70.1973 144.73 71.2217 145.753 71.2217 147.001V160.419C71.2217 161.668 70.1973 162.69 68.9462 162.69H18.4981Z"
                  fill="#ADC6FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.5977 156.021C21.6555 156.021 20.8916 155.259 20.8916 154.318C20.8916 153.378 21.6555 152.616 22.5977 152.616C23.5404 152.616 24.3043 153.378 24.3043 154.318C24.3043 155.259 23.5404 156.021 22.5977 156.021Z"
                  fill="#FF7946"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.857 156.021C26.9143 156.021 26.1504 155.259 26.1504 154.318C26.1504 153.378 26.9143 152.616 27.857 152.616C28.7992 152.616 29.5636 153.378 29.5636 154.318C29.5636 155.259 28.7992 156.021 27.857 156.021Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.1168 156.021C32.174 156.021 31.4102 155.259 31.4102 154.318C31.4102 153.378 32.174 152.616 33.1168 152.616C34.059 152.616 34.8229 153.378 34.8229 154.318C34.8229 155.259 34.059 156.021 33.1168 156.021Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M67.2521 156.021H51.3257C51.0113 156.021 50.7568 155.767 50.7568 155.454V153.183C50.7568 152.87 51.0113 152.616 51.3257 152.616H67.2521C67.5665 152.616 67.8209 152.87 67.8209 153.183V155.454C67.8209 155.767 67.5665 156.021 67.2521 156.021Z"
                  fill="#FF7946"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M20.8916 174.799C20.8916 173.859 21.6555 173.097 22.5977 173.097C23.5404 173.097 24.3043 173.859 24.3043 174.799C24.3043 175.74 23.5404 176.502 22.5977 176.502C21.6555 176.502 20.8916 175.74 20.8916 174.799Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.857 176.502C26.9143 176.502 26.1504 175.74 26.1504 174.799C26.1504 173.859 26.9143 173.097 27.857 173.097C28.7992 173.097 29.5636 173.859 29.5636 174.799C29.5636 175.74 28.7992 176.502 27.857 176.502Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.1168 176.502C32.174 176.502 31.4102 175.74 31.4102 174.799C31.4102 173.859 32.174 173.097 33.1168 173.097C34.059 173.097 34.8229 173.859 34.8229 174.799C34.8229 175.74 34.059 176.502 33.1168 176.502Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M67.2521 176.502H51.3257C51.0113 176.502 50.7568 176.248 50.7568 175.935V173.664C50.7568 173.351 51.0113 173.097 51.3257 173.097H67.2521C67.5665 173.097 67.8209 173.351 67.8209 173.664V175.935C67.8209 176.248 67.5665 176.502 67.2521 176.502Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M210.25 7.26562C188.581 7.26562 171.016 24.8314 171.016 46.5C171.016 68.1685 188.581 85.7344 210.25 85.7344C231.919 85.7344 249.484 68.1685 249.484 46.5C249.484 24.8314 231.919 7.26562 210.25 7.26562Z"
                  fill="#399300"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M203.928 59.8274L201.451 57.3504L225.454 33.2597C226.275 32.4388 227.606 32.4388 228.427 33.2597L229.418 34.2504C230.238 35.0713 230.238 36.4021 229.418 37.223L206.901 59.8274C206.08 60.6483 204.749 60.6483 203.928 59.8274Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M209.373 57.3505L206.896 59.8275C206.075 60.6484 204.744 60.6484 203.923 59.8275L194.06 49.9641C193.239 49.1433 193.239 47.8125 194.06 46.9917L195.051 46.0009C195.872 45.18 197.202 45.18 198.023 46.0009L209.373 57.3505Z"
                  fill="white"/>
          </svg>
          <h3 class="text-success">{{ $t('signup.finish-success-text') }}</h3>
          <span class="description" v-html="$t('signup.finish-succes-status-new')"></span>
          <div class="text-center pt-3">
            <button class="btn btn-primary" @click="goHome">{{ $t('signup.finish-resume-btn') }}</button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {required, minLength} from "vuelidate/lib/validators"
import CodeInput from "vue-verification-code-input"
import * as API from "@/api";

export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      teachers_maths: [],
      teachers_ru: [],
      teachers_en: [],
      teachers_kk: [],
      teachers_nature: [],
      teachers: [],
      loading: false,
      teachersId: {
        maths_id: '',
        russian_lang_id: '',
        english_lang_id: '',
        kazakh_lang_id: '',
        natural_science_id: '',
      },


    }
  },
  validations: {
    user: {
      password: {required, minLength: minLength(8)},
      password_confirmation: {required, minLength: minLength(8)},
      teaching_language_id: {required},
      last_name: {required},
      first_name: {required},
      locality_id: {required},
      school_id: {required},
      subject_id: {required},
      region_id: {required},
      city: {required},
    },
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return (this.password !== this.password_confirmation)
      } else {
        return false
      }
    },
    passwordsFilled() {
      return (this.password !== '' && this.password_confirmation !== '')
    },
    passwordValidation() {
      let errors = []
      for (let condition of this.rules) {
        condition.active = condition.regex.test(this.password);
        // if (!condition.regex.test(this.password)) {
        errors.push({
          message: condition.message,
          active: condition.active
        });
        // }
      }
      let error = false;
      Object.entries(errors).forEach(entry => {
        const [key, value] = entry;
        if (value.active == false) {
          error = true;
        }
      });
      this.passwordValid = !error;
      return {valid: !error, errors}
    }
  },
  mounted() {

    let accountSessionData = JSON.parse(sessionStorage.getItem('account'))

    let schoolId = accountSessionData.school_id


    this.$http.get(window.API_ROOT + '/api/references/regions/')
        .then((res) => {
          if (res.body.data) {
            this.regions = res.body.data;
            this.regionsLoading = false;
            console.log(this.regions)
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });
    // this.user= JSON.parse(sessionStorage.getItem('account'));


    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/2`)
        .then((res) => {
          if (res.body) {
            this.teachers_kk = res.body;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/3`)
        .then((res) => {
          if (res.body) {
            this.teachers_maths = res.body;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/5`)
        .then((res) => {
          if (res.body) {
            this.teachers_en = res.body;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/1`)
        .then((res) => {
          if (res.body) {
            this.teachers_ru = res.body;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/4`)
        .then((res) => {
          if (res.body) {
            this.teachers_nature = res.body;
            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });
  },

  methods: {

    setTeacherId() {

      let accountSessionData = JSON.parse(sessionStorage.getItem('account')) ?? {}

      // this.teachers.push(this.teachers_maths.find(item => item.id === this.teachersId.maths_id))
      // this.teachers.push(this.teachers_en.find(item => item.id === this.teachersId.english_lang_id))
      // this.teachers.push(this.teachers_kk.find(item => item.id === this.teachersId.kazakh_lang_id))
      // this.teachers.push(this.teachers_ru.find(item => item.id === this.teachersId.russian_lang_id))
      // this.teachers.push(this.teachers_nature.find(item => item.id === this.teachersId.natural_science_id))

      this.teachers.push(this.teachersId.maths_id)
      this.teachers.push(this.teachersId.english_lang_id)
      this.teachers.push(this.teachersId.kazakh_lang_id)
      this.teachers.push(this.teachersId.russian_lang_id)
      this.teachers.push(this.teachersId.natural_science_id)

      this.teachers = this.teachers.filter( item => item !== '')

      accountSessionData.teachers = this.teachers

      sessionStorage.setItem('account', JSON.stringify(accountSessionData))

      this.teachers = []
      this.$router.push({path: '/signup-by-iin/additional-info'});
    },



    goHome() {
      this.$router.push({path: '/cabinet'});
    },
    createUser() {
      // localStorage.setItem('account', JSON.stringify(this.account))
      // localStorage.setItem('parent', JSON.stringify(this.parent))
      // localStorage.setItem('education', JSON.stringify(this.education))
      // if (this.account.email) {
      //   function validateEmail(email) {
      //     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //     return re.test(email);
      //   }
      //
      //   if (!validateEmail(this.account.email)) {
      //     Vue.toastr({
      //       message: this.$t('error'),
      //       description: this.$t('form-group.email-required'),
      //       type: 'error'
      //     });
      //     return;
      //   }
      // }
      // ;
      this.$v.user.$touch()
      if (this.$v.$invalid) return
      if (this.password != this.password_confirmation) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('signup.passwords-dont-match'),
          type: 'error'
        })
        return
      }
      this.loading = true;

      this.user.iin = JSON.parse(sessionStorage.getItem('iin'))
      let userDate = this.user.iin.split('')
      let genderUser = userDate[6]
      userDate.length = 6
      let userDateYear = userDate[0] + userDate[1]
      let userDateYearFull = +userDateYear < 22 ? +userDateYear + 2000 : +userDateYear + 1900
      let userDateMonth = userDate[2] + userDate[3]
      let userDateDay = userDate[4] + userDate[5]
      let genderUserId = genderUser === "5" ? '1' : '2'

      this.$http.post(window.API_ROOT + '/api/teacher', {

        'iin': JSON.parse(sessionStorage.getItem('iin')),
        'last_name': this.user.last_name,
        'first_name': this.user.first_name,
        'locality_id': this.user.locality_id + '',
        'school_id': this.user.school_id + '',
        'subject_id': this.user.subject_id + '',
        'teaching_language_id': this.user.teaching_language_id + '',
        'email': JSON.parse(sessionStorage.getItem('email')),
        'phone': JSON.parse(sessionStorage.getItem('phone')),
        'password': this.password,
        'password_confirmation': this.password_confirmation,
        'birth_date': userDateYearFull + '-' + userDateMonth + '-' + userDateDay,
        'gender': genderUserId,

      })
          .then((res) => {
            this.loading = false;
            localStorage.setItem('token', res.body.token)
            localStorage.setItem('user', JSON.stringify(res.body.user))
            localStorage.setItem('session_id', res.body.session_id)
            sessionStorage.removeItem('account');
            sessionStorage.removeItem('phone');
            sessionStorage.removeItem('education');
            sessionStorage.removeItem('phone-verified');
            sessionStorage.removeItem('iinValid');
            sessionStorage.removeItem('iinEmpty');
            this.$store.commit('FORGET_PASSWORD', this.password);
            this.$bvModal.show('finish-modal');

          }).catch((e) => {
        Vue.toastr({
          message: this.$t('error'),
          description: e.body.errors[0],
          type: 'error'
        })
        this.loading = false
      })
    },
    enforcePhoneFormat() {
      this.$v.parent.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.parent.phone = x[0];

    },

    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}

</script>
<style lang="css" scoped>
.el-select {
  margin-bottom: 0px;
}


.input_text {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 16px;
  height: 46px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}
</style>
