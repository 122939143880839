<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('signup.title') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 1</span><span class="total">{{ $t('signup.out-of') }} 4</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">4</span><span class="total">{{ $t('signup.out-of') }} 1{{ $t('signup.ending') }}</span>
            </div>
            <div class="signup-by-iin-main">
              <div class="iin-left">
            <div class="warning-wrapper">
              <div class="warning-img"></div>
              <div class="warning-message" v-html="$t('signup-by-iin.title-message')">

              </div>
            </div>
            <div class="intstruction-wrapper">
              <span class="instruction-title">{{ $t('signup-by-iin.instruction') }}</span>
              <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция по регистрации - 2023.docx.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
              <a v-if="$i18n.locale=='kz'" download href="/files/kz/Тіркелу_жөніндегі_нұсқаулық_2023_docx.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
              <button  @click="$bvModal.show('youtube-modal')" class="watch-instruction">{{ $t('signup-by-iin.see-instruction') }}</button>
            </div>
                                <form-group class="iin-input" :field="$v.account.iin" :label="$t('signup.iin-label') + $t('references.required-field')"  name="iin">
                                  <el-input
                                      clearable
                                      maxlength="12"
                                      :placeholder="$t('signup.iin-label')"
                                      v-model="account.iin"
                                      @input="getDataFromIin"
                                      @change="$v.account.iin.$touch()"
                                      show-word-limit>
                                  </el-input>
                                </form-group>
            <div class="button-iin-signup-group">
              <button class="button-iin-clear" @click="clearAll()" >{{ $t('additional-infoSignup-by-iin.retry') }}</button>
              <el-button type="button" class="button-iin-next" @click="phoneConfirm" :disabled="!iinValid">{{ $t('signup.next-btn') }}</el-button>

            </div>
            </div>
              <div class="iin-info">
                   <div v-if="iinEmpty" class="iin-empty-message">{{ $t('signup-by-iin.iin-search') }}</div>
                   <div v-else-if="!iinValid" class="iin-empty-message">{{  errorMessageIIn}}</div>
                <div class="iin-wrap-info" v-if="iinValid">
                  <div class="iin-search-title">{{ $t('signup-by-iin.user-found') }}:</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('signup.last-name-label') }}:</span>{{account.last_name}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('signup.first-name-label') }}:</span>{{account.first_name}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('signup.patronymic-label') }}:</span>{{account.patronymic}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('references.region-label') }}:</span>{{account.region_id}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('references.district-label') }}:</span>{{account.city_id}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('references.locality-label') }}:</span>{{account.locality_id}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('references.school-label') }}:</span>{{account.school}}</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{ $t('references.class-label') }}:</span>{{account.class}}</div>
                  <div v-if="account.language=='ru'" class="iin-search-item"><span class="iin-account-title">{{ $t('references.education-language-label') }}:</span>{{$t('references.language-ru')}}</div>
                  <div v-if="account.language=='kz'" class="iin-search-item"><span class="iin-account-title">{{ $t('references.education-language-label') }}:</span>{{$t('references.language-kz')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">

          <button @click="$bvModal.hide('youtube-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe  style="width: 100%; height: 500px;" v-if="$i18n.locale=='ru'"  src="https://www.youtube.com/embed/12DxeHCSlJw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='kz'"  src="https://www.youtube.com/embed/EJoTE-nbHN0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email} from "vuelidate/lib/validators"
import CodeInput from "vue-verification-code-input"

export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date();
          startDate.setMonth(11, 31);
          startDate.setFullYear( startDate.getFullYear() - 16 );

          let endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setFullYear( endDate.getFullYear() - 9 );

          return time.getTime() < startDate || time.getTime() >= endDate;
        }
      },
      errorMessageIIn:'',
      iinCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      schools:[],
      regions: [],
      cities: [],
      classes: [
        {
          id: 5,
        },
        {
          id: 6,
        }
      ],
      phone: '',
      iinValid:false,
      iinEmpty:true,
      sendingSms: true,
      schoolLoading:false,
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      invalidCode: false,
      canResend: false,
      phoneVerified: true,
      resendSec: 60,
      account: {
        phone:'',
        class: '',
        language:'',
        school:'',
        school_id:'',
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        locality_id: '',
        region_id: '',
        city_id: '',
      }
    }
  },
  validations: {
    account: {
      iin: { required, minLength: minLength(12) },

    }
  },
  mounted() {
    // this.routeSignupHandler()
    if(sessionStorage.getItem('iinValid')) {
      this.iinValid = JSON.parse(sessionStorage.getItem('iinValid'));
    }
    if(sessionStorage.getItem('account')) {
      this.account = JSON.parse(sessionStorage.getItem('account'));
    }
    if(sessionStorage.getItem('iinEmpty')) {
      this.iinEmpty = JSON.parse(sessionStorage.getItem('iinEmpty'));
    }
  },
  methods: {
    clearAll(){
      sessionStorage.removeItem('account');
      sessionStorage.removeItem('phone');
      sessionStorage.removeItem('education');
      sessionStorage.removeItem('phone-verified');
      sessionStorage.removeItem( 'iinEmpty')
      sessionStorage.removeItem( 'iinValid')
      this.$router.go();
    },
    getDataFromIin(val){
      if (val.length > 11){
        this.iinEmpty=false
        this.$http.get(window.API_ROOT + '/api/user-by-iin?iin='+val)
            .then((res) => {
              this.account.iin=res.body.data.iin
              this.account.first_name=res.body.data.first_name;
              this.account.last_name=res.body.data.last_name;
              this.account.patronymic=res.body.data.patronymic;
              this.account.class=res.body.data.education.class;
              this.account.language=res.body.data.education.instruction_language;
              this.account.region_id=res.body.data.region.name
              this.account.city_id=res.body.data.city.name
              this.account.locality_id=res.body.data.locality.name
              this.account.school_id=res.body.data.education.school.id
              this.account.school=res.body.data.education.school.name
              this.iinValid=true
              sessionStorage.setItem('iinValid', JSON.stringify(this.iinValid));
              sessionStorage.setItem('iinEmpty', JSON.stringify(this.iinEmpty));
              sessionStorage.setItem('account', JSON.stringify(this.account));
              localStorage.setItem('school', JSON.stringify(this.account.school))
            })
            .catch((e) => {
              sessionStorage.removeItem('iinValid');
              sessionStorage.removeItem('iinEmpty');
              sessionStorage.removeItem('account');
              this.errorMessageIIn=e.body.message;
          Vue.toastr({
            message: this.$t('warning'),
            description: e.body.message,
            type: 'error'
          })
              this.iinValid=false
          this.account={
            phone:'',
            class: '',
            language:'',
            school:'',
            iin: val,
            last_name: '',
            first_name: '',
            patronymic: '',
            locality_id: '',
            region_id: '',
            city_id: '',
          }
        })
      }
    },
    phoneConfirm(){
      this.getDataFromIin(this.account.iin)
      this.$v.$touch()
      if (this.$v.$invalid) return
      sessionStorage.setItem('account', JSON.stringify(this.account));
      this.$router.push({ path: '/signup-by-iin/phone-confirm' });
    },



    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year',new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}
</script>
<style lang="css" scoped>
.modal-dialog{
  max-width: max-content;
  width: max-content;
}

@media screen and (max-width: 991px) {
  .signup-by-iin-main{
    flex-direction: column;
  }
  .iin-left{
    width: 100%;
  }
  .iin-info{
    width: 100%;
  }
  .iin-wrap-info{
    height: 482px;
  }
  .button-iin-signup-group{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  }
@media screen and (max-width: 575px) {
  .iin-input{
    max-width: unset;
  }
  .download-instruction{
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
.watch-instruction{
  width: 100%;
  margin-left: 0;
}
  .button-iin-signup-group{
    flex-direction: column;
  }
  .button-iin-next{
    margin-right: 0;
    display: block;
    width: 100%;
  }
  .button-iin-clear{
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }
  .iin-search-item{
    font-size: 14px;
  }
  .iin-info{
    padding: 10px;
  }
  .warning-message{
    width: 64%;
  }
}
</style>

