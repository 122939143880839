<template>
    <div class="tech-form">
        <p class="text">{{$t('signin-form.tech-undertitle')}}</p>
        <p class="time">{{min}}:{{sec}}</p>
    </div>
</template>
<script>
export default ({
    props: ['min', 'sec'],
})
</script>

<style>
    .tech-form {
        margin-top: 45px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .tech-form .text {
        font-size: 18px;
    }
    .tech-form .time {
        color: #05458C;
        font-weight: bold;
        font-size: 64px;
        line-height: 75px;
        margin-top: 50px;
    }
</style>