<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('signup.title') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 1</span><span class="total">{{
                $t('signup.out-of')
              }} 3</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">3</span><span class="total">{{ $t('signup.out-of') }} 1{{
                $t('signup.ending')
              }}</span>
            </div>
            <div class="signup-by-iin-main">
              <div class="iin-left">
                <div class="teachers-subjects-wrapper">
                  <p class="teachers-subjects-title">{{ $t('teachers_can_register.title') }}</p>
                  <p class="teachers-subjects-item" v-for="item in $t('teachers_can_register.list')" v-html="item"></p>
                </div>
                <div class="intstruction-wrapper">
                  <span class="instruction-title">{{ $t('signup-by-iin.instruction') }}</span>
                  <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция_по_регистрации_Учителей_Мын_бала.pdf"
                     class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
                  <a v-if="$i18n.locale=='kz'" download
                     href="/files/kz/Инструкция_по_регистрации_Учителей_Мын_бала_каз.pdf" class="download-instruction">{{
                      $t('signup-by-iin.download-instruction')
                    }}</a>
                  <button @click="$bvModal.show('youtube-modal')" class="watch-instruction">
                    {{ $t('signup-by-iin.see-instruction') }}
                  </button>
                </div>
                <form-group class="iin-input" :field="$v.teacher.iin"
                            :label="$t('signup.iin-label') + $t('references.required-field')" name="iin">
                  <el-input
                      clearable
                      maxlength="12"
                      :placeholder="$t('signup.iin-label')"
                      v-model="teacher.iin"
                      @input="getDataFromIin"
                      @change="$v.teacher.iin.$touch()"
                      show-word-limit>
                  </el-input>
                </form-group>
                <div class="button-iin-signup-group">
                  <button class="button-iin-clear" @click="clearAll()">{{
                      $t('additional-infoSignup-by-iin.retry')
                    }}
                  </button>
                  <el-button type="button" class="button-iin-next" @click="phoneConfirm" :disabled="!iinValidTeacher">
                    {{ $t('signup.next-btn') }}
                  </el-button>

                </div>
              </div>
              <div class="iin-info">
                <div v-if="iinEmptyTeacher" class="iin-empty-message">{{ $t('signup-by-iin.iin-search') }}</div>
                <div v-else-if="!iinValidTeacher" class="iin-empty-message">{{ errorMessageIIn }}</div>
                <div class="iin-wrap-info" v-if="iinValidTeacher">
                  <div class="iin-search-title">{{ $t('signup-by-iin.user-found') }}:</div>
                  <div class="iin-search-item"><span class="iin-account-title">{{
                      $t('signup.last-name-label')
                    }}:</span>{{ teacher.last_name }}
                  </div>
                  <div class="iin-search-item"><span class="iin-account-title">{{
                      $t('signup.first-name-label')
                    }}:</span>{{ teacher.first_name }}
                  </div>
                  <div class="iin-search-item"><span class="iin-account-title">{{
                      $t('signup.patronymic-label')
                    }}:</span>{{ teacher.patronymic }}
                  </div>
                  <div class="iin-search-item"><span class="iin-account-title">{{
                      $t('references.school-label')
                    }}:</span>{{ teacher.school.name }}
                  </div>
                  <div class="iin-search-item"><span class="iin-account-title">{{
                      $t('references.education-language-label')
                    }}:</span>{{ (this.langs.filter( item => +item.id == +teacher.teaching_language.id ))[0].name }}
                  </div>
<!--                  <div v-if="teacher.language=='ru'" class="iin-search-item"><span class="iin-account-title">{{-->
<!--                      $t('references.education-language-label')-->
<!--                    }}:</span>{{ $t('references.language-ru') }}-->
<!--                  </div>-->
<!--                  <div v-if="teacher.language=='kz'" class="iin-search-item"><span class="iin-account-title">{{-->
<!--                      $t('references.education-language-label')-->
<!--                    }}:</span>{{ $t('references.language-kz') }}-->
<!--                  </div>-->
                </div>
              </div>
              <div class="intstruction-wrapper-mobile">
                <span class="instruction-title">{{ $t('signup-by-iin.instruction') }}</span>
                <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция_по_регистрации_Учителей_Мын_бала.pdf"
                   class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
                <a v-if="$i18n.locale=='kz'" download
                   href="/files/kz/Инструкция_по_регистрации_Учителей_Мын_бала_каз.pdf" class="download-instruction">{{
                    $t('signup-by-iin.download-instruction')
                  }}</a>
                <button @click="$bvModal.show('youtube-modal')" class="watch-instruction">
                  {{ $t('signup-by-iin.see-instruction') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">

          <button @click="$bvModal.hide('youtube-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe style="width: 100%; height: 500px;" v-if="$i18n.locale=='ru'"
                  src="https://www.youtube.com/embed/12DxeHCSlJw" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
          <iframe style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='kz'"
                  src="https://www.youtube.com/embed/EJoTE-nbHN0" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email} from "vuelidate/lib/validators"
import CodeInput from "vue-verification-code-input"
import * as API from "@/api";

export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date();
          startDate.setMonth(11, 31);
          startDate.setFullYear(startDate.getFullYear() - 16);

          let endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setFullYear(endDate.getFullYear() - 9);

          return time.getTime() < startDate || time.getTime() >= endDate;
        }
      },
      errorMessageIIn: '',
      iinCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      schools: [],
      regions: [],
      cities: [],
      langs: [],
      phone: '',
      iinValidTeacher: false,
      iinEmptyTeacher: true,
      sendingSms: true,
      schoolLoading: false,
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      invalidCode: false,
      canResend: false,
      phoneVerified: true,
      resendSec: 60,
      teacher: {
        id: '',
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        gender: '',
        region: '',
        city: '',
        locality: '',
        school: '',
        subject: '',
        teaching_language: '',
        email: '',
        phone: '',
      }
    }
  },
  validations: {
    teacher: {
      iin: {required, minLength: minLength(12)},

    }
  },
  mounted() {
    // this.routeSignupHandler()
    if (sessionStorage.getItem('iinValidTeacher')) {
      this.iinValidTeacher = JSON.parse(sessionStorage.getItem('iinValidTeacher'));
    }
    if (sessionStorage.getItem('teacher')) {
      this.teacher = JSON.parse(sessionStorage.getItem('teacher'));
    }
    if (sessionStorage.getItem('iinEmptyTeacher')) {
      this.iinEmptyTeacher = JSON.parse(sessionStorage.getItem('iinEmptyTeacher'));
    }
    this.$http.get(`${API.Api}/api/references/teaching-languages`)
        .then(res => {
          this.langs = res.body.data
          console.log('this.langs', this.langs)
        })
  },
  methods: {
    clearAll() {
      sessionStorage.removeItem('teacher');
      sessionStorage.removeItem('phone');
      sessionStorage.removeItem('education');
      sessionStorage.removeItem('phone-verified');
      sessionStorage.removeItem('iinEmptyTeacher')
      sessionStorage.removeItem('iinValidTeacher')
      this.$router.go();
    },
    getDataFromIin(val) {
      if (val.length > 11) {
        this.iinEmptyTeacher = false
        this.$http.get(window.API_ROOT + '/api/teacher/find-by-iin?iin=' + val)
            .then((res) => {
              console.log(res)
              this.teacher.id = res.body.user.id
              this.teacher.iin = res.body.user.iin
              this.teacher.last_name = res.body.user.last_name
              this.teacher.first_name = res.body.user.first_name
              this.teacher.patronymic = res.body.user.patronymic
              this.teacher.birth_date = res.body.user.birth_date
              this.teacher.gender = res.body.user.gender
              this.teacher.region = res.body.user.region
              this.teacher.city = res.body.user.city
              this.teacher.locality = res.body.user.locality
              this.teacher.school = res.body.user.school
              this.teacher.subject = res.body.user.subject
              this.teacher.teaching_language = res.body.user.teaching_language
              this.teacher.email = res.body.user.email
              this.teacher.phone = res.body.user.phone
              // this.teacher.locality_id = res.body.user.locality_id
              // this.teacher.school_id = res.body.user.school_id
              // this.teacher.subject_id = res.body.user.subject_id
              // this.teacher.teaching_language_id = res.body.user.teaching_language_id
              // this.teacher.email = res.body.user.email
              // this.teacher.phone = res.body.user.phone


              console.log(this.teacher)
              this.iinValidTeacher = true
              sessionStorage.setItem('iinValidTeacher', JSON.stringify(this.iinValidTeacher));
              sessionStorage.setItem('iinEmptyTeacher', JSON.stringify(this.iinEmptyTeacher));
              sessionStorage.setItem('teacher', JSON.stringify(this.teacher));
              localStorage.setItem('schoolTeacher', JSON.stringify(this.teacher.school))
            })
            .catch((e) => {
              console.log(e)
              sessionStorage.removeItem('iinValidTeacher');
              sessionStorage.removeItem('iinEmptyTeacher');
              sessionStorage.removeItem('teacher');
              this.errorMessageIIn=e.body.message;
              Vue.toastr({
                message: this.$t('warning'),
                description: e.body.message,
                type: 'error'
              })
              this.iinValidTeacher = false
              this.teacher = {
                id: '',
                iin: val,
                last_name: '',
                first_name: '',
                patronymic: '',
                birth_date: '',
                gender: '',
                region: '',
                city: '',
                locality: '',
                school: '',
                subject: '',
                teaching_language: '',
                email: '',
                phone: '',
              }
            })
      }
    },
    phoneConfirm() {
      this.getDataFromIin(this.teacher.iin)
      this.$v.$touch()
      if (this.$v.$invalid) return
      sessionStorage.setItem('teacher', JSON.stringify(this.teacher));
      this.$router.push({path: '/signup-teacher/teacher-email-confirm'});
    },


    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}
</script>
<style lang="css" scoped>
.modal-dialog {
  max-width: max-content;
  width: max-content;
}

@media screen and (max-width: 991px) {
  .signup-by-iin-main {
    flex-direction: column;
  }

  .iin-left {
    width: 100%;
  }

  .iin-info {
    width: 100%;
  }

  .iin-wrap-info {
    height: 482px;
  }

  .button-iin-signup-group {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575px) {
  .iin-input {
    max-width: unset;
  }

  .download-instruction {
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .watch-instruction {
    width: 100%;
    margin-left: 0;
  }

  .button-iin-signup-group {
    flex-direction: column;
  }

  .button-iin-next {
    margin-right: 0;
    display: block;
    width: 100%;
  }

  .button-iin-clear {
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }

  .iin-search-item {
    font-size: 14px;
  }

  .iin-info {
    padding: 10px;
  }

  .warning-message {
    width: 64%;
  }
}
</style>

