<template>
  <div class="content profile-content position-relative">
    <ProfileNavbar :title="$t('sidebar.schools-list')"></ProfileNavbar>
    <div class="clearfix">
      <div class="">
        <div class="schools-list-filter p-0 mb-3">
          <form @submit.prevent="handeFilterUse" class="filter-items d-flex flex-wrap">
            <div class="filter-item-search">
              <div class="form-group has-search">
                <!-- <span class="fa fa-search form-control-feedback"></span> -->
                <el-input
                    :placeholder="$t('schools-list.search-placeholder')"
                    @keypress.enter.native="handeFilterUse"
                    v-model="filter.search"
                >
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <!-- <input type="text" class="form-control form-control-search" :placeholder="$t('schools-list.search-placeholder')" v-model="filter.search"> -->
              </div>
            </div>
            <div class="filter-radio-btns">
              <div class="filter-item">
                <div class="form-group">
                  <div class="custom-box">
                    <label class="form-check-label" for="allSchools">
                      {{ $t('schools-list.all-schools') }}
                      <input
                          @change="getSchoolsList()"
                          class="form-check-input"
                          type="radio"
                          name="filter"
                          id="allSchools"
                          value="1"
                          checked
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="filter-item">
                <div class="form-group">
                  <div class="custom-box">
                    <label class="form-check-label" for="applications">
                      {{ $t('schools-list.applications') }}
                      <input
                          @change="getApplicationsSchool()"
                          class="form-check-input"
                          type="radio"
                          name="filter"
                          id="applications"
                          value="2"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex w-100">
              <div class="filter-item">
                <form-group
                    :label="$t('references.region-label')"
                    name="region_id"
                >
                  <el-select
                      :placeholder="$t('references.region-placeholder')"
                      v-model="filter.region_id"
                      filterable
                  >
                    <el-option
                        v-for="item in regions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </form-group>
              </div>
              <div class="filter-item ml-3">
                <form-group
                    :label="$t('references.education-language-label')"
                    name="instruction_language"
                >
                  <el-select
                      :placeholder="
											$t('references.education-language-placeholder')
										"
                      v-model="filter.school_bias"
                  >
                    <el-option
                        v-for="item in languages"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </form-group>
              </div>
              <div class="filter-item ml-3">
                <div class="form-group">
                  <div class="pb-4">
                    <p class="p-0 mb-2 mt-1">{{ $t('schools-list.internat') }}</p>
                    <el-switch
                        v-model="filter.internat"
                        active-text="Вкл"
                        :active-value="1"
                        :inactive-value="null"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>
            </div>
            <div class="filter-btns d-flex w-100">
              <el-button
                  class="btn btn-filter-modal"
                  native-type="native-type"
                  :loading="filterBtnLoading"
              >{{ $t('profile.notifications.filter-btn-title') }}
              </el-button
              >
              <el-button
                  class="btn btn-filter-modal ml-2"
                  @click="clearFilter"
                  native-type="reset"
              >{{
                  $t('profile.notifications.filter-btn-clear-title')
                }}
              </el-button
              >
            </div>
          </form>
        </div>
        <Loader v-if="loader"/>
        <p class="text-center" v-else-if="notFoundMessage">
          {{ notFoundMessage }}
        </p>
        <div class="top-content pl-0 pr-0" v-else>
          <div class="shools-list-content">
            <div class="shools-list-desc">
              <div>
                <p>
                  {{ $t('schools-list.desc-1') }}
                </p>
                <p>
                  {{ $t('schools-list.desc-2') }}
                </p>
                <p>
                  {{ $t('schools-list.desc-3') }}
                </p>
                <div class="btn-wrap d-flex flex-lg-row flex-md-column ml-lg-0 mx-md-auto align-items-center">
                  <a v-if="locale=='ru'" href="/files/ru/ru-instruction.pdf" target="_blank" class="btn btn-light">
                    <img width="16" class="mr-2" src="/images/download-icon.svg" alt="">
                    <span class="download-instruction">{{ $t("schools-list.download") }}</span>
                  </a>
                  <a v-if="locale=='kz'" href="/files/kz/kz-instruction.pdf" target="_blank" class="btn btn-light">
                    <img width="16" class="mr-2" src="/images/download-icon.svg" alt="">
                    <span class="download-instruction">{{ $t("schools-list.download") }}</span>
                  </a>
                  <div class="btn-wrap-text ml-4 mr-4 mt-md-2">
                    {{ $t("schools-list.download-title") }}
                  </div>
                </div>
              </div>
              <div
                  class="d-flex align-items-center justify-content-center mb-4 mb-lg-0 ml-lg-3"
              >
                <img
                    class="shools-list-desc-img ml-md-0"
                    src="/images/1000-BALA-logo.svg"
                    alt=""
                />
              </div>
            </div>

            <div
                class="shools-list-item m-0 mt-3"
                v-for="school_item in schools_list"
                :key="school_item.school_id"
            >
              <div class="school-img justify-content-md-center">
                <img :src="school_item.main_photo" alt="school-item"/>
              </div>
              <div class="school-content">
                <div
                    class="school-text-block py-1 py-lg-3 px-3 d-flex flex-column"
                >
                  <div
                      class="school-text-title text-lg-left text-md-center pb-md-3 pb-lg-0"
                      v-html="school_item[`name_${locale}`]"
                  ></div>
                  <div
                      class="school-text"
                      v-html="school_item[`description_${locale}`]"
                  ></div>
                  <div class="school-info-block mt-auto">
                    <div class="school-info">
                      {{ school_item.city }}
                    </div>
                    <div class="school-info">
                      {{
                        $t('schools-list.school-ru-full', {
                          count: school_item.ru_branch
                        })
                      }}
                    </div>
                    <div class="school-info">
                      {{
                        $t('schools-list.school-kz-full', {
                          count: school_item.kz_branch
                        })
                      }}
                    </div>
                  </div>
                </div>
                <div class="school-btn-block">
                  <div class="school-btns mt-auto">
                    <router-link
                        :to="`/school/${school_item.school_id}`"
                        href="school.html"
                        type="button"
                        class="btn btn-primary"
                    >{{ $t('schools-list.more-button') }}
                    </router-link
                    >
                    <el-button
                        class="btn btn-danger"
                        v-if="school_item.school_enroll"
                        :disabled="school_item.is_expired"
                        @click="$bvModal.show('withdraw-modal')"
                    >
                      {{ $t('schools-list.withdraw-button') }}
                    </el-button>
                  </div>
                  <div class="status-block mt-auto">
                    <div
                        class="mr-1 d-flex align-items-center"
                        v-if="school_item.school_enroll"
                    >
                      <div class="status status--green">
                        {{ $t('schools-list.status-success') }}
                      </div>
                      <img src="/images/icon-green.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pagination" v-show="meta && meta.total > 10 && radioFilter !== 'applications'">
            <a
                href="#"
                v-for="(link, index) in meta.links"
                :class="{active: link.active}"
                :key="index"
                @click.prevent="(index === 0 && links.prev) ? goToPage(meta.current_page - 1) : (index === meta.links.length - 1 && links.next) ? goToPage(meta.current_page + 1) : goToPage(link.label)"
            >
							<span v-if="index === 0">
								&#8249;
							</span>
              <span v-else-if="index === meta.links.length - 1">
								&#8250;
							</span>
              <span v-else>
								{{ link.label }}
							</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="withdraw-modal" hide-footer hide-header class="p-0">
      <div id="withdraw-wrapper">
        <div id="withdraw-dialog">
          <!--					<button-->
          <!--						@click="$bvModal.hide('withdraw-modal')"-->
          <!--						type="button"-->
          <!--						class="close"-->
          <!--						data-dismiss="modal"-->
          <!--						aria-label="Close"-->
          <!--					>-->
          <!--						<span aria-hidden="true"></span>-->
          <!--					</button>-->
          <div>
            <img src="/images/icon-warn.svg" alt=""/>
            <div class="withdraw-modal-title">
              {{ $t('schools-list.withdraw-modal-title') }}
            </div>
            <div class="withdraw-modal-text">
              {{ $t('schools-list.withdraw-modal-text') }}
            </div>
            <el-button class="btn btn-primary mr-3" @click="declineRequest">{{
                $t('schools-list.withdraw-modal-yes')
              }}
            </el-button>
            <el-button
                class="btn btn-outline-primary"
                @click="$bvModal.hide('withdraw-modal')"
            >{{ $t('schools-list.withdraw-modal-no') }}
            </el-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import ProfileNavbar from '@/components/ProfileNavbar'
import Loader from '@/components/Loader'

export default {
  name: 'Settings',
  components: {
    ProfileNavbar,
    Loader
  },
  data() {
    return {
      user_id: null,
      filter: 0,
      activeItem: 'new',
      isSchoolFilterFormVisible: false,
      regions: [],
      school: {
        region_id: '',
        class: '',
        instruction_language: '',
        internat: ''
      },
      classes: [
        {
          id: '5',
          name: '5'
        },
        {
          id: '6',
          name: '6'
        }
      ],
      languages: [
        {
          id: 'ru',
          name: this.$t('references.language-ru')
        },
        {
          id: 'kz',
          name: this.$t('references.language-kz')
        }
      ],
      schools_list: [],
      meta: null,
      links: null,
      loader: true,
      locale: localStorage.getItem('locale') || 'ru',
      filterBtnLoading: false,
      radioFilter: 'allSchools',
      filter: {
        internat: '',
        region_id: '',
        school_bias: '',
        search: ''
      },
      notFoundMessage: '',
      time_end: '',
      instruction_url: ''
    }
  },
  methods: {
    getDistricts(id) {
      this.$v.school.region_id.$touch()
      this.districtsLoading = true
      this.$http
          .get(window.API_ROOT + '/api/references/districts/' + id)
          .then(res => {
            this.districtsLoading = false
            if (res.body.data) {
              this.districts = res.body.data
              this.school.district_id = ''
            }
          })
          .catch(e => {
            this.districtsLoading = false
          })
    },
    clearFilter() {
      this.getSchoolsList()
      for (const key in this.filter) {
        this.filter[key] = null
      }
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
    async getApplicationsSchool() {
      try {
        this.loader = true
        const res = await this.$http.get(`${API_ROOT}/api/schools-filter`, {
          params: {
            apply_status: 1
          }
        })
        if (res.body.data) {
          this.notFoundMessage = ''
          this.schools_list = res.body.data
          this.meta = res.body.meta
        } else {
          this.notFoundMessage = res.body.msg
        }
      } catch (error) {
      } finally {
        this.loader = false
      }
    },
    async goToPage(page) {
      try {
        this.loader = true
        const params = {}
        for (const key in this.filter) {
          this.filter[key] && (params[key] = this.filter[key])
        }
        const res = await this.$http.get(`${API_ROOT}/api/schools-filter?page=${page}`, {
          params: {
            ...params
          }
        })
        if (res.body.data) {
          this.notFoundMessage = ''
          this.schools_list = res.body.data
          this.meta = res.body.meta
        }
      } catch (error) {
      } finally {
        this.loader = false
      }
    },
    async getSchoolsList() {
      try {
        const res = await this.$http.get(`${API_ROOT}/api/schools-filter?page=1`)
        if (res.ok) {
          if (res.body.data) {
            this.notFoundMessage = ''
            this.schools_list = res.body.data
            this.meta = res.body.meta
            this.links = res.body.links
          }
        }
      } catch (error) {
      } finally {
        this.loader = false
      }
    },
    async declineRequest() {
      try {
        const {id} = JSON.parse(localStorage.getItem('user'))
        const res = await this.$http.post(
            `${API_ROOT}/api/user/decline-request`,
            {
              user_id: id
            }
        )
        this.$bvModal.hide('withdraw-modal')
        Vue.toastr({
          message: this.$t('school.application-decline'),
          description: '',
          type: 'alert'
        })
        this.getSchoolsList()
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: 'Неизвестная ошибка, попробуйте позже',
          type: 'error'
        })
      }
    },
    async getRegions() {
      try {
        const res = await this.$http.get(
            `${window.API_ROOT}/api/references/regions`
        )
        if (res.body.data) {
          this.regions = res.body.data
        }
      } catch (error) {
      }
    },
    async handeFilterUse() {
      try {
        this.isSchoolFilterFormVisible = false
        this.filterBtnLoading = true
        this.loader = true
        const params = {}
        for (const key in this.filter) {
          this.filter[key] && (params[key] = this.filter[key])
        }
        const res = await this.$http.get(
            `${window.API_ROOT}/api/schools-filter/`,
            {
              params: {
                ...params
              }
            }
        )
        if (res.body.data) {
          this.notFoundMessage = ''
          this.schools_list = res.body.data
          this.meta = res.body.meta
          this.links = res.body.links
        }
        if (res.body.status === 'error') {
          this.notFoundMessage = res.body.msg
        }
      } catch (error) {
      } finally {
        this.filterBtnLoading = false
        this.loader = false
      }
    },
    async getInstructions() {
      try {
        let id = null
        switch (this.$i18n.locale) {
          case 'ru':
            id = 1
            break;
          case 'kz':
            id = 2
            break;
        }
        const res = await this.$http.get(`${window.API_ROOT}/api/files/${id}`)
        if (res.body) {
          this.instruction_url = res.body
        }
      } catch (error) {

      }
    }
  },
  mounted() {
    this.$http.get(window.API_ROOT + '/api/user/edit')
      .then((res) => {
        const user = res.body.data;
        localStorage.setItem('user', JSON.stringify(user));
        if (user.second_stage_type !== 'ss_winner' || !user.school_enroll_available) {
          this.$router.push('/cabinet')
        }
      })
    this.getSchoolsList()
    this.getRegions()
    this.getInstructions()
    // const { is_winner } = JSON.parse(localStorage.getItem('user'))
    // if (!is_winner) this.$router.push('/cabinet')
  }
}
</script>

<style>
.school-btn-block {
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: right;
}

.filter-radio-btns {
  display: flex;
  flex-direction: row;
}

.filter-radio-btns .filter-item {
  margin-left: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 38px;
}

.pagination a {
  color: #2c2c2c;
  opacity: 0.5;
  float: left;
  padding: 8px 8px;
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.pagination a:first-child {
  padding-right: 25px;
  font-size: 25px;
  padding-top: 5px;
}

.pagination a:last-child {
  padding-left: 25px;
  font-size: 25px;
  padding-top: 5px;
}

.pagination a.active {
  color: #18a0fb;
  opacity: 1;
}

.pagination a:hover:not(.active) {
  opacity: 1;
}

#withdraw-modal .modal-dialog {
  top: 25%;
}

.withdraw-dialog {
  display: inline-block;
  position: relative;
  width: 100%;
}

.modal #withdraw-dialog button.close {
  border-radius: 44px;
  width: 44px;
  height: 44px;
  background-color: #b5b5b5;
  background-image: url(/img/close.be409dec.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  opacity: 1;
  transition: all 0.2s ease;
}

.modal #withdraw-dialog span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.modal #withdraw-dialog span {
  white-space: break-spaces;
}

.modal #withdraw-dialog div {
  position: relative;
  z-index: 1;
}

.modal #withdraw-dialog button {
  padding: 10px 20px;
}

.withdraw-modal-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  padding-bottom: 8px;
  padding-top: 20px;
}

.withdraw-modal-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 24px;
}

.schools-list-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-item-search {
  position: relative;
  width: 439px;
}

.btn-wrap {
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  overflow: hidden;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-wrap button {
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 2px 6px #ccc;
  line-height: 22px;
}

.btn-wrap button:hover {
  background: #fff;
}

.btn-wrap-text {
  color: #737373;
  font-size: 13px;
  line-height: 20px;
}

.has-search .form-control-feedback {
  position: absolute;
  right: 15px;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-control-search {
  height: 38px;
}

.custom-box {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 12px 30px 11px 16px;
}

.btn-filter {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 36px;
}

.notification-block {
  margin-top: 60px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  padding: 9px 10px;
  background: #18a0fb;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 5px 5px 5px 5px;
  color: #ffffff;
}

.nav-tabs .nav-link {
  color: black;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 0px;
}

.nav-tabs {
  border-bottom: none;
}

.col-md-8 {
  max-width: 44%;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #18a0fb;
}

.form-check-label input:checked .form-check-label {
  background-color: #18a0fb;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.toggle-block {
  height: 46px;
}

.form-filter-schools {
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04),
  0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 20px;
  position: absolute;
  top: 135px;
  width: 738px;
  visibility: hidden;
  transition: all 0.3s linear;
  opacity: 0;
  transform: translateY(-20px);
}

.form-filter-schools.show {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.btn-filter-modal {
  padding: 14px 30px !important;
  background: #ffffff !important;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}

.form-check-modal {
  margin-top: 15px;
  padding-left: 0;
}

.check-input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.check-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: #fbfbfb;
  border: 1px solid #e8eae9;
  display: block;
  border-radius: 100px;
  position: relative;
}

.check-label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #fbfbfb;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  transition: 0.3s;
}

.check-input:checked + .check-label {
  background: #0078d7;
}

.check-input:checked + .check-label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.check-label:active:after {
  width: 40px;
}

.shools-list-desc {
  background: #ffffff;
  border-radius: 8px;
  padding: 25px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: row;
}

.shools-list-desc-img {
  width: 278px;
}

.shools-list-item {
  height: 200px;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.school-info-block {
  display: flex;
  flex-direction: row;
}

.school-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.school-btns .btn {
  margin: 5px 0;
}

.school-btns a {
  padding: 10px 20px;
  font-size: 13px;
  width: fit-content;
  white-space: nowrap;
  min-width: 158px;
}

.school-btns img {
  width: 12px;
  height: 12px;
}

.school-text-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.school-text {
  max-height: 100px;
  overflow: hidden;
  color: #303030;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;

  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 4;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  display: -webkit-box;
  display: box;
  word-wrap: normal;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  text-align: left;
}

.school-info {
  background: rgba(224, 224, 224, 0.3);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 14px;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.status {
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  padding-right: 6px;
}

.status--green {
  color: #399300;
}

.status--yellow {
  color: #ffb700;
}

.status--red {
  color: #d1403f;
}

.status-block {
  justify-content: center;
}

.download-instruction {
  font-size: 13px;
}

.school-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.school-img {
  width: 245px;
  flex-shrink: 0;
  padding: 0;
  text-align: center;
}

.school-img img {
  width: auto;
  max-width: 245px;
  height: 100%;
  object-fit: cover;
}

.school-middle-part {
  display: flex;
  flex-direction: column;
}

.filter-items {
  display: flex;
  flex-direction: row;
}

.top-content {
  margin: 0;
}

.status-block > div {
  justify-content: flex-end;
}

@media screen and (max-width: 1226px) {
  .school-text {
    -ms-line-clamp: 2;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}

@media screen and (max-width: 1170px) and (min-width: 992px) {
  .status-block > div {
    justify-content: flex-start;
  }

  .school-btn-block {
    padding-top: 0;
  }

  .shools-list-item {
    /* height: 245px; */
    height: auto;
  }

  .school-img img {
    height: auto;
  }

  .school-btns .btn:nth-child(2) {
    margin: 5px 0 5px 6px;
  }

  .school-text > p {
    margin-bottom: 0;
  }

  .school-text {
    -ms-line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    margin-bottom: 8px;
  }

  .shools-list-item {
    flex-direction: row;
  }

  .school-content {
    display: flex;
    flex-direction: column;
  }

  .school-btns {
    flex-direction: row;
    justify-content: flex-start;
  }

  .school-btns a {
    width: fit-content;
    margin-right: 10px;
  }

  .status-block {
    position: initial;
    justify-content: left;
  }

  .school-img {
    display: flex;
    align-items: center;
  }

  /* .school-text {
    height: 20px;
  } */
  .schools-list-filter {
    flex-direction: column;
  }
}

@media screen and (max-width: 992px) {
  .school-text {
    -ms-line-clamp: 4;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    margin-bottom: 8px;
  }

  .school-text > p {
    margin-bottom: 0;
  }

  .btn-wrap {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .btn-wrap-text {
    margin-top: 8px;
  }

  .shools-list-item {
    flex-direction: column;
  }

  .shools-list-desc,
  .school-middle-part {
    display: flex;
    flex-direction: column-reverse;
  }

  .school-img {
    /* width: 100%; */
    height: 200px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .school-img img {
    border-radius: 10px;
    width: auto;
  }

  .school-info {
    width: fit-content;
  }

  .school-btns {
    flex-direction: row;
  }

  .school-btns a {
    margin-right: 0;
  }

  .school-btns .btn {
    margin: 0 5px;
  }

  .school-content {
    flex-direction: column;
  }

  .status-block {
    display: flex;
    position: initial;
    /* padding: 14px 0 22px 0; */
  }

  .status-block > div {
    padding: 14px 0 0px 0;
  }

  .school-info-block {
    align-items: center;
    flex-direction: column;
  }

  .school-info {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .school-text-title {
    text-align: center;
  }

  .form-filter-schools {
    width: 100%;
  }

  .filter-btns {
    text-align: center;
  }

  /* .filter-btns button:first-child {
    margin-bottom: 13px;
  } */
  .schools-list-filter {
    flex-direction: column;
  }

  .schools-list-filter .filter-items:first-child {
    flex-direction: column;
  }

  .filter-item-search {
    width: 100%;
  }

  .form-filter-schools.show {
    left: 0;
    top: 195px;
  }

  .custom-box {
    padding: 7px 20px 7px 16px;
  }

  .withdraw-modal-title {
    font-size: 30px;
  }

  .shools-list-item {
    height: auto;
    padding: 20px 0 5px 0;
  }

  .pagination {
    padding-bottom: 38px;
  }

  .top-content {
    margin-bottom: 24px;
  }

  .filter-radio-btns {
    justify-content: center;
  }

  .filter-radio-btns .filter-item:first-child {
    margin-left: 0px;
  }

  .filter-radio-btns .filter-item .form-check-label {
    font-size: 13px;
  }
}
</style>
