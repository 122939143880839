<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('confirm-email') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 2</span><span class="total">{{ $t('signup.out-of') }} 3</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">3</span><span class="total">{{ $t('signup.out-of') }} 2{{ $t('signup.ending') }}</span>
            </div>
            <div  class="signin-form row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-4 col-md-12" >
                    <form-group  :field="$v.teacher.email" :label="$t('references.email') + $t('references.required-field')" name="email">
                      <el-input
                          :disabled="emailVerified"
                          :placeholder="$t('references.email')"
                          v-model="teacher.email"
                          clearable>
                      </el-input>
                    </form-group>
                    <router-link type="primary" style="margin-left: 0" class="btn-next" to="/signup-teacher/first-step" tag="el-button">{{ $t('school.back-btn') }}</router-link>
                    <el-button style="color: white; background-color: #409EFF" type="button"  class="btn-next" @click="isFreeEmail">{{ $t('signup.next-btn') }}</el-button>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.error-modal') }}</h3>
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </b-modal>
    <b-modal id="sms-modal" hide-footer hide-header>
      <div id="sms-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('sms-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.enter-sms-code') }}</h3>
          <span>{{ $t('signup.sended-to') }} {{teacher.email}}</span>
          <div id="form">
            <CodeInput :field-width="54" :field-height="82"  :fields="4" class="input" v-on:change="onChange" v-on:complete="onComplete" />
            <p class="text-danger" v-if="invalidCode">{{ $t('signup.incorrect-code') }}</p>
          </div>
          <p v-if="canResend"><a @click="resendSms">{{ $t('signup.resend-code') }}</a></p>
          <p v-if="!canResend">{{ $t('signup.resend-code-after') }}{{ resendSec }} {{ $t('signup.resend-code-seconds') }}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email} from "vuelidate/lib/validators"
import CodeInput from "vue-verification-code-input"
import axios from "axios";

export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          let startDate = new Date();
          startDate.setMonth(11, 31);
          startDate.setFullYear( startDate.getFullYear() - 16 );

          let endDate = new Date();
          endDate.setMonth(11, 31);
          endDate.setFullYear( endDate.getFullYear() - 9 );

          return time.getTime() < startDate || time.getTime() >= endDate;
        }
      },
      iinCorrect: true,
      errorMessage: '',
      sendingSms: true,
      invalidCode: false,
      canResend: true,
      emailVerified: false,
      resendSec: 60,
      teacherId: null,
      teacher: {
        email: '',
      }
    }
  },
  validations: {
    teacher: {
      email: { required, email },
    }
  },
  mounted() {
    // if(sessionStorage.getItem('teacher')) {
    //   this.teacher = JSON.parse(sessionStorage.getItem('teacher'));
    //   if(this.teacher.email){
    //   function format(value, pattern) {
    //     var i = 0,
    //         v = value.toString();
    //     return pattern.replace(/#/g, _ => v[i++]);
    //   }
    //   this.email=format(this.teacher.email,'+# (###) ### ##-##')
    //   this.enforcePhoneFormat()}
    // }
    if(sessionStorage.getItem('teacher')) {
      const teacherData = JSON.parse(sessionStorage.getItem('teacher'));
      this.teacherId = teacherData.id
    }
    if(sessionStorage.getItem('email-verified')) {
      this.emailVerified = (sessionStorage.getItem('email-verified') == 'true');
      if (this.emailVerified==true && this.teacher.email==false){
        this.emailVerified=false
        sessionStorage.setItem('email-verified',  JSON.stringify(false));
      }
    }

  },
  methods: {

    isFreeEmail() {
      axios.post(API_ROOT + '/api/teacher/is-free-email', {email: this.teacher.email, })
          .then(res => {
            Vue.toastr({
              message: 'Error',
              description: this.$t('signup_error_email'),
              type: 'error'
            })
          })
          .catch(res => {
            this.openModal()
          })
    },

    openModal(){
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.emailVerified) {
        sessionStorage.setItem('teacher-email', JSON.stringify(this.teacher));
        sessionStorage.setItem('email-verified',  JSON.stringify(true));
        this.$router.push({ path: '/signup-by-iin/third-step' });
      } else {
        if (this.resendSec == 60) {
          setInterval(() => {
            this.resendSec--;
            if (this.resendSec == 0) {
              this.canResend = true;
            }
          }, 1000);
        }
        if(this.canResend==true){
          this.canResend=false
        this.sendEmail({
          email: this.teacher.email,
        });}
        this.$bvModal.show('sms-modal');
      }
    },
    resendSms(){
      this.canResend = false;
      this.resendSec = 60;
      this.sendEmail({
        email: this.teacher.email,
      });
    },
    // enforcePhoneFormat() {
    //   this.$v.phone.$touch();
    //   let x = this.phone
    //       .replace(/\D/g, "")
    //       .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    //
    //   this.teacher.phone = x[0];
    // },
    onChange(v){
      // console.log('on change');
    },
    onComplete(v){
      this.invalidCode = false;
      this.sendingSms = true;
      this.$http.post(window.API_ROOT + '/api/teacher/verification-code/verify', {
        email: this.teacher.email,
        code: +v,
        user_id: this.teacherId
      }).then((res) => {
        console.log('res', res)
        if(res.status == 200){
          sessionStorage.setItem('teacher-email', JSON.stringify(this.teacher));
          sessionStorage.setItem('email-verified', true);
          this.$router.push({ path: '/signup-teacher/third-step' })
        }
        if(res.status != 200){
          this.invalidCode = true;
        }
        this.sendingSms = false;
      }).catch(() => {
        this.sendingSms = false;
      })
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    onSubmit (data) {
      this.login(data)
          .then(() => {
            this.$router.push(this.$route.query.redirect || '/')
          })
          .catch(() => {})
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year',new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    ...mapActions({
      'sendEmail': 'sendEmail',
      'checkSms': 'checkSms'
    })
  }
}
</script>

