<template>
  <div class="container">
    <div class="row mt-5 helper-flex-column-rev" style="width: 100%;">
      <div class="col-lg-5 col-md-12" style="min-width: 44%;">
        <div class="text-center welcome-text">
          <h1 v-if="!tech">{{ $t('signin-form.title')}}</h1>
          <h1 v-else>{{ $t('signin-form.tech-title') }}</h1>
        </div>
        <div class="px-4" v-if="!tech">
          <login-form></login-form>
        </div>
        <div class="px-4" v-else>
          <tech-works :min="min < 10 ? '0'+ min : min" :sec="sec < 10 ? '0'+ sec : sec"></tech-works>
        </div>
        <div class="contacts-wrapper px-4">
          <div>{{$t('signin_contacts')}}</div>
          <div class="item-link"><img width="34px" height="33px" src="images/telegram-teacher.png" alt=""> <a
              href="https://t.me/Mynbala2024_bot">mynbala2024_bot</a></div>
          <div class="item-link"><img width="34px" height="33px" src="images/call-icon.png" alt=""> <a
              href="tel:87273100258">8 727 310 02 58</a></div>
        </div>
        <div class="intstruction-wrapper px-4">
          <span class="instruction-title">{{$t('signup-by-iin.signin-instruction')}}</span>
          <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция по авторизации 2023.docx.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <a v-if="$i18n.locale=='kz'" download href="/files/kz/Авторизациялану_нұсқаулығы_2023_docx.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <button  @click="$bvModal.show('youtube-modal-auth')" class="watch-instruction">{{ $t('signup-by-iin.see-instruction') }}</button>
        </div>
        <div class="intstruction-wrapper px-4">
          <span class="instruction-title">{{$t('signup-by-iin.password')}}</span>
          <a v-if="$i18n.locale=='ru'" download href="/files/ru/Инструкция_по_восстановлению_пароля_2023_docx.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <a v-if="$i18n.locale=='kz'" download href="/files/kz/Құпия_сөзді_қалпына_келтіру_бойынша_нұсқаулық_2023_docx.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
          <button  @click="$bvModal.show('youtube-modal-recovery')" class="watch-instruction">{{ $t('signup-by-iin.see-instruction') }}</button>
        </div>
      </div>
      <div style="min-width: 50%; margin-left: auto; padding-top: 100px" class="col-lg-5 col-md-12">
        <div class="intstruction-wrapper" style="overflow-y: auto; max-height: 700px;">
          <!--          <iframe style="width: 100%; height: 700px;" v-if="$i18n.locale=='ru'" src="/files/ru/График_заявок_2023_ru.pdf#toolbar=0" type="application/pdf"></iframe>-->
          <!--          <iframe style="width: 100%; height: 700px;" v-else-if="$i18n.locale=='kz'" src="/files/kz/График_заявок_2023_kz.pdf#toolbar=0" type="application/pdf"></iframe>-->
<!--          <pdf v-for="(page, index) in totalPageCount"-->
<!--               :key="index"-->
<!--               v-if="$i18n.locale=='ru'"-->
<!--               src="/files/ru/График_техпрогона_от_14_марта_рус.pdf"-->
<!--               :page="index + 1"-->
<!--               class="pdf-block"></pdf>-->
<!--          <pdf v-for="(page, index) in totalPageCount"-->
<!--               :key="index"-->
<!--               v-else-if="$i18n.locale=='kz'"-->
<!--               src="/files/kz/График_техпрогона_от_14_марта_каз.pdf"-->
<!--               :page="index + 1"-->
<!--               class="pdf-block"></pdf>-->
          <el-image
              v-if="$i18n.locale === 'ru'"
              style="width: 100%; height: 100%"
              src="/files/ru/winners.png"
              fit="fill"
          />
          <el-image
              v-if="$i18n.locale === 'kz'"
              style="width: 100%; height: 100%"
              src="/files/kz/winners.png"
              fit="fill"
          />
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal-auth" hide-footer hide-header>
      <div >
        <div id="dialog">

          <button @click="$bvModal.hide('youtube-modal-auth')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe  style="width: 100%; height: 500px;" v-if="$i18n.locale=='kz'"  src="https://www.youtube.com/embed/bZB5FsrBQrI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='ru'"  src="https://www.youtube.com/embed/pUDS8_XUMmE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </b-modal>

    <b-modal id="youtube-modal-recovery" hide-footer hide-header>
      <div>
        <div id="dialog">
          <button @click="$bvModal.hide('youtube-modal-recovery')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe  style="width: 100%; height: 500px;" v-if="$i18n.locale=='ru'"  src="https://www.youtube.com/embed/WX7KQ7cksI8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='kz'"  src="https://www.youtube.com/embed/RYimnQrACYM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from 'vuex'
import LoginForm from '@/components/LoginForm'
import TechWorks from '@/components/TechWorks'
import pdf from 'vue-pdf';
export default {
  name: 'Login',
  components: {
    LoginForm,
    TechWorks,
    pdf
  },
  data() {
    return {
      min: 0,
      sec: 0,
      totalPageCount: 1,
    }
  },
  computed: {
    ...mapGetters({
      tech: 'isTech',
      time: 'getTime'
    })
  },
  methods: {
    ...mapActions({
      'setTech': 'setTech',
      // 'getTech': 'getTech'
    }),
  },
  watch: {
    sec: {
      handler(value) {
        if(value >= 0) {
          setTimeout(() => {
            this.sec--;
          }, 1000)
        } else if(this.min || this.hour > 0) {
          this.sec = 59;
          if(this.min > 0) {
            this.min--
          }
        }
        if(this.hour === 0 && this.min === 0 && this.sec === 0) {
          setTimeout(() => {
            this.setTech(false)
          }, 1000)
        }
      },
      immediate: true,
    }
  },
  mounted() {
    // this.getTech();
    this.min = this.time
  }
}
</script>
<style lang="css" scoped>

.item-link {
  margin-top: 8px;

  img {
    margin-right: 16px;
  }
}

@media screen and (max-width: 575px) {
  .helper-flex-column-rev{
    margin-top: 0!important;
    padding: 0;
    flex-direction: column-reverse;
  }
  .adaptive-wrapper{
    flex-direction: column;
  }
  .iin-input{
    max-width: unset;
  }
  .download-instruction{
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
  .watch-instruction{
    width: 100%;
    margin-left: 0;
  }
  .button-iin-signup-group{
    flex-direction: column;
  }
  .button-iin-next{
    margin-right: 0;
    display: block;
    width: 100%;
  }
  .button-iin-clear{
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }
  .iin-search-item{
    font-size: 14px;
  }
  .iin-info{
    padding: 10px;
  }
  .warning-message{
    width: 64%;
  }
}
.el-select-dropdown__empty{
  padding: 10px;
}
</style>
