<template>
  <div class="content profile-content">
    <ProfileNavbarTeacher :title="$t('sidebar.settings')"></ProfileNavbarTeacher>
    <div class="clearfix">
      <div class="">

        <div class="col-md-4">
          <div class="settings-form mt-5">
            <h4 class="settings-form-title">{{ $t("profile.settings.form-title") }}</h4>
            <p class="settings-form-subtitle">
              {{ $t("profile.settings.form-subtitle") }}
            </p>
            <div class="form">
              <form @submit.prevent="changePassword">
                <div class="form-group">
                  <label class="pwd-lable" for="password">{{ $t("profile.settings.current-password-title") }}</label>
                  <el-input
                      maxlength="8"
                      type="number"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      :placeholder="$t('signin-form.password-placeholder')"
                      v-model="currentPassword"
                      @input="onCurrentPasswordInput"
                      autocomplete="off"
                      show-password>
                  </el-input>
                </div>
                <div class="form-group">
                  <label class="pwd-lable" for="password">{{ $t("profile.settings.new-password-title") }}</label>
                  <el-input
                      maxlength="8"
                      type="number"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      :placeholder="$t('signin-form.password-placeholder')"
                      v-model="newPassword"
                      @input="onPasswordInput"
                      autocomplete="off"
                      show-password>
                  </el-input>
                </div>
                <div class="form-group">
                  <label class="pwd-lable"
                         for="password-repeat">{{ $t("profile.settings.new-password-repeat-title") }}</label>
                  <el-input
                      show-password
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      maxlength="8"
                      type="number"
                      id="password"
                      :placeholder="$t('signin-form.password-confirm-label')"
                      v-model="confirmPassword"
                      @input="onPasswordConfirmInput"></el-input>

                </div>
                <p class="text-primary">{{ $t('signin-form.password-must1') }}</p>
                <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must2') }}</p>
                <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must3') }}</p>
                <div class="submit-btn">
                  <input type="submit" id="submit-btn" class="btn-submit" :value="$t('profile.settings.btn-text')">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbarTeacher from "@/components/ProfileNavbarTeacher";
import {minLength} from "vuelidate/lib/validators";

export default {
  name: 'Settings',
  components: {
    ProfileNavbarTeacher
  },
  data() {
    return {
      iinLoading: false,
      loading: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      apiRoot: window.API_ROOT
    }
  },
  methods: {
    onCurrentPasswordInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.currentPassword = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    onPasswordInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.newPassword = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    onPasswordConfirmInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.confirmPassword = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    changePassword() {
      this.loading = true;

      if (this.newPassword !== this.confirmPassword) {
        Vue.toastr({
          message: this.$t('signup.error-modal'),
          description: this.$t('signup.passwords-dont-match'),
          type: 'error'
        });

        return
      }

      if (!this.currentPassword) {
        Vue.toastr({
          message: this.$t('signup.error-modal'),
          description: this.$t('signup.enter-current-password'),
          type: 'error'
        });

        return
      }

      this.$http.post(window.API_ROOT + '/api/teacher/update-password', {
        old_password: this.currentPassword,
        password: this.newPassword,
        password_confirmation: this.confirmPassword
      })
          .then((res) => {
            Vue.toastr({
              message: this.$t('pole-modal.success'),
              description: this.$t('profile.settings.update-success'),
              type: 'success'
            });

            this.loading = false;
          }).catch((e) => {
        Vue.toastr({
          message: this.$t('signup.error-modal'),
          description: this.$t('profile.settings.update-error'),
          type: 'error'
        });
        this.loading = true;
      });
    },
  },

  validations: {
    newPassword: {
      required: false,
      minLength: minLength(8)
    },
    confirmPassword: {
      required: false,
      minLength: minLength(8)
    },
  }
}


</script>
<style>

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-submit {
  margin-top: 20px;
  background: #2F80ED;
  border-radius: 5px;
  padding: 14px 36px;
  color: #ffffff;
  border: none;
}

.pwd-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */


  /* Текст */

  color: #303030;
}

.settings-form-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #303030;
}

.settings-form-subtitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A9A9A9;
}

.settings-form {
  padding: 25px;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 8px;
}
</style>